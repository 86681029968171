import { defineStore } from 'pinia'
import { ref } from 'vue';

export const useAdminStore = defineStore('admin', () => {

    // use local storge permet de conserver en local storage les infos
    const categories = ref([]);

    function setCategories(Categories) {
        categories.value = Categories;
    }

    const services = ref([]);

    function setServices(Services) {
        services.value = Services;
    }

    return { categories, setCategories, services, setServices }
})