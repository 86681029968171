<template>
    <section v-if="fiche == 'empty'" class="loader_container">
        <div class="loader loader-4"></div>
    </section>
    <div v-else class="page-content">


        <div class="menu_other presta_menu">
            <div class="container">
                <div class="logo2">
                    <div class="logo">

                        <router-link to="/">
                            <img :src="serverUrl + '/uploads/kikekoi/logo.svg'" alt="Logo du site kikekoi">
                        </router-link>
                    </div>
                </div>
                <div class="input2">

                </div>
                <div class="profile2">

                    <a href="" class="active_profile">
                        <svg width="22" class="svg_threeline" height="16" viewBox="0 0 22 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 8H21.5" stroke="black" stroke-width="2.25" />
                            <path d="M0.5 14H21.5" stroke="black" stroke-width="2.25" />
                            <path d="M0.5 2H21.5" stroke="black" stroke-width="2.25" />
                        </svg>
                        <svg width="24" class="svg_profile" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 0.524902C5.67226 0.524902 0.525009 5.67215 0.525009 11.9999C0.525009 18.3277 5.67226 23.4749 12 23.4749C18.3278 23.4749 23.475 18.3277 23.475 11.9999C23.475 5.67215 18.3278 0.524902 12 0.524902ZM12 21.5249C8.98426 21.5249 6.29626 20.1119 4.55026 17.9174C5.76646 16.326 7.46303 15.1689 9.38851 14.6174C8.69521 14.177 8.12428 13.5687 7.72861 12.849C7.33294 12.1292 7.12533 11.3212 7.12501 10.4999C7.12501 7.8119 9.31201 5.6249 12 5.6249C14.688 5.6249 16.875 7.8119 16.875 10.4999C16.8743 11.3214 16.6665 12.1294 16.2707 12.8492C15.8749 13.5691 15.304 14.1775 14.6108 14.6182C16.5366 15.1692 18.2335 16.3265 19.4498 17.9182C17.7038 20.1119 15.0158 21.5249 12 21.5249Z"
                                fill="" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div class="menu_mobile presta_mobile_menu">
            <div class="container">
                <div class="menu_mobile_left">
                    <div class="logo">
                        <router-link to="/">
                            <img :src="serverUrl + '/uploads/kikekoi/logo.svg'" alt="Logo du site kikekoi">
                        </router-link>
                    </div>
                </div>
                <div class="menu_mobile_right">
                    <i class="icon-share"></i>
                    <i class="icon-heart"></i>
                </div>
            </div>
        </div>
        <div class="img_container">
            <div class="presta-gallery"
                v-if="fiche !== 'empty' && fiche.imagesReady !== undefined && fiche.imagesReady.length > 0">
                <a :href="serverUrl + image" :data-pswp-width="fiche.imagesReady[index].width"
                    :data-pswp-height="fiche.imagesReady[index].height" target="_blank"
                    v-for="(image, index)  in fiche.images" v-bind:key="index" class="elem">
                    <img :src="serverUrl + image" alt="">

                </a>

                <div @click="openClose" class="rk_see_all_photos">
                    <span>Voir toutes les photos</span>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="presta">

                <div class="rk_entreprise_logo">
                    <img :src="serverUrl + fiche.logo" alt="">
                </div>


                <div class="presta-details">

                    <div class="presta-details-left">

                        <h1>{{ fiche.nomEntreprise }}</h1>

                        <div class="rk_short_pres">
                            <p>{{ fiche.shortPresentation }}</p>
                        </div>
                        <div class="rk_titre_position">
                            <img :src="serverUrl + '/uploads/kikekoi/map-icon-green.svg'" alt="icone marker">
                            <span>{{ fiche.contact.ville }}</span>
                        </div>
                        <div class="rk_info_left">
                            <h3>Nous contacter :</h3>
                            <div class="rk_info">
                                <EmailOutlineIcon /> <a :href="'mailto:' + fiche.contact.email">{{
                                    fiche.contact.email }}</a>
                            </div>
                            <div class="rk_info">
                                <MapMarkerOutlineIcon /> <a>{{
                                    fiche.contact.adresse + ' ' + fiche.contact.codePostale + ' ' + fiche.contact.ville
                                }}</a>
                            </div>
                            <div class="rk_info">
                                <PhoneIcon /> <a :href="'tel:' + fiche.contact.tel">{{ fiche.contact.tel
                                }}</a>
                            </div>
                            <div class="rk_info" v-if="fiche.contact.site">
                                <WebIcon /> <a :href="fiche.contact.site">{{
                                    fiche.contact.site
                                }}</a>
                            </div>
                        </div>

                        <div class="rk_sous_titre">
                            <h2>
                                <span>À propos de nous :</span>
                            </h2>
                        </div>
                        <div class="rk_presta_description" v-html="fiche.presentation">


                        </div>
                        <div class="rk_sous_titre">
                            <h2>
                                <span>Nos prestations :</span>
                            </h2>
                        </div>
                        <div class="rk_prestations">
                            <ul>
                                <li v-for="(service, indexServices) in fiche.servicesPresta" v-bind:key="indexServices">
                                    <div class="rk_prestation">
                                        <span v-if="service.type == 'Sur devis'">{{
                                            service.services.titre }} (Prix sur Devis)</span>
                                        <span v-else>{{ service.services.titre
                                        }}
                                            ({{
                                                service.prix }}€ / {{ service.type }} )</span>
                                        <span>{{ service.services.description }}
                                        </span>
                                    </div>

                                </li>
                            </ul>



                        </div>
                        <reservationCom v-if="fiche.horaire"></reservationCom>
                        <div class="presta-locationmap">
                            <div class="rk_sous_titre">
                                <h2>Où nous trouver :</h2>
                            </div>

                            <l-map ref="map" class="presta_map" v-model:zoom="zoom"
                                :center="[fiche.location.coordinates[1], fiche.location.coordinates[0]]">
                                <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                                    name="OpenStreetMap"></l-tile-layer>
                                <l-marker
                                    :lat-lng="[fiche.location.coordinates[1], fiche.location.coordinates[0]]"></l-marker>

                            </l-map>
                        </div>

                    </div>
                    <div class="presta-details-right">
                        <div class="rk_devis">
                            <div class="rk_devis_titre">
                                <h3>Demander un devis :</h3>

                            </div>
                            <FormKit v-model="devis" type="form" submit-label="Demander un devis" @submit="sendDevis"
                                incomplete-message="Veuillez verifier que tout les champs soient remplis">
                                <FormKit type="select" name="prestation" validation="required" v-model="choixPresta"
                                    label="Choisissez une prestation :">
                                    <option v-for="(service, indexServices) in fiche.servicesPresta"
                                        v-bind:key="indexServices" :value="service.services.titre">{{ service.services.titre
                                        }}</option>

                                </FormKit>
                                <FormKit name="description" type="textarea" validation="required"
                                    label="Décrivez votre demande" :floating-label="true" />
                                <FormKit type="email" name="emailUser" validation="required" label="Votre email"
                                    :floating-label="true" />

                            </FormKit>


                        </div>
                        <div class="rk_contact_info">



                            <div class="rk_info_right">
                                <h4>Horaires d'ouverture :</h4>
                                <div class="rk_horaire">
                                    <p v-if="fiche.horaires.lundi.m !== 'Fermé' && fiche.horaires.lundi.a !== 'Fermé'">
                                        <strong>Lundi </strong> De {{ fiche.horaires.lundi.m }} à {{ fiche.horaires.lundi.a
                                        }}
                                    </p>
                                    <p v-else> <strong>Lundi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p v-if="fiche.horaires.mardi.m !== 'Fermé' && fiche.horaires.mardi.a !== 'Fermé'">
                                        <strong>Mardi </strong> De {{ fiche.horaires.mardi.m }} à {{ fiche.horaires.mardi.a
                                        }}
                                    </p>
                                    <p v-else> <strong>Mardi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p
                                        v-if="fiche.horaires.mercredi.m !== 'Fermé' && fiche.horaires.mercredi.a !== 'Fermé'">
                                        <strong>Mercredi </strong> De {{ fiche.horaires.mercredi.m }} à {{
                                            fiche.horaires.mercredi.a }}
                                    </p>
                                    <p v-else> <strong>Mercredi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p v-if="fiche.horaires.jeudi.m !== 'Fermé' && fiche.horaires.jeudi.a !== 'Fermé'">
                                        <strong>Jeudi </strong> De {{ fiche.horaires.jeudi.m }} à {{ fiche.horaires.jeudi.a
                                        }}
                                    </p>
                                    <p v-else> <strong>Jeudi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p
                                        v-if="fiche.horaires.vendredi.m !== 'Fermé' && fiche.horaires.vendredi.a !== 'Fermé'">
                                        <strong>Vendredi</strong> De {{ fiche.horaires.vendredi.m }} à {{
                                            fiche.horaires.vendredi.a }}
                                    </p>
                                    <p v-else> <strong>Vendredi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p v-if="fiche.horaires.samedi.m !== 'Fermé' && fiche.horaires.samedi.a !== 'Fermé'">
                                        <strong>Samedi </strong> De {{ fiche.horaires.samedi.m }} à {{
                                            fiche.horaires.samedi.a }}
                                    </p>
                                    <p v-else> <strong>Samedi </strong> Fermé</p>
                                </div>
                                <div class="rk_horaire">
                                    <p
                                        v-if="fiche.horaires.dimanche.m !== 'Fermé' && fiche.horaires.dimanche.a !== 'Fermé'">
                                        <strong>Dimanche </strong> De {{ fiche.horaires.dimanche.m }} à {{
                                            fiche.horaires.dimanche.a }}
                                    </p>
                                    <p v-else> <strong>Dimanche </strong> Fermé</p>
                                </div>
                            </div>



                        </div>

                        <div class="presta-details-right-report">
                            <i class="icon-report"></i>
                            <span class="presta-details-right-report-text">Signaler ce prestataire</span>
                        </div>
                    </div>
                    <!-- <div class="presta-summary-right">
                        <div class="presta-summary-right-item">
                            <i class="icon-share"></i>
                            <span class="presta-summary-right-item-share">Share</span>
                        </div>

                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
// variable
import { onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from "axios";
import { toast } from 'vue3-toastify';
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

import EmailOutlineIcon from 'vue-material-design-icons/EmailOutline.vue';
import MapMarkerOutlineIcon from 'vue-material-design-icons/MapMarkerOutline.vue';
import PhoneIcon from 'vue-material-design-icons/Phone.vue';
import WebIcon from 'vue-material-design-icons/Web.vue';
import reservationCom from '../../components/produits/reservationView.vue';

import "leaflet/dist/leaflet.css";
import '@formkit/addons/css/floatingLabels'

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { usePrestaStore } from '../../store/piniaPresta'
const storePresta = usePrestaStore();


const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
const zoom = 13;
const route = useRoute()
const fiche = ref('empty');
const choixPresta = ref('Choisissez une prestation')
const devis = ref();
const lightbox = ref();

onMounted(() => {
    setTimeout(() => {
        lightbox.value = new PhotoSwipeLightbox({
            gallery: '.presta-gallery',
            children: '.elem',
            pswpModule: () => import('photoswipe'),
            maxWidthToAnimate: 800,
        });


        lightbox.value.init();
    }, 400);


});
onBeforeMount(() => {
    axios.get(serverUrl.value + '/prestaitres/single', {
        withCredentials: true,
        params: {
            slug: route.params.slug
        }
    })
        .then(function (response) {
            // handle success
            fiche.value = response.data[0];
            storePresta.setCurrentPresta(fiche.value);
            fiche.value.imagesReady = [];
            fiche.value.images.forEach((url, index) => {
                fiche.value.imagesReady[index] = { width: 0, height: 0 };
                const img = new Image();
                img.src = serverUrl.value + url;
                img.onload = () => {
                    fiche.value.imagesReady[index].width = img.width;
                    fiche.value.imagesReady[index].height = img.height;
                }

            })


        })
        .catch(function (error) {
            // handle error

            toast.error(error);
        })

})

async function sendDevis() {
    await axios.post(process.env.VUE_APP_SERVER_URL + '/devis/presta/' + fiche.value._id, devis.value, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(function (response) {
        console.log(response.data);


        toast.success('Demande envoyée !');
    }).catch(function (err) {

        toast.error(err.response.data);
        return err;
    });
}
function openClose() {
    lightbox.value.loadAndOpen(1, {
        gallery: document.querySelector('.presta-gallery'),
        children: 'a',
        pswpModule: () => import('photoswipe'),

        maxWidthToAnimate: 800,

    })
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700&display=swap");
@import "../../assets/css/mixin.scss";
@import "../../assets/css/airbnb.css";

$mainColorText : #161615;

@font-face {
    font-family: "airbnb";
    src: url("../../assets/font/airbnb.eot?88214706");
    src: url("../../assets//font/airbnb.eot?88214706#iefix") format("embedded-opentype"),
        url("../../assets//font/airbnb.woff2?88214706") format("woff2"),
        url("../../assets//font/airbnb.woff?88214706") format("woff"),
        url("../../assets//font/airbnb.ttf?88214706") format("truetype"),
        url("../../assets//font/airbnb.svg?88214706#airbnb") format("svg");
    font-weight: normal;
    font-style: normal;
}

.presta_map {
    aspect-ratio: 3/1;
}


[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "airbnb";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* '' */
.icon-share:before {
    content: "\e80e";
}

/* '' */
.icon-report:before {
    content: "\e82d";
}


html {
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@include mq("tablet") {
    html {
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(151, 151, 151);
    }
}

* {
    padding: 0;
    margin: 0;
    list-style: none;
    border: none;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    font-family: 'Poppins';
    line-height: 1;

    &:focus {
        outline: none;
    }
}

body {
    font-family: 'Poppins';
}

.container {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @include mq("tablet") {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include mq("desktop") {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include mq("large") {
        padding-left: 80px;
        padding-right: 80px;
    }
}



.menu_other {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
    background-color: #fff;
    display: none;
    transition: 2s display;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);

    @include mq("tablet") {
        display: flex;
    }

    .container {
        height: 80px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-left: 24px;
        padding-right: 24px;

        .logo2 {
            flex: 1;
            display: none;

            @include mq("tablet") {
                display: flex;
            }

            a {
                width: max-content;

                svg {
                    color: #ff385c;
                }
            }
        }

        .input2 {
            position: relative;
            height: 46px;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            width: 347px;
            border-radius: 40px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

            @include mq("tablet") {
                width: unset;
            }

            input[type="text"]::placeholder {
                color: #333;
            }

            input[type="text"] {
                width: 100%;

                @include mq("tablet") {
                    width: 300px;
                }

                height: 48px;
                border: 1px solid #dddddd;
                border-radius: 40px;
                box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
                padding: 0px 24px;
                font-weight: 600;
                font-size: 14px;
            }

            .icon-search {
                position: absolute;
                top: 8px;
                right: 8px;
                color: #fff;
                background-color: #ff385c;
                padding: 8px 6px;
                height: 32px;
                width: 32px;
                border-radius: 50px;

                path {
                    stroke: #fff;
                }
            }

            span {
                width: max-content;
                height: 30px;
                font-weight: 400;
                font-size: 14px;
                padding: 0px 16px;
                display: flex;
                color: #717171;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 10px;

                &:first-child {
                    margin-left: 8px;
                    color: black;
                    font-weight: 600;
                }

                &:last-child {
                    flex: unset;
                    width: 30px;
                    border: none;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    border-right: 1px solid #ddd;
                }
            }

            svg {
                position: absolute;
                top: 8px;
                right: 8px;
                color: black;
                background-color: #ff385c;
                padding: 8px 6px;
                height: 32px;
                width: 32px;
                border-radius: 50px;

                path {
                    stroke: #fff;
                }
            }
        }

        .profile2 {
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            display: none;

            @include mq("tablet") {
                display: flex;
            }

            a {
                padding: 12px;
                border-radius: 30px;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;

                .svg_country {
                    width: 18px;
                    height: 16px;

                    path {
                        fill: #000;
                    }
                }

                &:hover {
                    background-color: rgba(#f7f7f7, 1);
                }
            }

            .active_profile {
                background-color: #fff;
                padding: 3px 6px 3px 9px;
                margin-left: 10px;
                max-height: 42px;
                height: 70px;
                border: 1px solid #dddddd;

                .svg_threeline {
                    height: 11px;
                }

                .svg_profile {
                    margin-left: 10px;
                    height: 30px;
                    width: 30px;

                    path {
                        fill: gray;
                    }
                }

                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
}



.menu_mobile {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(#dddddd, 0.5);
    display: block;

    @include mq("tablet") {
        display: none;
    }

    .container {
        display: flex;
        justify-content: space-between;
        height: 48px;

        .menu_mobile_left {
            a {
                display: flex;
                height: 48px;
                align-items: center;
                color: #000;
                width: max-content;

                svg {
                    margin-left: -12px;
                    font-size: 34px;
                    border-radius: 24px;
                    padding: 10px;

                    &:hover {
                        background-color: #f7f7f7;
                    }
                }

                .back_page {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }

        .menu_mobile_right {
            display: flex;
            align-items: center;
            height: 48px;

            span {
                color: #717171;
                font-size: 15px;
                font-weight: 500;
            }

            .seperator {
                height: 20px;
                width: 1px;
                background-color: #ddd;
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }
}







a {
    display: flex;
    width: calc(45%);
    color: $mainColorText;

    @include mq("tablet") {
        width: calc(31%);
    }

    @include mq("desktop") {
        width: calc(23.5%);
    }

    @include mq("large") {
        width: calc(19%);
    }

    @media (min-width: 1400px) {
        width: calc(15.5%);
    }

    .carousel-cell {
        //tablet desktop icin width verebilirsin
        border-radius: 5px;
        counter-increment: gallery-cell;

        .city_slider_item {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-right: 16px;

            &:last-child {
                margin-right: 0px;
            }

            .icon-heart {
                position: absolute;
                top: 12px;
                right: 12px;
            }

            img {
                width: 100%;
                aspect-ratio: 3/4;
                object-fit: cover;
                border-radius: 12px;
            }

            .experience_details {
                display: flex;
                flex-direction: column;

                .experience_first {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    svg {
                        color: #ff385c;
                    }

                    .star_point {
                        color: $mainColorText;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .star_count {
                        color: #717171;
                        font-size: 14px;
                    }
                }

                .experience_second {
                    margin-top: 8px;

                    .experience_heading {
                        font-size: 16px;
                        font-weight: 300;
                        color: $mainColorText;
                    }
                }

                .experience_third {
                    margin-top: 8px;

                    .experience_price {
                        span {
                            font-weight: 600;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}








.presta_menu {
    .container {
        width: 1120px;
        padding-left: 40px;
        padding-right: 40px;
        margin: 0 auto;

        @include mq("desktop") {
            padding-left: 40px;
            padding-right: 40px;
        }

        @include mq("large") {
            padding-left: 80px;
            padding-right: 80px;
            margin: 0 auto;
            width: 100%;
        }

        @media (min-width: 1300px) {
            padding-left: 0px;
            padding-right: 0px;
            width: 1120px;
        }
    }
}

.container {
    .presta {
        padding-left: 0px;
        padding-right: 0px;
        margin: 0px auto;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq("tablet") {
            margin: 0px auto;
            padding-top: 80px;
            width: 100%;
        }

        @include mq("desktop") {
            margin: 0px auto;
            padding-top: 80px;
            width: 100%;
        }

        @include mq("large") {
            margin: 0px auto;
            padding-top: 80px;
            width: 100%;
        }

        @media (min-width: 1300px) {
            width: 1120px;
        }

        &-heading {
            padding-top: 24px;
            font-size: 26px;
            line-height: 30px;
            color: $mainColorText;
            font-weight: 500;
            order: 2;

            @include mq("tablet") {
                order: 1;
            }
        }

        &-summary {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;

            margin-bottom: 24px;


            order: 3;

            @include mq("tablet") {
                order: 2;
            }

            &-left {
                display: flex;

                &-item {
                    display: flex;
                    align-items: center;
                    column-gap: 4px;

                    &::after {
                        content: "·";
                        margin-left: 8px;
                        margin-right: 8px;
                    }

                    &:last-child::after {
                        content: "";
                    }

                    i {
                        font-size: 14px;
                        color: #ff385c;
                    }

                    &-point {
                        color: $mainColorText;
                        font-size: 15px;
                        font-weight: 600;
                    }

                    &-reviews {
                        color: #717171;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: -0.5px;
                        text-decoration: underline;
                    }

                    &-ownerstatus {
                        font-size: 14px;
                        font-weight: 300;
                        color: #717171;
                    }

                    &-location {
                        font-size: 14px;
                        font-weight: 600;
                        color: #717171;
                        margin-left: 3px;
                        text-decoration: underline;
                    }
                }
            }

            &-right {
                column-gap: 12px;
                display: none;

                @include mq("tablet") {
                    display: flex;
                }

                &-item {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 14px;
                    }

                    .icon-share {
                        font-size: 20px;
                    }

                    &-share {
                        margin-left: 8px;
                        text-decoration: underline;
                        font-size: 15px;
                        font-weight: 500;
                        color: $mainColorText;
                    }

                    &-save {
                        margin-left: 8px;
                        text-decoration: underline;
                        font-size: 15px;
                        font-weight: 500;
                        color: $mainColorText;
                    }
                }
            }
        }

        &-gallery {
            @include mq("tablet") {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 8px 8px;
                grid-template-areas:
                    "one one two three ."
                    "one one four five ."
                    ". . . . ."
                    ". . . . .";
            }

            position: relative;
            height: 320px;
            order: 1;

            @include mq("tablet") {
                height: 330px;
                order: 3;
            }

            @include mq("desktop") {
                order: 3;
                height: 450px;
            }

            @include mq("large") {
                order: 3;
                height: 500px;
            }

            img {
                object-fit: cover;
            }

            .one {
                grid-area: one;
                width: calc(100% + 48px);
                height: 320px;
                margin-left: -24px;

                @include mq("tablet") {
                    height: 330px;
                    border-radius: 10px 0px 0px 10px;
                    margin-left: unset;
                    width: 100%;
                }

                @include mq("desktop") {
                    height: 450px;
                    border-radius: 10px 0px 0px 10px;
                    margin-left: unset;
                    width: 100%;
                }

                @include mq("large") {
                    height: 500px;
                    border-radius: 10px 0px 0px 10px;
                    margin-left: unset;
                    width: 100%;
                }
            }

            .two {
                grid-area: two;
                width: 100%;
                height: 245px;
                display: none;

                @include mq("tablet") {
                    display: block;
                    height: 160px;
                }

                @include mq("desktop") {
                    height: 220px;
                }

                @include mq("large") {
                    height: 245px;
                }
            }

            .three {
                grid-area: three;
                width: 100%;
                border-radius: 0px 10px 0px 0px;
                height: 245px;
                display: none;

                @include mq("tablet") {
                    display: block;
                    height: 160px;
                }

                @include mq("desktop") {
                    height: 220px;
                }

                @include mq("large") {
                    height: 245px;
                }
            }

            .four {
                grid-area: four;
                width: 100%;
                height: 245px;
                display: none;

                @include mq("tablet") {
                    display: block;
                    height: 160px;
                }

                @include mq("desktop") {
                    height: 220px;
                }

                @include mq("large") {
                    height: 245px;
                }
            }

            .five {
                grid-area: five;
                width: 100%;
                border-radius: 0px 0px 10px 0px;
                height: 245px;
                display: none;

                @include mq("tablet") {
                    display: block;
                    height: 160px;
                }

                @include mq("desktop") {
                    height: 220px;
                }

                @include mq("large") {
                    height: 245px;
                }
            }

            &-showall {
                position: absolute;
                bottom: 20px;
                right: 30px;
                padding: 8px 16px;
                border: 1px solid $mainColorText;
                border-radius: 8px;
                background-color: #fff;
                align-items: center;
                display: none;

                @include mq("tablet") {
                    display: flex;
                }

                i {
                    font-size: 14px;
                }

                &-text {
                    color: $mainColorText;
                    font-size: 14px;
                    font-weight: 500;
                    font-weight: 600;
                    margin-left: 8px;
                }
            }
        }

        &-details {
            width: 100%;
            display: flex;
            position: relative;
            order: 4;
            margin-top: unset;
            border-top: 1px solid #ddd;
            padding-top: 24px;

            @include mq("tablet") {
                margin-top: 48px;
                border-top: unset;
                padding-top: unset;
            }

            &-left {
                width: 100%;

                @include mq("tablet") {
                    width: 55%;
                }
            }



            &-right {
                width: 45%;
                padding-left: 5%;
                position: sticky;
                top: 120px;
                margin-bottom: 48px;
                height: 100%;
                display: none;

                @include mq("tablet") {
                    display: block;
                }

                &-card {
                    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
                    border: 1px solid #ddd;
                    border-radius: 12px;
                    padding: 25px;

                    &-header {
                        display: flex;
                        flex-direction: column;

                        @include mq("desktop") {
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        }

                        &-price {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;

                            span {
                                font-size: 22px;
                                line-height: 26px;
                                color: $mainColorText;
                                font-weight: 600;
                            }
                        }

                        &-reviews {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 500;
                            margin-top: 8px;

                            @include mq("desktop") {
                                margin-top: unset;
                            }

                            i {
                                color: #ff385c;
                            }

                            &-point {
                                color: $mainColorText;
                                margin-left: 2px;
                            }

                            &-count {
                                color: #717171;
                                margin-left: 2px;
                            }
                        }
                    }

                    &-form {
                        margin-top: 24px;
                        display: flex;
                        border: 1px solid $mainColorText;
                        border-radius: 8px 8px 0px 0px;

                        &-left {
                            display: flex;
                            flex-direction: column;
                            width: 50%;
                            padding: 12px;
                            border-right: 1px solid $mainColorText;

                            &-heading {
                                font-size: 10px;
                                font-weight: 800;
                            }

                            &-date {
                                font-size: 13px;
                                margin-top: 4px;
                            }
                        }

                        &-right {
                            display: flex;
                            flex-direction: column;
                            width: 50%;
                            padding: 12px;

                            &-heading {
                                font-size: 10px;
                                font-weight: 800;
                            }

                            &-date {
                                font-size: 13px;
                                margin-top: 4px;
                            }
                        }
                    }

                    &-formbottom {
                        display: flex;
                        border: 1px solid $mainColorText;
                        border-top: none;
                        border-radius: 0px 0px 8px 8px;
                        display: flex;
                        flex-direction: column;
                        padding: 12px;
                        position: relative;

                        &-heading {
                            font-size: 10px;
                            font-weight: 800;
                        }

                        &-guest {
                            font-size: 14px;
                            margin-top: 4px;
                        }

                        i {
                            position: absolute;
                            right: 14px;
                            top: 14px;
                            font-size: 24px;
                        }
                    }

                    &-button {
                        height: 48px;
                        margin-top: 16px;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        color: #fff;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(to right,
                                #e61e4d 0%,
                                #e31c5f 50%,
                                #d70466 100%) !important;
                    }

                    &-info {
                        margin-top: 16px;
                        margin-bottom: 24px;
                        color: $mainColorText;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        justify-content: center;
                    }

                    &-summary {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                        margin-bottom: 16px;

                        a {
                            margin-left: 5px;
                            width: auto !important;
                        }

                        &:first-child {
                            margin-top: 0px;
                        }

                        &-left {
                            font-size: 16px;
                            line-height: 20px;
                            color: $mainColorText;
                            text-decoration: underline;
                        }

                        &-right {
                            font-size: 16px;
                            line-height: 20px;
                            color: $mainColorText;
                            min-width: max-content;
                        }

                        .discount {
                            color: green;
                        }
                    }

                    &-total {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: space-between;
                        border-top: 1px solid #ddd;
                        padding-top: 16px;

                        &-left {
                            font-size: 16px;
                            color: $mainColorText;
                            font-weight: 600;
                            letter-spacing: 0.4px;
                        }

                        &-right {
                            font-size: 16px;
                            color: $mainColorText;
                            font-weight: 600;
                            letter-spacing: 0.4px;
                        }
                    }
                }

                &-lowerprice {
                    margin-top: 24px;
                    border: 1px solid #ddd;
                    border-radius: 12px;
                    padding: 25px;
                    display: flex;
                    align-items: center;

                    &-text {
                        font-size: 16px;
                        line-height: 20px;
                        color: $mainColorText;

                        .text-bold {
                            font-weight: 600;
                        }
                    }

                    i {
                        margin-left: 24px;
                        font-size: 36px;
                    }
                }

                &-report {
                    margin-top: 24px;
                    display: flex;
                    justify-content: center;

                    i {
                        margin-right: 12px;
                        color: #717171;
                    }

                    &-text {
                        font-size: 14px;
                        line-height: 20px;
                        color: #717171;
                    }
                }
            }

            &-plus {
                margin-top: 32px;
                padding-bottom: 0px;

                @include mq("tablet") {
                    padding-bottom: 24px;
                }

                &-item {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 24px;

                    i {
                        font-size: 24px;
                    }

                    &-info {
                        display: flex;
                        flex-direction: column;
                        margin-left: 16px;

                        &-heading {
                            font-size: 16px;
                            color: $mainColorText;
                            font-weight: 500;
                        }

                        &-desc {
                            font-size: 14px;
                            color: #717171;
                            margin-top: 8px;
                            font-weight: 400;
                        }
                    }
                }
            }

            &-description {
                font-size: 16px;
                line-height: 24px;
                color: $mainColorText;

                &-showmore {
                    font-weight: 600;
                    text-decoration: underline;
                }

                i {
                    font-size: 14px;
                    margin-left: 4px;
                }
            }

            &-sleep {
                padding-bottom: 48px;
                border-bottom: 1px solid #ddd;
                margin-top: 32px;

                @include mq("tablet") {
                    margin-top: 48px;
                }

                &-heading {
                    font-size: 22px;
                    color: $mainColorText;
                    margin-bottom: 24px;
                }

                img {
                    width: 320px;
                    height: 210px;
                    border-radius: 8px;
                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
                }

                &-name {
                    font-size: 16px;
                    color: $mainColorText;
                    margin-top: 16px;
                    font-weight: 600;
                }

                &-type {
                    font-size: 15px;
                    font-weight: 400;
                    color: $mainColorText;
                    margin-top: 4px;
                }
            }

            &-offers {
                margin-top: 48px;
                padding-bottom: 24px;
                border-bottom: 1px solid #ddd;

                @include mq("tablet") {
                    padding-bottom: 48px;
                }

                &-heading {
                    font-size: 22px;
                    color: $mainColorText;
                    margin-bottom: 24px;
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    @include mq("tablet") {
                        flex-direction: row;
                    }

                    &-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                        width: 100%;

                        @include mq("tablet") {
                            width: 50%;
                        }

                        i {
                            font-size: 24px;
                        }

                        &-name {
                            margin-left: 16px;
                            line-height: 20px;
                            color: $mainColorText;

                            font-size: 16px;
                        }

                        .item-none {
                            text-decoration: line-through;
                        }
                    }
                }

                &-button {
                    border: 1px solid $mainColorText;
                    padding: 12px 24px;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 48px;
                    width: 100%;
                    margin-top: 16px;

                    @include mq("tablet") {
                        width: max-content;
                        margin-top: 24px;
                    }

                    &:hover {
                        background-color: #f7f7f7;
                        text-decoration: underline;
                    }
                }
            }

            &-datetime {
                margin-top: 48px;
                padding-bottom: 48px;
                display: none;

                @include mq("tablet") {
                    display: block;
                }

                &-heading {
                    font-size: 22px;
                    color: $mainColorText;
                }

                &-daterange {
                    font-size: 14px;
                    color: #717171;
                    margin-top: 8px;
                }

                img {
                    width: 100%;
                    margin-top: 24px;
                }
            }
        }

        &-reviews {
            width: 100%;
            border-top: 1px solid #ddd;
            padding-top: 48px;
            order: 6;

            &-point {
                display: flex;
                align-items: center;

                i {
                    color: #ff385c;
                    font-size: 16px;
                }

                &-count {
                    font-size: 22px;
                    color: $mainColorText;
                    font-weight: 600;
                    margin-left: 8px;
                }
            }

            &-summary {
                margin-top: 32px;
                display: flex;
                flex-wrap: wrap;
                column-gap: 100px;
                row-gap: 16px;

                &-item {
                    align-items: center;
                    display: none;

                    @include mq("tablet") {
                        display: flex;
                        width: calc(50% - 100px);
                    }

                    &-name {
                        width: 85%;
                        font-size: 16px;
                        color: $mainColorText;
                    }

                    &-progress {
                        height: 4px;
                        width: 30%;
                        border-radius: 5px;
                        background-color: $mainColorText;
                    }

                    &-avg {
                        margin-left: 10px;
                        font-size: 12px;
                    }
                }
            }

            &-list {
                display: flex;
                width: 100%;
                max-width: 100%;
                overflow: auto;
                scroll-snap-type: x mandatory;
                -webkit-overflow-scrolling: touch;

                @include mq("tablet") {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    row-gap: 40px;
                    margin-top: 40px;
                }

                @include mq("desktop") {
                    flex-direction: row;
                    column-gap: 100px;
                }

                &-item {
                    display: flex;
                    flex-direction: column;
                    min-width: 85%;
                    scroll-snap-align: start;
                    border: 1px solid #ddd;
                    padding: 16px;
                    border-radius: 8px;
                    margin-left: 12px;

                    @include mq("tablet") {
                        min-width: 100%;
                        width: 100%;
                        border: unset;
                        padding: unset;
                        border-radius: unset;
                        margin-left: unset;
                    }

                    @include mq("desktop") {
                        min-width: calc(50% - 100px);
                        width: calc(50% - 100px);
                    }

                    &-profile {
                        display: flex;
                        align-items: center;

                        img {
                            width: 56px;
                            height: 56px;
                            border-radius: 56px;
                        }

                        &-info {
                            display: flex;
                            flex-direction: column;
                            margin-left: 12px;

                            &-name {
                                font-size: 16px;
                                color: $mainColorText;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: -0.3px;
                            }

                            &-date {
                                font-size: 14px;
                                color: #717171;
                                line-height: 20px;
                            }
                        }
                    }

                    &-comment {
                        margin-top: 16px;
                        font-size: 16px;
                        line-height: 24px;
                        color: $mainColorText;
                    }
                }

                &-showall {
                    border: 1px solid $mainColorText;
                    padding: 12px 24px;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    width: max-content;
                    border-radius: 8px;
                    cursor: pointer;
                    margin-top: 8px;
                    margin-bottom: 24px;

                    @include mq("tablet") {
                        margin-top: 24px;
                        margin-bottom: 40px;
                    }

                    &:hover {
                        background-color: #f7f7f7;
                        text-decoration: underline;
                    }
                }
            }
        }

        &-locationmap {
            width: 100%;

            order: 5;
            padding-bottom: 24px;
            padding-top: 32px;

            @include mq("tablet") {
                padding-top: 48px;
                padding-bottom: 48px;
            }

            iframe {
                height: 250px;

                @include mq("tablet") {
                    height: 480px;
                }
            }

            &-heading {
                font-size: 22px;
                color: $mainColorText;
                margin-bottom: 24px;
            }

            &-city {
                margin-top: 32px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: $mainColorText;
            }

            &-desc {
                margin-top: 16px;
                font-size: 16px;
                line-height: 24px;
                color: $mainColorText;
            }

            &-showmore {
                margin-top: 16px;
                font-weight: 600;
                text-decoration: underline;

                i {
                    font-size: 14px;
                    margin-left: 4px;
                }
            }
        }

        &-hosted {
            border-top: 1px solid #ddd;
            margin-top: 0px;
            order: 7;

            @include mq("tablet") {
                margin-top: 40px;
            }

            &-owner {
                margin-top: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include mq("tablet") {
                    justify-content: unset;
                    margin-top: 48px;
                }

                img {
                    width: 48px;
                    height: 48px;
                    object-fit: cover;
                    border-radius: 64px;
                    order: 2;

                    @include mq("tablet") {
                        order: 1;
                        width: 64px;
                        height: 64px;
                    }
                }

                &-profile {
                    display: flex;
                    flex-direction: column;
                    margin-left: unset;
                    order: 1;

                    @include mq("tablet") {
                        margin-left: 16px;
                        order: 2;
                    }

                    &-name {
                        font-size: 24px;
                        line-height: 26px;
                        color: $mainColorText;
                        font-weight: 600;
                        letter-spacing: 0.5px;
                    }

                    &-joineddate {
                        font-size: 14px;
                        line-height: 18px;
                        margin-top: 8px;
                        color: #717171;
                    }
                }
            }

            &-details {
                display: flex;
                flex-direction: column;
                margin-bottom: 48px;

                @include mq("tablet") {
                    flex-direction: row;
                }

                &-left {
                    width: 100%;
                    margin-top: 24px;

                    @include mq("tablet") {
                        width: 50%;
                    }

                    &-feature {
                        display: flex;
                        flex-direction: column;

                        @include mq("tablet") {
                            flex-direction: row;
                            align-items: center;
                        }

                        &-item {
                            margin-right: 24px;
                            display: flex;
                            align-items: center;
                            margin-top: 16px;

                            &:first-child {
                                margin-top: 0px;
                            }

                            @include mq("tablet") {
                                margin-top: unset;
                            }

                            i {
                                font-size: 14px;
                                color: #ff385c;
                            }

                            &-name {
                                margin-left: 6px;
                                font-size: 16px;
                                line-height: 20px;
                                color: $mainColorText;
                                width: max-content;
                            }
                        }
                    }

                    &-cohost {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        margin-top: 24px;
                        display: none;

                        @include mq("tablet") {
                            display: none;
                        }

                        @include mq("desktop") {
                            display: block;
                        }

                        &-heading {}

                        &-profile {
                            margin-top: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 32px;
                                height: 32px;
                                object-fit: cover;
                                border-radius: 24px;
                            }

                            &-name {
                                font-size: 16px;
                                color: $mainColorText;
                                margin-left: 12px;
                                font-weight: 400;
                            }
                        }
                    }

                    &-details {
                        display: flex;
                        flex-direction: column;
                        margin-top: 16px;

                        &-heading {
                            font-size: 16px;
                            line-height: 20px;
                            color: $mainColorText;
                            font-weight: 600;
                        }

                        &-text {
                            margin-top: 8px;
                            font-size: 16px;
                            line-height: 24px;
                            color: $mainColorText;
                            font-weight: 400;
                        }
                    }
                }

                &-right {
                    width: 100%;
                    margin-top: 24px;

                    @include mq("tablet") {
                        width: 50%;
                        margin-left: 48px;
                    }

                    @include mq("desktop") {
                        margin-left: 48px;
                    }

                    @include mq("large") {
                        margin-left: 48px;
                    }

                    @media (min-width: 1300px) {
                        margin-left: unset;
                    }

                    &-hostinfo {
                        display: flex;
                        flex-direction: column;
                        row-gap: 16px;

                        &-item {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    &-contacthost {
                        border: 1px solid $mainColorText;
                        padding: 12px 24px;
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        border-radius: 8px;
                        cursor: pointer;
                        margin-top: 24px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 48px;

                        @include mq("tablet") {
                            width: max-content;
                            margin-bottom: 40px;
                        }

                        &:hover {
                            background-color: #f7f7f7;
                            text-decoration: underline;
                        }
                    }

                    &-protectmoney {
                        margin-top: 24px;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;

                        @include mq("tablet") {
                            flex-direction: row;
                        }

                        i {
                            font-size: 24px;
                            color: #ffb400;
                            margin-left: 48px;

                            @include mq("tablet") {
                                margin-left: unset;
                            }
                        }

                        &-text {
                            font-size: 12px;
                            line-height: 16px;
                            color: $mainColorText;
                            width: 100%;

                            @include mq("tablet") {
                                width: 250px;
                                margin-left: 12px;
                            }
                        }
                    }
                }
            }
        }

        &-thingtoknow {
            border-top: 1px solid #ddd;
            padding-top: 48px;
            order: 8;
            margin-bottom: 0px;

            @include mq("tablet") {
                margin-bottom: unset;
            }

            @include mq("desktop") {
                margin-bottom: unset;
            }

            @include mq("large") {
                margin-bottom: unset;
            }

            @media (min-width: 1300px) {
                margin-left: 48px;
            }

            &-heading {
                font-size: 22px;
                color: $mainColorText;
                margin-bottom: 24px;
            }

            &-boxes {
                display: flex;
                flex-direction: column;

                @include mq("tablet") {
                    flex-direction: row;
                }

                &-box {
                    width: 100%;
                    padding-right: 40px;
                    margin-top: 24px;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 24px;

                    &:last-child {
                        border-bottom: 0px;
                    }

                    @include mq("tablet") {
                        width: 33.33%;
                        margin-top: 24px;
                        border-bottom: unset;
                        padding-bottom: unset;
                    }

                    &:last-child {
                        padding-right: 0px;
                    }

                    &-heading {
                        font-size: 16px;
                        line-height: 20px;
                        color: $mainColorText;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    &-list {
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        &-item {
                            display: flex;

                            i {
                                font-size: 16px;
                            }

                            &-text {
                                font-size: 16px;
                                line-height: 20px;
                                color: $mainColorText;
                                margin-left: 12px;
                            }
                        }
                    }

                    &-showmore {
                        margin-top: 24px;
                        font-weight: 600;
                        text-decoration: underline;

                        i {
                            font-size: 14px;
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }
}


.presta_mobile_menu {
    .container {
        padding-left: 24px;
        padding-right: 24px;

        .menu_mobile_right {
            display: flex;
            align-items: center;

            .icon-share {
                font-size: 22px;
                margin-right: 20px;
            }
        }
    }
}


.presta-details-summary {
    border-bottom: none !important;
}

//map 
.rk_maps_switcher {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    appearance: none;
    display: inline-flex;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    outline: none;
    margin: 0px;
    padding: 14px 19px;
    color: white;
    cursor: pointer;
    touch-action: manipulation;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
    background: rgb(34, 34, 34);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.search {
    transform: translateX(-100%);
    min-width: 100%;
    transition: transform .4s;

    &.active {
        transform: translateX(0%);
    }
}

.maps {
    min-width: 100%;
    position: absolute !important;
    transition: transform .4s;
    padding: 0 !important;
    top: 0;
    left: 0;
    transform: translateX(100%);

    &.active {
        transform: translateX(0%);
    }
}

/*LOADER-7*/
.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    vertical-align: top;
}

/*LOADER-4*/

.loader-4 {
    border: 7px double #ff5722;
    -webkit-animation: ball-turn 1s linear infinite;
    animation: ball-turn 1s linear infinite;
}

.loader-4:before,
.loader-4:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: #ff5722;
    border-radius: 50%;
    bottom: 0;
    right: 37px;
}

.loader-4:after {
    left: 37px;
    top: 0;
}

@-webkit-keyframes ball-turn {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ball-turn {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;



.presta-gallery {
    padding-top: 40px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .elem:nth-child(n+4) {
        display: none;
    }

    .elem {
        margin: 2px;
        border-radius: 30px;
        overflow: hidden;
        height: 250px;
    }

    .elem:nth-child(1) {
        flex: 2 1 0;
    }

    .elem:nth-child(2) {
        flex: 1 1 0;
    }

    .elem:nth-child(3) {
        flex: 1 1 0;
    }

    .rk_see_all_photos {
        position: absolute;
        cursor: pointer;
        z-index: 1;
        bottom: 40px;
        background-color: white;
        left: 50%;
        padding: 10px 15px;
        transform: translatex(-50%);
        border-radius: 20px;


    }
}

.presta {
    .rk_entreprise_logo {
        background-color: white;
        position: absolute;
        width: 200px;
        height: 200px;
        top: 0;
        transform: translatey(-50%);
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.presta-details-left {
    h1 {
        font-weight: 700;
        font-size: 32px;
        color: $mainColorText;
    }

    .rk_titre_position {
        margin: 10px 0;
        display: flex;
        align-items: center;

        span {
            margin-left: 10px;
        }
    }

    .rk_info_left {
        width: 100%;
        display: flex;
        flex-direction: column;

        h3 {
            width: 100%;
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .rk_info {
            display: flex;
            color: $inputColor;
            align-items: center;
            margin-bottom: 5px;


            a {
                margin-left: 10px;
                width: auto;
                color: $inputColor;

            }
        }
    }

    .rk_short_pres {
        margin: 10px 0;

        p {
            font-size: 22px
        }

    }

    .rk_sous_titre {
        h2 {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 18px;
        }
    }

    .rk_presta_description {
        margin-top: 10px;
        line-height: 22px;
    }

    .rk_prestations {
        li {
            padding-left: 30px;
            margin-top: 15px;
            position: relative;

            &:after {
                content: '';
                width: 10px;
                height: 10px;
                background-color: #9acdc1;
                position: absolute;
                top: 50%;
                left: 10px;
                border-radius: 50%;
                transform: translatey(-50%);
            }
        }
    }
}




.rk_contact_info {
    background-color: #eeeef4;
    border-radius: 20px;
    padding: 30px;
    margin-top: 30px;
    color: #6362A7;
    display: flex;
    flex-wrap: wrap;

    h3 {
        color: #6362A7;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }

    a {
        color: #6362A7;
        font-size: 12px;
        width: 100%;
        margin-left: 10px;
    }

    .rk_info {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }


    .rk_info_right {
        width: 100%;
        padding-top: 10px;

        h4 {
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            line-height: 18px;

            strong {
                width: 80px;
                display: inline-block;
            }
        }
    }



}
</style>

<style lang="scss">
.rk_devis {
    background: #f5f5f5;
    border-radius: 20px;
    padding: 30px;

    .rk_devis_titre {
        h3 {
            text-align: center;
            margin-bottom: 20px;
        }
    }




    &.selected {
        overflow: scroll;

    }

    .formkit-outer {
        margin-bottom: 16px;
    }

    .formkit-inner {
        box-shadow: none;
    }

    .formkit-input {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        border: none;
        background-color: white;

    }

    .formkit-label {
        font-family: 'Poppins';
        font-weight: 700 !important;
        color: #78849e !important;
    }

    [type="button"].formkit-input,
    [type="submit"].formkit-input {
        background-color: #6362A7;
        font-family: 'Poppins';
        font-weight: 700 !important;
        width: 100%;
        padding: 20px 25px;
        transition: all .4s;

    }

    [type="button"].formkit-input:hover,
    [type="submit"].formkit-input:hover {
        background-color: #012577;
    }

}
</style>