<template>
    <h1>Vos commandes :</h1>
    <div class="rk_container" v-if="commandes.length > 0">
        <div class="rk_order" v-for="(commande, index) in commandes" :key="index">
            <div class="userInfo">
                {{ commande.infos.nom }} {{ commande.infos.prenom }}
            </div>
            <div class="date">
                {{ formatDate(commande.dateCommande) }}
            </div>
            <div>
                <span class="rk_status"
                    :class="{ wait: commande.statut == 'En attente', prepa: commande.statut == 'En préparation', sent: commande.statut == 'Expédié', cancel: commande.statut == 'Annulé', done: commande.statut == 'Terminée' }">
                    {{ commande.statut }}

                </span>
            </div>

            <div class="rk_actions">
                <div class="rk_voir" @click="currentShow = index">
                    <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" fill="white"></path>
                        <path
                            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                            fill="white"></path>
                    </svg>
                    <p>Voir la commande</p>
                </div>


            </div>
            <div v-if="currentShow == index" class="rk_order_items">
                <div id="checklist">
                    <h3>Contenu de la commande :</h3>
                    <div class="rk_element" v-for="(produit, index) in commande.commande" :key="index">
                        <input type="checkbox" :id="'elem' + index" name="scales" />
                        <label :for="'elem' + index">{{ produit.produit.titre }} × {{ produit.quantite }} </label>

                    </div>
                    <div class="rk_total"> Total : {{ commande.montantTotal }} €</div>
                </div>
                <div class="controls">
                    <h3>Statut de la commande :</h3>
                    <select v-model="commande.statut">
                        <option value='En attente'>En attente</option>
                        <option value='En préparation'>En préparation</option>
                        <option value='Terminée'>Terminée</option>
                        <option value='Expédié'>Expédié</option>
                        <option value='Annulé'>Annulé</option>
                    </select>
                    <FormKit v-if="commande.statut === 'Annulé'" v-model="raison" type="text"
                        label="Motif de votre annulation" help="Le client sera informé du motif." :floating-label="true" />
                    <FormKit type="button" @click="uptadeOrder(commande)" label="Mettre à jour le statut"
                        help="Un email sera envoyé au client à chaque changement de statut" />
                </div>

            </div>
        </div>

    </div>
</template>
<script setup>
import axios from "axios";
import { onBeforeMount, ref } from 'vue'
import { useLoginStore } from '../../store/piniaLogin'
import { toast } from 'vue3-toastify';

const storelogin = useLoginStore()
const commandes = ref([]);

const raison = ref('');
const currentShow = ref(-1);

const user = JSON.parse(storelogin.currentUser);

// variable
onBeforeMount(() => {
    //preload or not 
    user.entreprises.forEach((entreprise) => {
        axios.get(process.env.VUE_APP_SERVER_URL + `/commandes/${entreprise}`)
            .then(function (response) {
                if (response.data.length > 0) {
                    commandes.value = [...commandes.value, ...response.data];

                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
    })


})


function formatDate(date) {
    const maDate = new Date(date); // Le format est AAAA-MM-JJ

    const nomsMois = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    // Obtenez le jour, le mois et l'année
    const jour = maDate.getDate();
    const mois = maDate.getMonth(); // L'indice du mois commence à 0 (0 = Janvier, 1 = Février, etc.)
    const annee = maDate.getFullYear();
    return `${jour} ${nomsMois[mois]} ${annee}`;
}

function uptadeOrder(commande) {

    const form = new FormData();

    if (commande.statut === 'Annulé') {
        form.append('raison', raison.value);

    }

    form.append('commandes', JSON.stringify(commande));

    axios.put(process.env.VUE_APP_SERVER_URL + `/commandes/${commande.commande[0].produit.prestaId[0]}/${commande._id}`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(function () {

            toast.success("Commande mise à jour!");

        })
        .catch(function (error) {
            toast.error(error);
        });

}
</script>

<style lang="scss" scoped>
$kikekoiGrey: #f5f5f5;
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

h1 {
    font-family: Poppins;

}

.rk_container {

    font-family: Poppins;

    .rk_order {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px;

        >* {
            flex: 1;
        }

        &:nth-child(odd) {

            background-color: $mainColorGreen ;
        }

        .rk_status {
            padding: 15px 20px;
            border-radius: 10px;

            &.wait {
                background: #f8dda7;
            }

            &.prepa {
                background: #c6e1c6;
            }

            &.done {
                background: #e5e5e5;
                ;
            }

            &.sent {
                background: #c8d7e1;
            }

            &.cancel {
                background: #eba3a3;
            }
        }

        .rk_actions {
            display: flex;
            flex-direction: row;

            .rk_voir {
                background-color: $inputColorhover;
                padding: 10px;
                text-align: center;
                border-radius: 10px;
                margin-left: auto;
                cursor: pointer;

                p {
                    color: white;
                    margin: 0;
                }
            }
        }

        .rk_order_items {
            width: 100%;
            padding: 20px;
            flex: auto;
            border: solid 2px $inputColorhover;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;


            .controls {
                flex: 1;
                display: flex;
                flex-direction: column;

                .formkit-outer {
                    display: inline-block;
                }

                select {
                    font-family: Poppins;
                    border: 1px solid$inputColorhover;
                    border-radius: 4px;
                    padding: 10px 15px;
                    margin: 10px 0;
                    background: transparent;
                    transition: background-color .5s;
                    cursor: pointer;
                }
            }
        }

    }
}




#checklist {
    --background: #fff;
    --text: #414856;
    --disabled: #c3c8de;
    --width: 100px;
    --height: 180px;
    --border-radius: 10px;
    flex: 4;

    .rk_element {
        display: flex;
        align-items: center;
        transform: translateX(25%);
        opacity: 0;
        animation: enter 0.4s ease forwards;


        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                animation-delay: ($i - 2) * .2s;
            }
        }
    }

    .rk_total {
        margin-top: 20px;
    }

    label {
        color: var(--text);
        position: relative;
        cursor: pointer;
        display: grid;
        align-items: center;
        width: fit-content;
        transition: color 0.3s ease;
        margin-right: 20px;
        font-size: 18px;

        &::before,
        &::after {
            content: "";
            position: absolute;
        }

        &:before {
            height: 2px;
            width: 8px;
            left: -27px;
            background: white;
            border-radius: 2px;
            transition: background 0.3s ease;
        }

        &:after {
            height: 4px;
            width: 4px;
            top: 8px;
            left: -25px;
            border-radius: 50%;
        }
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        height: 15px;
        width: 15px;
        outline: none;
        border: 0;
        margin: 0 15px 0 0;
        cursor: pointer;
        background: var(--background);
        display: grid;
        align-items: center;
        margin-right: 20px;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, .3);


        &:before,
        &:after {
            content: "";
            position: absolute;
            height: 2px;
            top: auto;
            background: $inputColorhover;
            border-radius: 2px;
        }

        &:before {
            width: 0px;
            right: 60%;
            transform-origin: right bottom;
        }

        &:after {
            width: 0px;
            left: 40%;
            transform-origin: left bottom;
        }

        &:checked::before {
            animation: check-01 0.4s ease forwards;
        }

        &:checked::after {
            animation: check-02 0.4s ease forwards;
        }

        &:checked+label {
            color: var(--disabled);
            animation: move 0.3s ease 0.1s forwards;
        }

        &:checked+label::before {
            background: var(--disabled);
            animation: slice 0.4s ease forwards;
        }

        &:checked+label::after {
            animation: firework 0.5s ease forwards 0.1s;
        }

    }
}


@keyframes enter {
    0% {
        transform: translateX(25%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes move {
    50% {
        padding-left: 8px;
        padding-right: 0px;
    }

    100% {
        padding-right: 4px;
    }
}

@keyframes slice {
    60% {
        width: 100%;
        left: 4px;
    }

    100% {
        width: 100%;
        left: -2px;
        padding-left: 0;
    }
}

@keyframes check-01 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }

    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }

    51% {
        width: 0px;
        top: 8px;
        transform: rotate(45deg);
    }

    100% {
        width: 5px;
        top: 8px;
        transform: rotate(45deg);
    }
}

@keyframes check-02 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }

    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }

    51% {
        width: 0px;
        top: 8px;
        transform: rotate(-45deg);
    }

    100% {
        width: 10px;
        top: 8px;
        transform: rotate(-45deg);
    }
}

@keyframes firework {
    0% {
        opacity: 1;
        box-shadow: 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0, 0 0 0 -2px #4f29f0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        box-shadow: 0 -15px 0 0px #4f29f0, 14px -8px 0 0px #4f29f0, 14px 8px 0 0px #4f29f0, 0 15px 0 0px #4f29f0, -14px 8px 0 0px #4f29f0, -14px -8px 0 0px #4f29f0;
    }
}
</style>


<style lang="scss">
$kikekoiGrey: #f5f5f5;
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.controls {


    .formkit-outer {
        button {
            font-family: Poppins;
            background-color: $mainColorBlue ;
            margin-top: 10px;
            transition: background .4s;

            &:hover {
                background-color: $inputColorhover ;

            }
        }
    }
}
</style>