<template>
  <menuComp v-if="currentLatlng.lat !== '' && currentLatlng.lng !== ''"></menuComp>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="page" :class="{ 'menu_opened': showModal }">
    <router-view />

  </div>
</template>

<script setup>
import menuComp from './components/global/menuComp.vue'
import { useLoginStore } from './store/piniaLogin'

import { storeToRefs } from 'pinia';

const store = useLoginStore()
const { showModal, currentLatlng } = storeToRefs(store)

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,600;0,900;1,300;1,600;1,900&display=swap');
@import "assets/css/mixin.scss";
$mainColorText : #161615;
$mainColorBlue : #6362A7;

@font-face {
  font-family: "Cakecofe";
  src: local("Cakecofe"),
    url(./assets/font/Cakecafe.otf) format("opentype");
}

:root {
  --fk-color-primary: $mainColorBlue ;
}

#app {

  @include mq("mobile") {
    padding-top: 0px;
  }

  @include mq("tablet") {
    padding-top: 80px;
  }

  @include mq("desktop") {
    padding-top: 80px;
  }




  .page {
    z-index: 2;
    position: relative;

    background-color: white;
    transition: transform .4s;

    &.menu_opened {
      overflow: hidden;
      transform: translateX(-300px);
    }
  }

}

body {
  padding: 0;
  margin: 0;
}
</style>
