<template>
    <div v-if="storeProduit.currentProduit !== undefined && storeProduit.currentProduit._id !== undefined"
        class="rk_produit">
        <div class="rk_produit_content">
            <div class="rk_images">
                <div class="img_list">
                    <img @click="imageUrlAvant = imageUrl" :class="{ active: imageUrlAvant == imageUrl }"
                        v-for="(imageUrl, indexImg) in storeProduit.currentProduit.images" :key="indexImg"
                        :src="serverUrl + imageUrl" alt="">
                </div>
                <div class="img_full">
                    <img :src="serverUrl + imageUrlAvant" alt="">
                </div>
            </div>
            <div class="rk_presentation">
                <div class="infos">
                    <div class="rk_product_cat">
                        <p v-for="(categorie, indexCat) in storeProduit.currentProduit.categories" :key="indexCat">{{
                            categorie.name }}</p>
                    </div>
                    <div class="rk_adresse">
                        <img :src="serverUrl + '/uploads/kikekoi/map-icon-green.svg'" alt="icone marker">
                        <p>{{ storeProduit.currentProduit.prestaId[0].contact.ville }}</p>
                    </div>
                </div>
                <h1>{{ storeProduit.currentProduit.titre }}</h1>
                <a :href="'/presta/' + storeProduit.currentProduit.prestaId[0].slug">{{
                    storeProduit.currentProduit.prestaId[0].nomEntreprise }}</a>
                <p class="rk_prix">{{ storeProduit.currentProduit.prix }} €</p>
                <h2>Description</h2>
                <p class="rk_description" v-html="storeProduit.currentProduit.description"></p>
                <div class="rk_add_toCart">
                    <span class="add_number">
                        <div class="addPlus" @click="qteAdd++">+</div>
                        <input type="number" name="qte" v-model="qteAdd">
                        <div class="addMoins" @click="qteAdd--">-</div>

                    </span>
                    <button class="rk_add" @click="addToCart(storeProduit.currentProduit)">Ajouter au panier</button>
                </div>
            </div>
        </div>
        <div v-if="storeProduit.currentProduit.prestaId[0].produits !== undefined" class="rk_mise_en_avant">
            <div class="rk_container">
                <h2 class="rk_titre_section">Tous les produits de {{
                    storeProduit.currentProduit.prestaId[0].nomEntreprise }}
                    <img :src="serverUrl + '/uploads/kikekoi/icon-map.svg'" alt="icone map">
                </h2>
                <div class="rk_stareds">


                    <vueper-slides fixed-height="350px" lazy class="no-shadow" disable-arrows-on-edges :bullets="true"
                        :touchable="false" :visible-slides="5" :arrows="false" slide-multiple :slideRatio="1 / 1"
                        :breakpoints="{ 1280: { slideRatio: 1 / 1, visibleSlides: 3, slideMultiple: 1 }, 800: { slideRatio: 1 / 1, visibleSlides: 2, slideMultiple: 2 } }">
                        <vueper-slide class="rk_slides" v-for="produit in storeProduit.currentProduit.prestaId[0].produits"
                            :link="'/produit/' + produit._id" :key="produit.titre">

                            <template #content>
                                <div class="rk_stared_item popular">
                                    <img :src="serverUrl + produit.images[0]" :alt="'image de ' + produit.nom" />
                                    <span class=" titre_carte">{{ produit.titre }}</span>
                                </div>
                            </template>

                        </vueper-slide>
                    </vueper-slides>
                </div>
            </div>
        </div>

        <div class="rk_presta_location">
            <h2 class="rk_titre_section">
                Où trouver {{
                    storeProduit.currentProduit.prestaId[0].nomEntreprise }}
                <img :src="serverUrl + '/uploads/kikekoi/icon-map.svg'" alt="icone map">
            </h2>


            <l-map ref="map" :zoom="10"
                :center="[storeProduit.currentProduit.prestaId[0].location.coordinates[1], storeProduit.currentProduit.prestaId[0].location.coordinates[0]]">
                <l-tile-layer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" layer-type="base"
                    name="OpenStreetMap"></l-tile-layer>

                <l-marker
                    :lat-lng="[storeProduit.currentProduit.prestaId[0].location.coordinates[1], storeProduit.currentProduit.prestaId[0].location.coordinates[0]]">

                    <l-popup class="rk_map_popup">
                        <img :src="serverUrl + storeProduit.currentProduit.prestaId[0].logo">

                    </l-popup>
                    <l-icon :icon-anchor="[0, 32]" :icon-size="[32, 32]"
                        :icon-url="serverUrl + '/uploads/kikekoi/pinspire.png'">
                    </l-icon>

                </l-marker>
                <div class="map_infos">
                    <h3>{{ storeProduit.currentProduit.prestaId[0].nomEntreprise }}</h3>
                    <router-link :to="'/presta/' + storeProduit.currentProduit.prestaId[0].slug">Voir la fiche
                    </router-link>
                </div>

            </l-map>
        </div>

        <div class="rk_retours_client">
            <div class="rk_phone"></div>
            <div class="retours_container">
                <div class="title">
                    <h2>Ils ont acheté ce produit !</h2>
                    <p>16 résultats</p>
                </div>
                <div class="content">
                    <div class="element" v-for="index in 16" :key="index">
                        <div class="rk_name">
                            <p>Manon</p>
                            <p> à Aix les bains</p>
                        </div>
                        <div class="rk_bought">
                            <p>À tester un produit similaire</p>
                        </div>
                        <div class="rk_date">
                            <p>{{ index + 3 }} sept 2023</p>
                        </div>
                        <div class="rk_contact">
                            <p>Contacter</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script setup>
// variable
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useProduitStore } from '../../store/piniaProducts'

import axios from "axios";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "@vue-leaflet/vue-leaflet";
import { usePanierStore } from '../../store/piniaPanier'

const route = useRoute()
const storePanier = usePanierStore()
const storeProduit = useProduitStore();

const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
const imageUrlAvant = ref();
const qteAdd = ref(1);

onBeforeMount(() => {
    //preload or not 
    if (storeProduit.currentProduit._id == route.params.id) {
        imageUrlAvant.value = storeProduit.currentProduit.images[0];
        axios.get(process.env.VUE_APP_SERVER_URL + `/produits/half/${route.params.id}`)
            .then(function (response) {
                const product = storeProduit.currentProduit;
                product.prestaId[0].poduits = response.data.datas.prestaId[0].produits;

                storeProduit.setCurrentProduit(product);
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
    } else {
        axios.get(process.env.VUE_APP_SERVER_URL + `/produits/full/${route.params.id}`)
            .then(function (response) {
                storeProduit.setCurrentProduit(response.data.datas);
                imageUrlAvant.value = storeProduit.currentProduit.images[0];
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })

    }

})



function addToCart(produit) {
    storePanier.setCartOpened(true)
    setTimeout(() => {
        storePanier.setCurrentOrder(produit, qteAdd);

    }, 400);
}

</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$BlueLight : #EEEEF4;

$mainColorGreen : #D2EFE8;
@import "../../assets/css/mixin.scss";




.rk_produit {
    background: linear-gradient(to bottom,
            $BlueLight 0%,
            $BlueLight 250px,
            white 250px, white 100%);

    p {
        font-family: "Poppins", sans-serif;

    }

    h1 {
        text-align: left;
        font: normal normal bold 35px/40px Poppins;
        letter-spacing: 0px;
        color: #012577;
        opacity: 1;

    }

    .rk_produit_content {
        display: flex;
        padding-bottom: 60px;

        flex-wrap: wrap;

        .rk_images {
            width: calc(50% - 40px);
            min-width: calc(430px - 40px);
            display: flex;
            justify-content: end;

            padding-right: 40px;

            .img_list {
                display: flex;
                flex-direction: column;
                width: 10%;
                justify-content: center;
                min-width: 70px;

                img {
                    cursor: pointer;
                    width: 100%;
                    aspect-ratio: 1/1;
                    border-radius: 20px;
                    margin: 10px 0;
                    display: none;
                    border: solid 2px transparent;
                    transition: border .4s;

                    &.active {
                        border: solid 2px #012577;
                    }

                    &:nth-of-type(-n+4) {
                        display: block;

                    }
                }
            }

            .img_full {
                height: 100%;
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                min-width: 280px;

                img {
                    aspect-ratio: 2/3;
                    object-fit: cover;
                    width: 100%;
                    border-radius: 20px;
                }
            }
        }

        .rk_presentation {
            width: 49%;

            a {
                text-align: left;
                font-style: italic;
                font-weight: 700;
                font-family: "Poppins", sans-serif;
                text-decoration: none;
                letter-spacing: 0px;
                opacity: 1;
                color: $mainColorText;
            }

            .infos {
                display: flex;
                flex-wrap: wrap;

                .rk_product_cat {
                    display: flex;

                    p {
                        background: #9BCFC3 0% 0% no-repeat padding-box;
                        border-radius: 7px;
                        color: #FFFFFF;
                        padding: 5px 10px;
                        margin-right: 10px;
                    }

                }

                .rk_adresse {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }
                }
            }

            .rk_add_toCart {
                input[type="number"] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    appearance: textfield;
                }

                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                .add_number {
                    position: relative;
                    margin-right: 10px;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 30px;
                    padding: 15px 30px 15px 20px;
                    overflow: hidden;
                    display: inline-flex;

                    .addMoins,
                    .addPlus {
                        cursor: pointer;
                        line-height: 5px;
                        position: absolute;
                        right: 0;
                        background-color: $inputColor;
                        padding: 10px 20px 10px 10px;
                        font-family: Poppins;
                        color: white;
                        width: 10px;
                    }

                    .addPlus {
                        border-bottom: solid 1px white;
                        top: 0px;
                    }

                    .addMoins {
                        border-top: solid 1px white;

                        bottom: 0px;
                    }

                    input {
                        border: none;

                        background: #FFFFFF 0% 0% no-repeat padding-box;

                        font-family: Poppins;
                        width: 30px;

                    }
                }




                .rk_add {
                    color: white;
                    padding: 15px 20px;
                    border: solid 1px $inputColor !important;
                    background: $inputColor 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    font-family: Poppins;
                    border: none;
                    border-radius: 30px;
                    opacity: 1;
                    transition: all .4s;
                    cursor: pointer;

                    &:hover {
                        background: white;
                        color: $inputColor
                    }
                }
            }


        }

        .rk_prix {
            text-align: left;
            font: normal normal bold 38px Poppins;
            margin: 15px 0;
            letter-spacing: 0px;
            color: $inputColor;
            opacity: 1;
        }

        h2 {
            text-align: left;
            font: normal normal bold 18px Poppins;
            letter-spacing: 0px;
            color: #161615;
            opacity: 1;
        }

        .rk_description {
            max-width: max(350px, 50%);
        }

    }

    .rk_titre_section {
        text-align: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        color: $textColorBlue;
        position: relative;

        svg,
        img {
            position: absolute;
            height: 200%;
            left: 50%;

            top: 50%;


            transform: translate(-50%, -50%);
        }
    }

    .vueperslide.vueperslide--active {
        transform: scale(1);

        .rk_selected_contour {
            position: absolute;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            margin: 10px;
            border-radius: 30px;
            border: solid 1px white;
            mix-blend-mode: screen;



            &:after {
                content: "█";
                position: absolute;
                top: -83px;
                left: 50%;
                transform: translatex(-50%);
                display: block;
                font-size: 90px;
                color: black;
            }
        }



    }

    .vueperslide {
        transition: transform .4s;
    }

    .vueperslides__bullet .default {
        background-color: trensparent;
        border: none;
        box-shadow: none;
        transition: 0.3s;
        width: 16px;
        height: 16px;
    }


    .vueperslides__bullet span {
        display: block;
        font-size: 10px;
        opacity: 0.8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 13px;
        color: #A6ADB4;
        width: 20px;
        transition: all .4s;
    }

    .vueperslides__bullet--active span {
        color: $mainColorText;
        position: relative;
        transform: scale(1.2);
    }

    .vueperslides__bullet--active span:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        width: 90%;
        height: 3px;
        border-radius: 15px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $mainColorBlue;
    }

    .vueperslides__bullet span:before {
        content: '0'
    }

    .rk_stareds {
        .rk_stared_item {
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
            position: relative;

            .titre_carte {
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 28px;
                text-align: center;
                font-family: 'Cakecofe';
                color: white;
            }

            &:not(.popular) {
                height: 100%;

                @include mq("mobile") {
                    aspect-ratio: 1 / 1;

                }

                @include mq("tablet") {
                    aspect-ratio: 1 / 1;

                }

                @include mq("desktop") {
                    aspect-ratio: 4 / 5;

                }

            }

            &.popular {
                aspect-ratio: 1 / 1;

            }

            .titre_carte {
                position: absolute;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }


        }
    }

    .rk_presta_location {
        margin-bottom: 40px;

        h2 {
            margin-bottom: 80px;

        }

        .leaflet-container {

            width: min(980px, 80%) !important;
            margin: auto;
            aspect-ratio: 3/1;
            position: relative;
            border-radius: 20px;


            .map_infos {

                position: absolute;
                left: 40px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1000;

                h3 {
                    font: normal normal bold 35px/40px Poppins;
                    color: #161615;
                    margin: 0;
                    margin-bottom: 10px;

                }

                a {
                    background-color: $mainColorBlue ;
                    color: white;
                    text-align: center;
                    font: normal normal bold 14px/32px Poppins;
                    padding: 5px 10px;
                    border-radius: 20px;
                    text-decoration: none;
                }

            }
        }
    }

    .rk_retours_client {
        display: flex;
        width: min(980px, 80%) !important;
        margin: auto;

        p {
            font: normal normal 14px/20px Poppins;
            margin: 0;

        }

        .rk_phone {
            flex: 1;
        }

        .retours_container {
            flex: 2;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: end;

                h2 {
                    font: normal normal bold 35px/40px Poppins;
                    color: #161615;
                    margin: 0
                }

                p {
                    font-weight: bold;
                    color: $inputColor;

                }
            }

            .content {
                height: 500px;
                overflow-y: scroll;
                overflow-x: initial;
                padding: 0 10px;
                margin: 20px 0;

                &::-webkit-scrollbar {
                    width: 10px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #EEEEF4;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(202, 202, 202);
                    border-radius: 8px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(151, 151, 151);
                }

                .element {
                    box-shadow: 0px 12px 14px #455B6314;
                    border-radius: 12px;
                    display: flex;
                    padding: 10px;
                    margin: 15px 0;

                    >* {
                        flex: 1;
                    }

                    .rk_name {
                        display: flex;
                        flex-direction: column;

                        p:nth-child(1) {
                            font: normal normal normal 13px/20px Arial;
                            color: #202124;
                        }

                        p:nth-child(2) {
                            font: normal normal normal 13px/23px Arial;
                            color: #A6ADB4;
                        }
                    }

                    .rk_bought,
                    .rk_date {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            text-align: let;
                            font: normal normal 300 12px/18px Poppins;
                            color: #454F63;
                        }
                    }


                    .rk_contact {
                        display: flex;
                        justify-content: end;
                        align-items: center;

                        p {
                            background: #9BCFC3 0% 0% no-repeat padding-box;
                            border-radius: 4px;
                            color: white;
                            display: inline-block;
                            padding: 5px 10px;
                        }

                    }
                }
            }
        }

    }

}
</style>
