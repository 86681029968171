<template>
    <div class="editAccount">
        <h2>Modifier vos informations</h2>
        <FormKit v-model="user" type="form" submit-label="mettre à jour" @submit="update"
            incomplete-message="Veuillez verifier que tout les champs soient remplis">
            <div class="double">
                <FormKit :floating-label="true" v-model="userData.nom" type="text" label="Nom" name="nom" />
                <FormKit :floating-label="true" v-model="userData.prenom" type="text" label="Prenom" name="prenom" />
                <FormKit :floating-label="true" v-model="userData.pseudo" type="text" label="Pseudo" name="pseudo" />
                <FormKit v-model="userData.isBenevole" label="Devenir bénévole" name="isBenevole" type="toggle" />
                <FormKit :floating-label="true" type="password" name="password" label="Mot de passe" />
                <FormKit :floating-label="true" type="password" label="Confirmation du mot de passe" name="password_confirm"
                    validation="confirm" :validation-messages="{
                        confirm: 'Les mots de passes ne sont pas identiques',
                    }" />

            </div>

            <hr>
            <div class="rk_search_map">
                <div class="">
                    <FormKit :floating-label="true" name="adresse" label="Adresse" />
                    <FormKit :floating-label="true" name="codePostale" label="Code postal" />
                    <FormKit :floating-label="true" name="ville" label="Ville" />


                </div>


                <div class="rk_map inscription">
                    <CrosshairsIcon v-if="!islocked" class="hover_map"></CrosshairsIcon>
                    <l-map ref="map" :zoom="18" :center="mapCenter" @update:center="centerUpdated" v-if="!hasMapchanged">
                        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                            name="OpenStreetMap"></l-tile-layer>
                        <l-marker :lat-lng="mapCenter"></l-marker>


                    </l-map>
                </div>
                <FormKit type="button" @click="checkAdress" help="Vérifiez votre adresse avant de mettre à jour">
                    Vérifier l'adresse
                </FormKit>
            </div>

        </FormKit>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useLoginStore } from '../../store/piniaLogin'
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { toast } from 'vue3-toastify';

const store = useLoginStore()
const userData = ref(JSON.parse(store.currentUser))
const user = ref();
const contact = ref({ lat: userData.value.location.coordinates[1], long: userData.value.location.coordinates[0] });
const islocked = ref(true);
const mapCenter = ref([userData.value.location.coordinates[1], userData.value.location.coordinates[0]])
const hasMapchanged = ref(false);


function centerUpdated(center) {
    if (!islocked.value) {
        mapCenter.value = center;

    }
}
function checkAdress() {
    hasMapchanged.value = true;
    let codePostale = "";
    let ville = "";
    let adresseCp = "";

    codePostale = user.value.codePostale;
    ville = user.value.ville;
    adresseCp = user.value.adresse;

    axios.get(`https://dev.virtualearth.net/REST/v1/Locations/FR/${codePostale}/${ville}/${adresseCp}?key=AoDJrgfVItWTs_icYLtdaizY88Uv3yj-98Tr03Cr6TR1VONQwP3tPZw0EY-JWTaL`)
        .then(function (response) {
            // handle success
            mapCenter.value = [response.data.resourceSets[0].resources[0].point.coordinates[0], response.data.resourceSets[0].resources[0].point.coordinates[1]];

            contact.value.lat = response.data.resourceSets[0].resources[0].point.coordinates[0];
            contact.value.long = response.data.resourceSets[0].resources[0].point.coordinates[1];

            hasMapchanged.value = false;

        })
        .catch(function (error) {
            // handle error
            hasMapchanged.value = false;

            console.log(error);
        })
}

// function selectStreet(adresse) {
//     user.value.codePostale = adresse.address.postcode;
//     user.value.ville = adresse.address.village;
//     user.value.adresse = adresse.address.road;

//     contact.value.lat = adresse.lat;
//     contact.value.long = adresse.lon;
// }

// function getMyPos() {
//     navigator.geolocation.getCurrentPosition((pos) => {
//         const crd = pos.coords;
//         contact.value.lat = crd.latitude;
//         contact.value.long = crd.longitude;
//         axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${crd.latitude}&lon=${crd.longitude}&<params>`)
//             .then(function (response) {
//                 // handle success
//                 user.value.codePostale = response.data.address.postcode
//                 user.value.ville = response.data.address.village
//                 user.value.adresse = response.data.address.road
//             })


//     }, (error) => {
//         console.log(error);
//     });
// }


// le pseudo est changé quand on edit le compte URGENT
async function update() {

    const data = {
        prenom: user.value.prenom,
        nom: user.value.nom,
        pseudo: user.value.pseudo,
        isBenevole: user.value.isBenevole,
        adresse: {
            codePostale: user.value.codePostale,
            ville: user.value.ville,
            rue: user.value.adresse
        },
        location: {
            type: 'Point',
            coordinates: [
                parseFloat(contact.value.long),
                parseFloat(contact.value.lat)
            ]
        }

    }
    if (user.value.password !== undefined) {
        data.password = user.value.password;
    }
    axios.put(process.env.VUE_APP_SERVER_URL + '/users/' + userData.value._id + '/', data, {
        headers: {
            'Authorization': `Basic ${userData.value.token}`,
        }
    }).then(function (response) {
        // handle success
        store.setUser(response.data)
        userData.value = JSON.parse(store.currentUser)
        toast.success('Profil mis à jour !');


    })
        .catch(function (error) {
            // handle error
            console.log(error);
        })


}
</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.editAccount {
    flex: 1 0 0;
    padding: 20px;

    hr {
        height: 1px;
        width: 20%;
        background-color: $inputColor;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .form_container.half {
        display: flex;
        flex-wrap: wrap;

        .half {
            flex: 1 1 250px;
            margin: 0 5px;
            min-height: 300px;
        }
    }

    .rk_search_map {
        display: flex;
        flex-wrap: wrap;

        div:not(:last-child) {
            width: 50%;
        }
    }

}
</style>

<style lang="scss">
@import "../../assets/css/mixin.scss";

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.editAccount {

    .formkit-outer[data-type="multi-step"]>.formkit-wrapper {
        max-width: 100% !important;
    }

    .select_geoloc {
        .formkit-input[type="button"] {
            border-radius: 30px;
            background-color: $mainColorBlue;
            border: solid 1px $mainColorBlue;
            position: relative;
            overflow: hidden;
            transition: all .4s;
            cursor: pointer;
            text-transform: uppercase;
            font-family: 'Poppins';
            color: white;
            margin: 0;
            padding: 10px 15px;

            &:hover {
                color: $mainColorBlue;

                background-color: white;
            }


        }

        .formkit-wrapper {
            @include mq("mobile") {
                text-align: center;
            }

            @include mq("tablet") {
                text-align: center;
            }

            @include mq("desktop") {
                text-align: none;
            }
        }

        .rk_map {
            .leaflet-top.leaflet-right {
                width: 30%;

                .leaflet-control {
                    width: 100%;
                }
            }

        }

        .formkit-form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mq("mobile") {
                flex-direction: column;
                align-items: center;

            }

            @include mq("tablet") {
                flex-direction: column;
                align-items: center;

            }

            @include mq("desktop") {
                justify-content: space-evenly;

                flex-direction: row;
            }

            >div {

                @include mq("mobile") {
                    width: 100%;
                }

                @include mq("tablet") {
                    width: 100%;
                }

                @include mq("desktop") {
                    width: 45%;
                }

            }

            >.formkit-outer {
                margin-top: 40px;
                width: 100%;
                display: flex;
                justify-content: center;

                .formkit-input[type="button"] {
                    color: white;

                    background-color: $inputColorhover;
                    border: solid 1px $inputColorhover;

                    &:hover {
                        color: $inputColorhover;

                        background-color: white;
                    }
                }
            }
        }

        button.formkit-input[type="submit"] {
            display: none;
        }
    }

    .formkit-tab-label {
        color: white;
        font-family: 'Poppins';

    }

    .formkit-tab::after,
    .formkit-badge {
        background: $inputColorhover !important;
    }

    .formkit-step-inner {
        margin-bottom: 20px;
    }

    .formkit-input[type="button"] {
        border-radius: 30px;
        background-color: $mainColorGreen;
        border: solid 1px $mainColorGreen;
        position: relative;
        overflow: hidden;
        transition: all .4s;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Poppins';
        color: $textColorBlue ;
        margin: 0;

        &:hover {
            color: $mainColorGreen;

            background-color: $textColorBlue;
        }


    }

    .formkit-steps {
        >.formkit-outer {
            text-align: center;
            margin-top: 40px;

            .formkit-input[type="button"] {
                color: white;

                background-color: $inputColorhover;
                border: solid 1px $inputColorhover;

                &:hover {
                    color: $inputColorhover;

                    background-color: white;
                }
            }
        }
    }

    .formkit-inner {
        box-shadow: none;





        .formkit-input {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            font-family: 'Poppins';
            font-weight: 700;
        }

        .formkit-label {
            font-family: 'Poppins';
            font-weight: 700;
            color: #161615;
            border-radius: 5px;
            padding: 5px 10px;
            left: 0;
        }
    }

    .formkit-actions {
        .formkit-wrapper {
            text-align: center;
            width: 100%;
            max-width: 100%;

            .formkit-input[type="submit"] {
                align-self: center;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
                font-family: 'Poppins';
                font-weight: 700;
                color: $inputColor;
                transition: all .4s;
                border: solid 1px white;

                &:hover {
                    background-color: $inputColor;
                    color: white;

                }

            }
        }
    }

    .double {
        justify-content: space-evenly;

        @include mq("mobile") {
            flex-direction: column;

        }

        @include mq("tablet") {
            flex-direction: column;

        }

        @include mq("desktop") {
            flex-direction: row;

        }


        .formkit-outer {
            @include mq("mobile") {
                width: 100%;

            }

            @include mq("tablet") {
                width: 100%;

            }

            @include mq("desktop") {
                width: 45%;

            }
        }
    }
}
</style>