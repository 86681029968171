<template>
    <Transition name="modal">

        <div class="rk_choiceData register_modal">
            <div v-if="!store.isLogged" class="rk_choice_container">
                <div>
                    <div class="rk_log_co" :class="{ selected: (logCo == 1) }" @click="logCo = 1">Connexion</div>
                    <div class="rk_log_co" :class="{ selected: (logCo == 2) }" @click="logCo = 2">Inscription</div>
                </div>
                <div>
                    <div class="rk_form connexion" v-if="logCo == 1">
                        <FormKit submit-label="Connexion" type="form" @submit="login">
                            <FormKit :floating-label="true" name="username" v-model="username" label="Pseudo ou email"
                                validation="required" />
                            <FormKit type="password" name="password" :floating-label="true" id="login-password"
                                v-model="password" label="Mot de passe" validation="required" />
                        </FormKit>
                    </div>
                    <div class="rk_form" v-if="logCo == 2">

                        <FormKit :allow-incomplete="false" type="multi-step" valid-step-icon="star" tab-style="progress"
                            ref="user">
                            <FormKit previous-label="Précedent" next-label="Suivant" type="step" valid-step-icon="check"
                                label="Informations" name="stepOne">
                                <div class="double">
                                    <FormKit :floating-label="true" type="text" label="Nom" name="nom"
                                        validation="required" />
                                    <FormKit :floating-label="true" type="text" label="Prénom" name="prenom"
                                        validation="required" />
                                </div>
                                <div class="double">
                                    <FormKit :floating-label="true" type="email" label="E-mail" name="email"
                                        validation="required" />
                                    <FormKit :floating-label="true" type="text" label="Pseudo" name="pseudo"
                                        validation="required" />
                                </div>
                                <div class="double">
                                    <FormKit :floating-label="true" type="password" name="password" label="Mot de passe"
                                        validation="required" />
                                    <FormKit :floating-label="true" type="password" label="Validation mot de passe"
                                        name="password_confirm" validation="length:6,16|confirm" :validation-messages="{
                                            confirm: 'Les mots de passe ne sont pas identiques.',
                                        }" />
                                </div>
                            </FormKit>
                            <FormKit previous-label="Précedent" next-label="Suivant" type="step" valid-step-icon="check"
                                label="Emplacement" name="stepTwo">
                                <div class="rk_search_map">
                                    <div class="">
                                        <FormKit :floating-label="true" name="adresse" label="Adresse"
                                            validation="required" />
                                        <FormKit :floating-label="true" name="codePostale" label="Code postal"
                                            validation="required" />
                                        <FormKit :floating-label="true" name="ville" label="Ville" validation="required" />


                                    </div>


                                    <div class="rk_map inscription">
                                        <CrosshairsIcon v-if="!islocked" class="hover_map"></CrosshairsIcon>
                                        <l-map ref="map" :zoom="18" :center="mapCenter" @update:center="centerUpdated"
                                            v-if="!hasMapchanged">
                                            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                layer-type="base" name="OpenStreetMap"></l-tile-layer>
                                            <l-marker :lat-lng="mapCenter"></l-marker>


                                        </l-map>
                                    </div>

                                </div>
                                <template #stepNext>
                                    <FormKit type="button" @click="checkAdress">
                                        Vérifier l'adresse
                                    </FormKit>
                                    <!-- incrementStep returns a callable function -->

                                </template>
                            </FormKit>
                            <FormKit v-if="adresse.lat != '' && adresse.lng != ''" type="button" @click="register"
                                :class="'rk_insc_kikekoi'" label="M'inscrire sur KIKEKOI" />
                        </FormKit>

                    </div>
                </div>
            </div>
            <div v-else class="logged_form">
                <h2>Bonjour {{ loggedUser.prenom }}</h2>
                <router-link v-if="store.isLogged" to="/admin" @click="store.setShowModal(false)">Mon compte</router-link>
                <form class="form form-login" @submit.prevent="logout">

                    <button type="submit" class="btn-login">Déconnexion</button>
                </form>
            </div>
        </div>

    </Transition>
</template>
<script setup>
// import { ref, defineProps, defineEmits } from 'vue';
// import axios from 'axios';
// import { toast } from 'vue3-toastify';
// import { storeToRefs } from 'pinia'
// import { useLoginStore } from '../../store/piniaLogin'
// const store = useLoginStore()
// const { currentUser } = storeToRefs(store)
// import "leaflet/dist/leaflet.css";
// import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
// import geoCode from '../inputs/geoCode.vue'

// const selectedLogin = ref(true);
// const notClosed = ref(true);
// const user = ref();
// const contact = ref({
//     lat: 45.692341,
//     long: 5.908998
// });
// const username = ref("");
// const password = ref("");
// const props = defineProps({
//     show: Boolean
// })
// const emit = defineEmits(['close'])
// async function logout() {
//     try {
//         store.setUser(false);
//         store.setToken(false);
//         // let { user, token } = res.data;
//         // store.setToken(token);
//         emit('close')
//     } catch (error) {
//         console.log(error);
//         toast.error(error.response.data);

//     }
// }

// async function login() {
//     const res = await axios.post(process.env.VUE_APP_SERVER_URL + '/login', { username: username.value, password: password.value }, {
//         headers: {
//             // Overwrite Axios's automatically set Content-Type
//             'Content-Type': 'application/json'
//         },
//         withCredentials: true
//     }).then(function (response) {
//         store.setUser(response.data);
//         store.setToken(response.data.token);

//         store.setSessionExpire(new Date(Date.now() + 72 * (60 * 60 * 1000)));

//         emit('close')

//         toast.success('Vous êtes conneté !');
//     }).catch(function (err) {
//         console.log(err);
//         toast.error(err.response.data);
//         return err;
//     });


// }

// function getMyPos() {
//     navigator.geolocation.getCurrentPosition((pos) => {
//         const crd = pos.coords;
//         contact.value.lat = crd.latitude;
//         contact.value.long = crd.longitude;
//         axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${crd.latitude}&lon=${crd.longitude}&<params>`)
//             .then(function (response) {
//                 // handle success
//                 user.value.codePostale = response.data.address.postcode
//                 user.value.ville = response.data.address.village
//                 user.value.adresse = response.data.address.road
//             })


//     }, (error) => {
//         console.log(error);
//     });
// }

// function selectStreet(adresse) {
//     user.value.codePostale = adresse.address.postcode;
//     user.value.ville = adresse.address.village;
//     user.value.adresse = adresse.address.road;
//     console.log(contact);
//     contact.value.lat = adresse.lat;
//     contact.value.long = adresse.lon;
// }


// async function register() {

//     const data = {
//         prenom: user.value.prenom,
//         nom: user.value.nom,
//         pseudo: user.value.pseudo,
//         email: user.value.email,
//         password: user.value.password,
//         adresse: {
//             codePostale: user.value.codePostale,
//             ville: user.value.ville,
//             rue: user.value.adresse
//         },
//         location: {
//             type: 'Point',
//             coordinates: [
//                 parseFloat(contact.value.long),
//                 parseFloat(contact.value.lat)
//             ]
//         }

//     }
//     const res = await axios.post(process.env.VUE_APP_SERVER_URL + '/users', data);
//     store.setUser(res.data);
//     store.setToken(res.data.token);
//     toast.success('Bienvenue ! Vous êtes maintenant conneté !');
//     emit('close')

//     // console.log(userData.value);
// }



// New js 


import { ref, defineEmits } from 'vue';
import { storeToRefs } from 'pinia'
import { useLoginStore } from '../../store/piniaLogin'
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
import CrosshairsIcon from 'vue-material-design-icons/Crosshairs.vue';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import { useRouter } from 'vue-router'
const router = useRouter()

const store = useLoginStore();
const username = ref("");
const password = ref("");
const map = ref();
const user = ref();
const adresse = ref({ lat: "", lng: "" });
const logCo = ref(1);
const islocked = ref(true);
const hasMapchanged = ref(false);
const isSuccess = ref(false);

const mapCenter = ref([45.692341, 5.908998])
defineEmits(['close'])
const { currentUser } = storeToRefs(store);
const loggedUser = {};
if (currentUser.value !== '') {
    loggedUser == JSON.parse(currentUser.value);
}
// function getMyPos() {
//     navigator.geolocation.getCurrentPosition((pos) => {
//         const crd = pos.coords;
//         mapCenter.value = [crd.latitude, crd.longitude];

//     }, (error) => {
//         console.log(error);
//     });
// }

function centerUpdated(center) {
    if (!islocked.value) {
        mapCenter.value = center;

    }
}
// function selectAdress() {
//     currentLatlng.value = mapCenter.value;
// }

// function selectStreet(adresse) {
//     mapCenter.value = [adresse.lat, adresse.lon];
// }

async function login() {
    axios.post(process.env.VUE_APP_SERVER_URL + '/login', { username: username.value, password: password.value }, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
        withCredentials: true
    }).then(function (response) {
        store.setUser(response.data);
        store.setToken(response.data.token);
        store.setLatlng(response.data.location.coordinates[1], response.data.location.coordinates[0]);

        store.setSessionExpire(new Date(Date.now() + 72 * (60 * 60 * 1000)));

        isSuccess.value = true;
        router.push('/admin')
        store.setShowModal(!store.showModal)
        toast.success('Vous êtes conneté !');
    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });


}
async function register() {
    const form = user.value.node.value;
    console.log(form);
    const data = {
        prenom: form.stepOne.prenom,
        nom: form.stepOne.nom,
        pseudo: form.stepOne.pseudo,
        email: form.stepOne.email,
        password: form.stepOne.password,
        adresse: {
            codePostale: form.stepTwo.codePostale,
            ville: form.stepTwo.ville,
            rue: form.stepTwo.adresse
        },
        location: {
            type: 'Point',
            coordinates: [
                parseFloat(adresse.value.lng),
                parseFloat(adresse.value.lat)
            ]
        }

    }
    try {
        const res = await axios.post(process.env.VUE_APP_SERVER_URL + '/users', data);
        store.setUser(res.data);
        store.setToken(res.data.token);
        store.setLatlng(res.data.location.coordinates[1], res.data.location.coordinates[0]);
        toast.success('Bienvenue sur kikekoi !');
        store.setShowModal(!store.showModal)
        router.push('/admin')
    } catch (error) {
        console.log(error);
        toast.error(error.message);

    }


    // console.log(userData.value);
}
async function logout() {
    try {
        store.setUser(false);
        store.setToken(false);
        router.push('/')
        store.setShowModal(!store.showModal)

    } catch (error) {
        console.log(error);
        toast.error(error.response.data);

    }
}

function checkAdress() {
    hasMapchanged.value = true;
    let codePostale = "";
    let ville = "";
    let adresseCp = "";

    if (user.value.node.value.stepTwo !== undefined) {
        codePostale = user.value.node.value.stepTwo.codePostale;
        ville = user.value.node.value.stepTwo.ville;
        adresseCp = user.value.node.value.stepTwo.adresse;
    } else {
        codePostale = user.value.node.value.codePostale;
        ville = user.value.node.value.ville;
        adresseCp = user.value.node.value.adresse;
    }

    axios.get(`https://dev.virtualearth.net/REST/v1/Locations/FR/${codePostale}/${ville}/${adresseCp}?key=AoDJrgfVItWTs_icYLtdaizY88Uv3yj-98Tr03Cr6TR1VONQwP3tPZw0EY-JWTaL`)
        .then(function (response) {
            // handle success
            mapCenter.value = [response.data.resourceSets[0].resources[0].point.coordinates[0], response.data.resourceSets[0].resources[0].point.coordinates[1]];
            console.log(mapCenter.value);

            adresse.value.lat = response.data.resourceSets[0].resources[0].point.coordinates[0];
            adresse.value.lng = response.data.resourceSets[0].resources[0].point.coordinates[1];
            hasMapchanged.value = false;

        })
        .catch(function (error) {
            // handle error
            hasMapchanged.value = false;

            console.log(error);
        })
}

</script>


<style scoped lang="scss">
@import "../../assets/css/mixin.scss";

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_choiceData {
    position: fixed;
    padding-top: 80px;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        text-align: center;
    }

    .rk_choice_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        >div:nth-child(1) {
            display: flex;
            justify-content: center;

            .rk_log_co {
                font-family: 'Poppins';
                padding: 10px 15px;
                border-top-left-radius: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                cursor: pointer;
                font-weight: 700;
                position: relative;

                &.selected {
                    background: #f5f5f5;

                    &:after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        border-bottom-right-radius: 20px;
                        border: solid 10px #f5f5f5;
                        border-top: none;
                        border-left: none;
                        transform: translate(-20px, 10px);
                    }

                    &:before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: 0%;
                        right: 0%;
                        border-bottom-left-radius: 20px;
                        border: solid 10px #f5f5f5;
                        border-top: none;
                        border-right: none;
                        transform: translate(+20px, 10px);
                    }
                }
            }

        }

        >div:nth-child(2) {
            flex: 1;
            border-radius: 20px;
            background: #f5f5f5;

            padding: 20px;
            overflow: auto;

            .rk_form {

                &.connexion {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }

                &:not(.connexion) {
                    width: 100%;
                    height: calc(100vh - 180px);

                }

                .formkit-wrapper {
                    width: 100%;
                    max-width: 100%;

                    .formkit-steps {
                        padding: 0;
                        border: none;
                        margin-top: 40px;
                        box-shadow: none;
                    }

                    .rk_search_map {
                        display: flex;
                        width: 100%;
                        border-color: white;
                        justify-content: space-between;

                        @include mq("mobile") {
                            flex-direction: column;
                            align-items: center;

                        }

                        @include mq("tablet") {
                            flex-direction: column;
                            align-items: center;

                        }

                        @include mq("desktop") {
                            justify-content: space-between;
                            flex-direction: row;
                        }

                        >div,
                        .formkit-outer {
                            @include mq("mobile") {
                                width: 100% !important;
                            }

                            @include mq("tablet") {
                                width: 100% !important;


                            }

                            @include mq("desktop") {
                                width: 45% !important;

                            }
                        }

                        >div {
                            width: 45%;
                        }
                    }
                }

            }


        }

    }

    .rk_map.inscription {
        aspect-ratio: 1/1;
    }

}

.logged_form {
    background-color: #f5f5f5;
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    .form {
        flex: 1;
        display: flex;
        align-items: end;
    }

    >a {
        padding: 12px;
        border-radius: 30px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-left: 12px;
        background-color: $inputColorhover;
        border: solid 1px $inputColorhover;
        position: relative;
        color: white;
        text-decoration: none;
        transition: all .4s;
        font-family: 'Poppins';


        &:hover {
            background-color: white;
            color: $inputColorhover;
        }
    }

    .form button {
        padding: 12px;
        border-radius: 30px;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-left: 12px;
        background-color: $inputColor;
        border: solid 1px $inputColor;
        position: relative;
        color: white;
        text-decoration: none;
        transition: all .4s;
        font-family: 'Poppins';
        cursor: pointer;

        &:hover {
            background-color: white;
            color: $inputColor;
        }

        margin-bottom:40px;
    }


}
</style>
<style lang="scss">
@import "../../assets/css/mixin.scss";

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.register_modal {
    &.rk_choiceData .rk_choice_container>div:nth-child(2) .rk_form .formkit-wrapper .rk_search_map .formkit-outer {
        width: 100% !important;

    }

    &.rk_choiceData .rk_choice_container>div:nth-child(2) .rk_form .formkit-wrapper .rk_search_map {
        >div {
            width: 100% !important;

        }

        flex-direction: column;
        width: 100%;
    }

    .formkit-outer[data-type="multi-step"]>[data-tab-style="progress"]>.formkit-steps {
        border: none;
        padding: 0;
        box-shadow: none;
    }

    .formkit-tab-label {
        color: $mainColorText !important;
    }

    .double {
        display: flex;
        flex-direction: column;

        .formkit-outer {
            width: 100% !important;

        }
    }

    .rk_choiceData .formkit-steps>.formkit-outer .formkit-input[type=button] {
        margin-bottom: 40px;
    }


}
</style>