import { defineStore } from 'pinia'
import { ref } from 'vue';
import { useLocalStorage } from "@vueuse/core"

export const usePrestaStore = defineStore('presta', () => {

    // use local storge permet de conserver en local storage les infos
    const currentPresta = ref(useLocalStorage('currentPresta', ''));

    function setCurrentPresta(presta) {
        currentPresta.value = presta;
    }



    return { currentPresta, setCurrentPresta }
})