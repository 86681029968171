import { defineStore } from 'pinia'
import { ref, computed } from 'vue';
import { useLocalStorage } from "@vueuse/core"

export const useLoginStore = defineStore('login', () => {
    // use local storge permet de conserver en local storage les infos
    const currentUser = ref(useLocalStorage('currentUser', ''));
    const currentToken = ref(useLocalStorage('currentToken', ''));
    const currentLatlng = ref(useLocalStorage('currentLatlng', { lat: '', lng: '' }));
    const currentServices = ref();
    const currentCategories = ref();

    const sessionExpire = ref(useLocalStorage('sessionExpire', ''));
    const isLogged = computed(() => currentToken.value !== '' && currentToken.value != "false")
    checkSession()
    function setUser(User) {
        currentUser.value = JSON.stringify(User);
        if (User !== false) {
            setLatlng(User.location.coordinates[1], User.location.coordinates[0])

        }
    }
    function setToken(token) {
        currentToken.value = token;
    }


    function setSessionExpire(date) {
        sessionExpire.value = date;
    }

    function setLatlng(lat, lng) {

        currentLatlng.value.lat = lat;
        currentLatlng.value.lng = lng;

    }
    function setServices(services) {

        currentServices.value = services;

    }

    function setCategories(categories) {
        currentCategories.value = categories;
    }


    function checkSession() {

        if (new Date() > new Date(sessionExpire.value)) {
            currentUser.value = 'false';
            currentToken.value = 'false';

        }
    }

    // à deplacer dans un autre fichier pinia

    const tutoSkiped = ref(useLocalStorage('tutoSkiped', false));

    function setTutoSkiped(isSkipped) {
        tutoSkiped.value = isSkipped;
    }
    const selectedPart = ref(useLocalStorage('selectedPart', 1));

    function setSelectedPart(SelectedPart) {
        selectedPart.value = SelectedPart;
    }

    const hasScrolled = ref(useLocalStorage('hasScrolled', false));

    function setHasScrolled(HasScrolled) {
        hasScrolled.value = HasScrolled;
    }

    const showModal = ref(useLocalStorage('showModal', false));

    function setShowModal(ShowModal) {
        showModal.value = ShowModal;
    }

    const activeMaps = ref(useLocalStorage('activeMaps', false));

    function setActiveMaps(ActiveMaps) {
        activeMaps.value = ActiveMaps;
    }

    const searchProduit = ref('');
    // function setSearchProduit(SearchProduit) {
    //     searchProduit.value = SearchProduit;
    // }
    return { searchProduit, activeMaps, setActiveMaps, showModal, setShowModal, setHasScrolled, hasScrolled, selectedPart, setSelectedPart, currentUser, currentToken, currentLatlng, setLatlng, setServices, setCategories, currentServices, currentCategories, setUser, setToken, isLogged, setSessionExpire, tutoSkiped, setTutoSkiped }
})