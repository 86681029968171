<template>
    <div class="rk_devis">
        <h2>Vos devis en attente :</h2>
        <div class="data-cards">
            <div v-for="(devi, indexDevi) in devis" v-bind:key="indexDevi" class="card">
                <h3>{{ devi.prestation }}</h3>
                <p>{{ devi.description }}</p>
                <a :href="`mailto:${devi.emailUser}?subject=Demande de devis de ${devi.prestation}`">
                    <FormKit type="button" prefix-icon="email">
                        Répondre par mail
                    </FormKit>
                </a>
                <div class="close" @click="deletDevis(devi, indexDevi)">
                    <span></span>
                    <span></span>

                    <svg viewBox="0 0 36 36" class="circle">
                        <path stroke-dasharray="100, 100"
                            d="M18 2.0845
                                                                                                                                                                                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                                                                                                                                                                                            a 15.9155 15.9155 0 0 1 0 -31.831" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onBeforeMount } from 'vue'
import { ref } from "vue";
import axios from "axios";
import { toast } from 'vue3-toastify';
import { useLoginStore } from '../../store/piniaLogin'

const storeLogin = useLoginStore()
const userData = ref(JSON.parse(storeLogin.currentUser));
const token = ref(storeLogin.currentToken);
const devis = ref();
const urlServer = ref(process.env.VUE_APP_SERVER_URL);

onBeforeMount(() => {
    axios.get(urlServer.value + '/devis/user/' + userData.value._id, {
        withCredentials: true,
        headers: {
            'Authorization': `Basic ${token.value}`
        }
    })
        .then(function (response) {
            // handle success
            console.log(response);
            devis.value = response.data;
        })
        .catch(function (error) {
            // handle error
            toast.error(error);
        })

});

async function deletDevis(devi, index) {
    const response = confirm("Voulez vous supprimer la demande de devis pour : " + devi.prestation);
    if (response) {
        axios.delete(process.env.VUE_APP_SERVER_URL + '/devis/user/' + devi._id, {
            headers: {
                'Authorization': `Basic ${token.value}`
            }
        })
            .then(function (response) {
                console.log(response);

                if (response.data == 'Devis supprimé') {
                    devis.value.splice(index, 1);
                }

            })
            .catch(function (error) {
                toast.error(error);
            });
    }
}   
</script>

<style lang="scss" scoped>
.rk_devis {
    padding: 20px;

    .data-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: start;

        .card {
            position: relative;
            flex: 1 1 250px;
            max-width: 300px;
            margin: 0 5px;
            background-color: #fff;
            border-radius: 0.5rem;
            padding: 1rem;
            margin: 1rem;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;

            a {
                align-self: center;
            }
        }
    }

    .close {
        position: absolute;
        top: -10px;
        right: -10px;
        $size: 20px;
        $borderSize: 2px;
        $borderColor: rgba(white, 1);
        $speed: .5s;
        width: $size;
        height: $size;
        background: #455A64;
        border-radius: 50%;
        box-shadow: 0 0 20px -5px rgba(white, .5);
        transition: .25s ease-in-out;
        cursor: pointer;
        animation: fade-in $speed ease-out .25s both;

        @keyframes fade-in {
            from {
                opacity: 0;
                transform: scale(1.1)
            }
        }

        .circle {
            path {
                stroke: $borderColor;
                fill: none;
                stroke-width: $borderSize/2;
                stroke-linecap: round;
                animation: progress $speed ease-out .25s both;

                @keyframes progress {
                    from {
                        stroke-dasharray: 0 100;
                    }
                }
            }
        }

        span {
            display: block;
            width: ($size/2);
            height: $borderSize - 1px;
            background: $borderColor;
            box-shadow: 0 0 20px -5px rgba(white, .5);
            border-radius: 20px;
            position: absolute;
            $padding: $size/3;
            transition: .25s ease-in-out;
            animation: slide-in $speed ease-in-out .25s both;

            @keyframes slide-in {
                from {
                    width: 0;
                }
            }

            &:nth-child(1) {
                top: ($padding - ($borderSize/2) +1px);
                left: $padding;
                transform: rotate(45deg);
                transform-origin: top left;
            }

            &:nth-child(2) {
                top: ($padding - ($borderSize/2) +1px);
                right: $padding;
                transform: rotate(-45deg);
                transform-origin: top right;
            }

        }

        &:hover {
            background: #37474F;


        }
    }
}
</style>
