<template>
    <div class="rk_modal">
        <div class="container">
            <img class="rk_k_bg" :src="serverUrl + '/uploads/kikekoi/image_K.svg'" alt="Logo K kikekoi">
            <h2>BIENVENUE SUR</h2>
            <img :src="serverUrl + '/uploads/kikekoi/logo.svg'" alt="Logo du site kikekoi">
            <hr>

            <div v-if="step == 1" class="presentation_kikekoi">
                <h1>Plateforme d'entraide et de prestataires</h1>
                <p>Kikekoi est la plateforme qui vous permettra de trouver tout ce que vous cherchez autour de chez vous !
                </p>
                <p>En cliquant sur le bouton j'accepte, vous acceptez l'utilisation de cookies par kikeKoi. Veuillez noter
                    que le site est actuellement en version bêta, ce qui signifie que des modifications significatives
                    peuvent intervenir à tout moment.
                </p>
                <p>
                    kikeKoi agit en tant que plateforme de mise en relation, facilitant la connexion. Nous tenons à
                    souligner que nous ne sommes pas responsables des éventuelles actions malveillantes de prestataires ou
                    d'utilisateurs. Nous recommandons la prudence.
                </p>
                <p>
                    Nous travaillons constamment à améliorer notre plateforme pour offrir une expérience utilisateur
                    optimale. Merci de votre compréhension et de votre confiance.
                    <br>
                    Bienvenu sur kikeKoi !
                </p>
                <div class="rk_btns">
                    <button class="rk_btn red" @click="step = 42">Je refuse 😔</button>
                    <button class="rk_btn" @click="step += 1">J'accepte et me connecte ou créer un compte</button>
                    <button class="rk_btn" @click="step += 2">J'accepte en restant invité</button>
                </div>

            </div>
            <div class="joke" v-if="step == 42">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/wVyggTKDcOE"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    autoplay></iframe>
                <button class="rk_btn" @click="step = 1">Ok la blague étais bonne !</button>
            </div>
            <div v-if="step == 2" class="rk_choiceData">
                <p> Maintenant que nous sommes d'accord, il ne nous reste plus qu'a aborder le principale : </p>

                <div v-if="!isSuccess" class="rk_choice_container">
                    <div>
                        <div class="rk_log_co" :class="{ selected: (logCo == 1) }" @click="logCo = 1">Connexion</div>
                        <div class="rk_log_co" :class="{ selected: (logCo == 2) }" @click="logCo = 2">Inscription</div>
                    </div>
                    <div>
                        <div class="rk_form connexion" v-if="logCo == 1">
                            <FormKit submit-label="Connexion" type="form" @submit="login">
                                <FormKit :floating-label="true" name="username" v-model="username" label="Pseudo ou email"
                                    validation="required" />
                                <FormKit type="password" name="password" :floating-label="true" id="login-password"
                                    v-model="password" label="Mot de passe" validation="required" />
                            </FormKit>
                        </div>
                        <div class="rk_form" v-if="logCo == 2">

                            <FormKit :allow-incomplete="false" type="multi-step" valid-step-icon="star" tab-style="progress"
                                ref="user">
                                <FormKit previous-label="Précedent" next-label="Suivant" type="step" valid-step-icon="check"
                                    label="Informations" name="stepOne">
                                    <div class="double">
                                        <FormKit :floating-label="true" type="text" label="Nom" name="nom"
                                            validation="required" />
                                        <FormKit :floating-label="true" type="text" label="Prenom" name="prenom"
                                            validation="required" />
                                    </div>
                                    <div class="double">
                                        <FormKit :floating-label="true" validation="required|email" type="email"
                                            label="E-mail" name="email" />
                                        <FormKit :floating-label="true" type="text" label="Pseudo" name="pseudo"
                                            validation="required" />
                                    </div>
                                    <div class="double">
                                        <FormKit :floating-label="true" type="password" name="password" label="Mot de passe"
                                            validation="required" />
                                        <FormKit :floating-label="true" type="password" label="Validation Mot de passe"
                                            name="password_confirm" validation="length:6,16|confirm" :validation-messages="{
                                                confirm: 'Les mots de passes ne sont pas identiques',
                                            }" />
                                    </div>
                                </FormKit>
                                <FormKit previous-label="Précedent" next-label="Suivant" type="step" valid-step-icon="check"
                                    label="Emplacement" name="stepTwo">
                                    <div class="rk_search_map">
                                        <div class="">
                                            <FormKit :floating-label="true" name="adresse" label="Adresse" />
                                            <FormKit :floating-label="true" name="codePostale" label="Code postale"
                                                validation="required" />
                                            <FormKit :floating-label="true" name="ville" label="Ville"
                                                validation="required" />


                                        </div>


                                        <div class="rk_map inscription">
                                            <CrosshairsIcon v-if="!islocked" class="hover_map"></CrosshairsIcon>
                                            <l-map ref="map" :zoom="18" :options="{ scrollWheelZoom: false }"
                                                :center="mapCenter" @update:center="centerUpdated" v-if="!hasMapchanged">
                                                <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    layer-type="base" name="OpenStreetMap"></l-tile-layer>
                                                <l-marker :lat-lng="mapCenter"></l-marker>


                                            </l-map>
                                        </div>

                                    </div>
                                    <template #stepNext>
                                        <FormKit type="button" @click="checkAdress">
                                            Vérifier l'adresse
                                        </FormKit>
                                        <!-- incrementStep returns a callable function -->

                                    </template>
                                </FormKit>
                                <FormKit v-if="adresse.lat != '' && adresse.lng != ''" type="button" @click="register"
                                    :class="'rk_insc_kikekoi'" label="M'inscrire sur KIKEKOI" />
                            </FormKit>

                        </div>
                    </div>
                </div>

                <div v-if="isSuccess" class="success-checkmark">
                    <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                    </div>
                </div>
            </div>
            <div v-if="step == 3" class="select_geoloc">
                <p>Nous avons détéctés que vous n'aviez pas encore défini de positions,
                    malheureusement kikekoi à besoin de votre position pour commencer!
                </p>
                <FormKit ref="user" type="form" class="rk_search_map">
                    <div class="">
                        <FormKit :floating-label="true" name="adresse" label="Adresse" />
                        <FormKit :floating-label="true" name="codePostale" label="Code postale" validation="required" />
                        <FormKit :floating-label="true" name="ville" label="Ville" validation="required" />

                        <span class="select_pos">
                            <FormKit type="button" class="rk_btn" @click="checkAdress" label="Vérifier cette adresse" />
                        </span>
                    </div>


                    <div class="rk_map inscription">
                        <CrosshairsIcon v-if="!islocked" class="hover_map"></CrosshairsIcon>
                        <l-map ref="map" :zoom="18" :center="mapCenter" :options="{ scrollWheelZoom: false }"
                            @update:center="centerUpdated" v-if="!hasMapchanged">
                            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                                name="OpenStreetMap"></l-tile-layer>
                            <l-marker :lat-lng="mapCenter"></l-marker>


                        </l-map>
                    </div>

                    <FormKit v-if="adresse.lat != '' && adresse.lng != ''" type="button" @click="registerInvite"
                        label="Aller sur KIKEKOI" />
                </FormKit>


            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, defineEmits } from 'vue';

import { useLoginStore } from '../../store/piniaLogin'
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";

import CrosshairsIcon from 'vue-material-design-icons/Crosshairs.vue';
import axios from 'axios';
import { toast } from 'vue3-toastify';



const store = useLoginStore();
const username = ref("");
const password = ref("");
const map = ref();
const user = ref();
const adresse = ref({ lat: "", lng: "" });
const logCo = ref(1);
const step = ref(1);
const islocked = ref(true);
const hasMapchanged = ref(false);
const isSuccess = ref(false);

const mapCenter = ref([45.692341, 5.908998])
defineEmits(['close'])
const serverUrl = process.env.VUE_APP_SERVER_URL;

function centerUpdated(center) {
    if (!islocked.value) {
        mapCenter.value = center;

    }
}


async function login() {
    await axios.post(process.env.VUE_APP_SERVER_URL + '/login', { username: username.value, password: password.value }, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
        withCredentials: true
    }).then(function (response) {
        store.setUser(response.data);
        store.setToken(response.data.token);
        store.setLatlng(response.data.location.coordinates[1], response.data.location.coordinates[0]);

        store.setSessionExpire(new Date(Date.now() + 72 * (60 * 60 * 1000)));

        isSuccess.value = true;

        toast.success('Vous êtes conneté !');
    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });


}
async function register() {
    const form = user.value.node.value;
    const data = {
        prenom: form.stepOne.prenom,
        nom: form.stepOne.nom,
        pseudo: form.stepOne.pseudo,
        email: form.stepOne.email,
        password: form.stepOne.password,
        adresse: {
            codePostale: form.stepTwo.codePostale,
            ville: form.stepTwo.ville,
            rue: form.stepTwo.adresse
        },
        location: {
            type: 'Point',
            coordinates: [
                parseFloat(adresse.value.lng),
                parseFloat(adresse.value.lat)
            ]
        }

    }
    try {
        const res = await axios.post(process.env.VUE_APP_SERVER_URL + '/users', data);
        store.setUser(res.data);
        store.setToken(res.data.token);
        store.setLatlng(res.data.location.coordinates[1], res.data.location.coordinates[0]);
        toast.success('Bienvenue sur kikekoi !');
    } catch (error) {
        console.log(error);
        toast.error(error.message);

    }


    // console.log(userData.value);
}

function registerInvite() {
    store.setLatlng(adresse.value.lat, adresse.value.lng);
}
function checkAdress() {
    hasMapchanged.value = true;
    let codePostale = "";
    let ville = "";
    let adresseCp = "";

    if (user.value.node.value.stepTwo !== undefined) {
        codePostale = user.value.node.value.stepTwo.codePostale;
        ville = user.value.node.value.stepTwo.ville;
        adresseCp = user.value.node.value.stepTwo.adresse;
    } else {
        codePostale = user.value.node.value.codePostale;
        ville = user.value.node.value.ville;
        adresseCp = user.value.node.value.adresse;
    }

    axios.get(`https://dev.virtualearth.net/REST/v1/Locations/FR/${codePostale}/${ville}/${adresseCp}?key=AoDJrgfVItWTs_icYLtdaizY88Uv3yj-98Tr03Cr6TR1VONQwP3tPZw0EY-JWTaL`)
        .then(function (response) {
            // handle success
            mapCenter.value = [response.data.resourceSets[0].resources[0].point.coordinates[0], response.data.resourceSets[0].resources[0].point.coordinates[1]];

            adresse.value.lat = response.data.resourceSets[0].resources[0].point.coordinates[0];
            adresse.value.lng = response.data.resourceSets[0].resources[0].point.coordinates[1];
            hasMapchanged.value = false;

        })
        .catch(function (error) {
            // handle error
            hasMapchanged.value = false;

            console.log(error);
        })
}

</script>


<style scoped lang="scss">
@import "../../assets/css/mixin.scss";

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_modal {
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(10px);

    .container {
        background-color: #d2efe8;

        border-radius: 20px;
        padding: 40px;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include mq("mobile") {
            width: 100vw;
            max-width: inherit;
            height: 100vh;

        }

        @include mq("tablet") {
            width: 80vw;
            max-width: 980px;
            height: 80vh;

        }



        h2 {
            width: 100%;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            color: $textColorBlue;
            position: relative;
        }

        hr {
            border-top: 2px solid $inputColor;
            width: 100px;
            border-radius: 15px;
            margin-top: 20px;
        }

        p {
            font-family: "Poppins";
            color: #012577;
            width: 80%;
        }

        .rk_k_bg {
            position: absolute;
            z-index: -1;
            height: 100%;
        }


        .select_geoloc {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            z-index: 1;

            @include mq("mobile") {
                overflow: scroll;
                flex-direction: row;
                width: 100%;
            }

            @include mq("tablet") {
                overflow: visible;
                flex-direction: column;
                width: 80%;

            }

            p {
                width: 100%;
                text-align: center;
            }

            form {
                padding: 2px;
            }

            .select_pos {
                transform: translateY(-50%);
                z-index: 1000;
                position: relative;
            }

            h2 {
                width: 100%;

            }

        }

        .rk_map {
            position: relative;

            &:not(.inscription) {
                height: 50vh;
                width: 100%;
                max-width: 980px;

            }

            &.inscription {
                aspect-ratio: 1/1;

                >div {
                    border-radius: 20px;

                }
            }

            .hover_map {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1000;
            }

            .locate_me {
                background: white;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                box-shadow: var(--fk-border-box-shadow);
                margin-left: 3px;
                cursor: pointer;
                margin-bottom: 10px;


                span {
                    height: 24px;
                }
            }

            .leaflet-container {
                >div:nth-child(3) {
                    z-index: 1000;
                }
            }

        }
    }

}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;

    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        overflow: hidden;
        border: 4px solid #4CAF50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }

        &::before,
        &::after {
            content: '';
            height: 100px;
            position: absolute;
            transform: rotate(-45deg);
        }

        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }

            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}



.presentation_kikekoi {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    h1 {
        text-align: center;
    }

    @include mq("mobile") {
        overflow: scroll;

    }

    @include mq("tablet") {
        overflow: visible;

    }



}

.rk_btns {
    flex-grow: 1;
    align-items: end;
    display: flex;
    width: 100%;

    @include mq("mobile") {
        flex-direction: column;
        align-items: center;

    }

    @include mq("tablet") {
        flex-direction: column;
        align-items: center;

    }

    @include mq("desktop") {
        justify-content: space-evenly;

        flex-direction: row;
    }

    .rk_btn {
        @include mq("mobile") {
            margin-bottom: 20px;

        }

        @include mq("tablet") {
            margin-bottom: 20px;

        }

        @include mq("desktop") {
            margin-bottom: 0px;
        }

        border-radius: 30px;
        background-color: $mainColorBlue;
        border: solid 1px $mainColorBlue;
        position: relative;
        overflow: hidden;
        transition: all .4s;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Poppins';
        color: white;
        margin: 0;
        padding: 10px 15px;

        &:hover {
            color: $mainColorBlue;

            background-color: white;
        }


    }
}


.rk_choiceData {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        text-align: center;
    }

    .rk_choice_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        >div:nth-child(1) {
            display: flex;
            justify-content: center;

            .rk_log_co {

                padding: 10px 15px;
                border-top-left-radius: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                cursor: pointer;
                font-weight: 700;
                position: relative;

                &.selected {
                    background: $inputColor;
                    color: white;

                    &:after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        border-bottom-right-radius: 20px;
                        border: solid 10px $inputColor;
                        border-top: none;
                        border-left: none;
                        transform: translate(-20px, 10px);
                    }

                    &:before {
                        content: '';
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: 0%;
                        right: 0%;
                        border-bottom-left-radius: 20px;
                        border: solid 10px $inputColor;
                        border-top: none;
                        border-right: none;
                        transform: translate(+20px, 10px);
                    }
                }
            }

        }

        >div:nth-child(2) {
            flex: 1;
            border-radius: 20px;
            background: $inputColor;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .rk_form {
                &:not(.connexion) {
                    width: 100%;
                }

                .formkit-wrapper {
                    width: 100%;
                    max-width: 100%;

                    .formkit-steps {
                        padding: 0;
                        border: none;
                        margin-top: 40px;
                        box-shadow: none;
                    }

                    .rk_search_map {
                        display: flex;
                        width: 100%;
                        border-color: white;
                        justify-content: space-between;

                        @include mq("mobile") {
                            flex-direction: column;
                            align-items: center;

                        }

                        @include mq("tablet") {
                            flex-direction: column;
                            align-items: center;

                        }

                        @include mq("desktop") {
                            justify-content: space-between;
                            flex-direction: row;
                        }

                        >div,
                        .formkit-outer {
                            @include mq("mobile") {
                                width: 100% !important;
                                margin-right: 20px;
                            }

                            @include mq("tablet") {
                                width: 100% !important;
                                margin-right: 20px;


                            }

                            @include mq("desktop") {
                                width: 100% !important;
                                margin-right: 20px;

                            }
                        }

                        >div {
                            width: 45%;
                        }
                    }
                }

            }


        }

    }


}
</style>

<style lang="scss">
@import "../../assets/css/mixin.scss";

$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.formkit-outer[data-type="multi-step"]>.formkit-wrapper {
    max-width: 100% !important;
}

.select_geoloc {




    .formkit-input[type="button"] {
        border-radius: 30px;
        background-color: $mainColorBlue;
        border: solid 1px $mainColorBlue;
        position: relative;
        overflow: hidden;
        transition: all .4s;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Poppins';
        color: white;
        margin: 0;
        padding: 10px 15px;

        &:hover {
            color: $mainColorBlue;

            background-color: white;
        }


    }

    .formkit-wrapper {
        @include mq("mobile") {
            text-align: center;
        }

        @include mq("tablet") {
            text-align: center;
        }

        @include mq("desktop") {
            text-align: none;
        }
    }

    .rk_map {
        .leaflet-top.leaflet-right {
            width: 30%;

            .leaflet-control {
                width: 100%;
            }
        }

    }

    .formkit-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq("mobile") {
            flex-direction: column;
            align-items: center;

        }

        @include mq("tablet") {
            flex-direction: column;
            align-items: center;

        }

        @include mq("desktop") {
            justify-content: space-evenly;

            flex-direction: row;
        }

        >div {

            @include mq("mobile") {
                width: 100%;
            }

            @include mq("tablet") {
                width: 100%;
            }

            @include mq("desktop") {
                width: 45%;
            }

        }

        >.formkit-outer {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: center;

            .formkit-input[type="button"] {
                color: white;

                background-color: $inputColorhover;
                border: solid 1px $inputColorhover;

                &:hover {
                    color: $inputColorhover;

                    background-color: white;
                }
            }
        }
    }

    button.formkit-input[type="submit"] {
        display: none;
    }
}

.rk_choiceData {
    .formkit-tab-label {
        color: white;
        font-family: 'Poppins';

    }

    .formkit-tab::after,
    .formkit-badge {
        background: $inputColorhover !important;
    }

    .formkit-step-inner {
        margin-bottom: 20px;
    }

    .formkit-input[type="button"] {
        border-radius: 30px;
        background-color: $mainColorGreen;
        border: solid 1px $mainColorGreen;
        position: relative;
        overflow: hidden;
        transition: all .4s;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Poppins';
        color: $textColorBlue ;
        margin: 0;

        &:hover {
            color: $mainColorGreen;

            background-color: $textColorBlue;
        }


    }

    .formkit-steps {
        >.formkit-outer {
            text-align: center;
            margin-top: 40px;

            .formkit-input[type="button"] {
                color: white;

                background-color: $inputColorhover;
                border: solid 1px $inputColorhover;

                &:hover {
                    color: $inputColorhover;

                    background-color: white;
                }
            }
        }
    }

    .formkit-inner {
        box-shadow: none;





        .formkit-input {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            font-family: 'Poppins';
            font-weight: 700;
        }

        .formkit-label {
            font-family: 'Poppins';
            font-weight: 700;
            color: #161615;
            border-radius: 5px;
            padding: 5px 10px;
            left: 0;
        }
    }

    .formkit-actions {
        .formkit-wrapper {
            text-align: center;
            width: 100%;
            max-width: 100%;

            .formkit-input[type="submit"] {
                align-self: center;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
                font-family: 'Poppins';
                font-weight: 700;
                color: $inputColor;
                transition: all .4s;
                border: solid 1px white;

                &:hover {
                    background-color: $inputColor;
                    color: white;

                }

            }
        }
    }
}

.double {
    @include mq("mobile") {
        flex-direction: column;

    }

    @include mq("tablet") {
        flex-direction: column;

    }

    @include mq("desktop") {
        flex-direction: row;

    }


    .formkit-outer {
        @include mq("mobile") {
            width: 100%;

        }

        @include mq("tablet") {
            width: 100%;

        }

        @include mq("desktop") {
            width: 45%;

        }
    }
}


.rk_modal {
    .formkit-message {
        color: white;
    }
}

.rk_modal .formkit-message {
    color: $textColorBlue;
}
</style>