import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'
import Prestataires from '../views/prestataires/fichePrestataireView.vue'
import Produits from '../views/produits/ProduitsView.vue'

import { useLoginStore } from '@/store/piniaLogin';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Accueil',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView
  },
  {
    path: '/presta/:slug',
    name: 'Prestataires',
    component: Prestataires
  },
  {
    path: '/produit/:id',
    name: 'Produits',
    component: Produits
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  const store = useLoginStore()
  document.title = to.name as string;
  if (!store.isLogged && to.name == 'Admin') {


    return { name: 'Accueil' }
  }
})

export default router
