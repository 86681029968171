import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'

import { plugin, defaultConfig } from '@formkit/vue'
import router from './router'
import { createFloatingLabelsPlugin, createMultiStepPlugin, createAutoAnimatePlugin, createAutoHeightTextareaPlugin } from '@formkit/addons'
import { fr } from '@formkit/i18n'
import { createProPlugin, autocomplete, toggle, taglist } from '@formkit/pro'
import '@formkit/themes/genesis'
import '@formkit/pro/genesis'
import '@formkit/addons/css/multistep'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import MenuIcon from 'vue-material-design-icons/Menu.vue';
import BaguetteIcon from 'vue-material-design-icons/Baguette.vue';
import TractorIcon from 'vue-material-design-icons/Tractor.vue';
import CowIcon from 'vue-material-design-icons/Cow.vue';
import HomeCityOutlineIcon from 'vue-material-design-icons/HomeCityOutline.vue';
import PoliceBadgeOutlineIcon from 'vue-material-design-icons/PoliceBadgeOutline.vue';
import PaletteOutlineIcon from 'vue-material-design-icons/PaletteOutline.vue';
import PiggyBankOutlineIcon from 'vue-material-design-icons/PiggyBankOutline.vue';
import FlaskIcon from 'vue-material-design-icons/Flask.vue';
import AccountHardHatOutlineIcon from 'vue-material-design-icons/AccountHardHatOutline.vue';
import MultimediaIcon from 'vue-material-design-icons/Multimedia.vue';
import StoreOutlineIcon from 'vue-material-design-icons/StoreOutline.vue';
import DramaMasksIcon from 'vue-material-design-icons/DramaMasks.vue';
import ScaleBalanceIcon from 'vue-material-design-icons/ScaleBalance.vue';
import BookOpenPageVariantOutlineIcon from 'vue-material-design-icons/BookOpenPageVariantOutline.vue';
import LightningBoltOutlineIcon from 'vue-material-design-icons/LightningBoltOutline.vue';
import WindTurbineIcon from 'vue-material-design-icons/WindTurbine.vue';
import HumanMaleBoardIcon from 'vue-material-design-icons/HumanMaleBoard.vue';
import WaterSyncIcon from 'vue-material-design-icons/WaterSync.vue';
import AccountCogOutlineIcon from 'vue-material-design-icons/AccountCogOutline.vue';
import SilverwareVariantIcon from 'vue-material-design-icons/SilverwareVariant.vue';
import HospitalBoxOutlineIcon from 'vue-material-design-icons/HospitalBoxOutline.vue';
import RemoteDesktopIcon from 'vue-material-design-icons/RemoteDesktop.vue';
import EarthIcon from 'vue-material-design-icons/Earth.vue';
import FactoryIcon from 'vue-material-design-icons/Factory.vue';
import CarWrenchIcon from 'vue-material-design-icons/CarWrench.vue';
import HumanWheelchairIcon from 'vue-material-design-icons/HumanWheelchair.vue';
import ChartLineIcon from 'vue-material-design-icons/ChartLine.vue';
import FaxIcon from 'vue-material-design-icons/Fax.vue';
import ContentCutIcon from 'vue-material-design-icons/ContentCut.vue';
import RunIcon from 'vue-material-design-icons/Run.vue';
import TruckFastOutlineIcon from 'vue-material-design-icons/TruckFastOutline.vue';
import BullhornOutlineIcon from 'vue-material-design-icons/BullhornOutline.vue';


const proPlugin = createProPlugin('fk-5f4761dec6', {
    autocomplete,
    toggle,
    taglist
})
const pinia = createPinia()

createApp(App).use(pinia).use(router).use(plugin, defaultConfig({
    locales: { fr },
    // Define the active locale
    locale: 'fr',
    plugins: [
        proPlugin,

        createMultiStepPlugin(),
        createFloatingLabelsPlugin(),
        createAutoHeightTextareaPlugin(),
        createAutoAnimatePlugin({
            /* optional AutoAnimate config */
            // default:
            duration: 250,
            easing: 'ease-in-out',
        },
            {
                /* optional animation targets object */
                // default:
                global: ['outer', 'inner'],
                form: ['form'],
                repeater: ['items'],
            })
    ]
}))
    .use(Vue3Toastify, {
        autoClose: 4000,
    } as ToastContainerOptions)
    .component('menu-icon', MenuIcon)
    .component('tractor-icon', TractorIcon)
    .component('baguette-icon', BaguetteIcon)
    .component('cow-icon', CowIcon)
    .component('home-city-outline-icon', HomeCityOutlineIcon)
    .component('police-badge-outline-icon', PoliceBadgeOutlineIcon)
    .component('palette-outline-icon', PaletteOutlineIcon)
    .component('piggy-bank-outline-icon', PiggyBankOutlineIcon)
    .component('flask-icon', FlaskIcon)
    .component('account-hard-hat-outline-icon', AccountHardHatOutlineIcon)
    .component('multimedia-icon', MultimediaIcon)
    .component('store-outline-icon', StoreOutlineIcon)
    .component('drama-masks-icon', DramaMasksIcon)
    .component('scale-balance-icon', ScaleBalanceIcon)
    .component('book-open-page-variant-outline-icon', BookOpenPageVariantOutlineIcon)
    .component('lightning-bolt-outline-icon', LightningBoltOutlineIcon)
    .component('wind-turbine-icon', WindTurbineIcon)
    .component('human-male-board-icon', HumanMaleBoardIcon)
    .component('water-sync-icon', WaterSyncIcon)
    .component('account-cog-outline-icon', AccountCogOutlineIcon)
    .component('silverware-variant-icon', SilverwareVariantIcon)
    .component('hospital-box-outline-icon', HospitalBoxOutlineIcon)
    .component('remote-desktop-icon', RemoteDesktopIcon)
    .component('earth-icon', EarthIcon)
    .component('factory-icon', FactoryIcon)
    .component('car-wrench-icon', CarWrenchIcon)
    .component('human-wheelchair-icon', HumanWheelchairIcon)
    .component('chart-line-icon', ChartLineIcon)
    .component('fax-icon', FaxIcon)
    .component('content-cut-icon', ContentCutIcon)
    .component('run-icon', RunIcon)
    .component('truck-fast-outline-icon', TruckFastOutlineIcon)
    .component('bullhorn-outline-icon', BullhornOutlineIcon)

    .mount('#app')

