<template>
    <div class="formkit-outer" data-family="text" data-type="text">
        <div class="formkit-wrapper">
            <label class="formkit-label">{{ label }}</label>


            <div class="container">
                <div class="searchInput" v-bind:class="{ active: searchInput }">

                    <input v-model="input" type="text" placeholder="Recherche...">
                    <div class="resultBox">
                        <!-- here list are inserted from javascript -->
                        <li @click="$emit('itemSelected', result); searchInput = false;" v-for="(result, index) in results"
                            v-bind:key="index"> {{
                                result.titre }}
                        </li>
                        <li v-if="results.length == 0 && type == 'Services'"
                            @click="$emit('itemSelected', { is_new: true, titre: input }); searchInput = false;"> Créer ce
                            service
                        </li>
                    </div>
                    <!-- // selectionne la catégorie en fonction de l'input -->
                    <div class="icon">
                        <i class="fas fa-search">

                        </i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script setup>
import { ref, watch, defineProps } from "vue";
import { useAdminStore } from '../../store/piniaAdmin'
const store = useAdminStore()

const props = defineProps({
    type: String,
    label: String,
    items: Array
})

// getting all required elements
const searchInput = ref(false);
const input = ref('');
const results = ref([]);
// const resultBox = searchInput.querySelector(".resultBox");
// const icon = searchInput.querySelector(".icon");
// let linkTag = searchInput.querySelector("a");
// let webLink;


watch(input, (newValue) => {

    if (newValue) {

        let datas = '';
        if (props.items !== '' && props.items !== undefined) {
            datas = props.items;
        } else {
            switch (props.type) {
                case 'Catégorie':
                    datas = store.categories;
                    break;

                case 'Services':
                    datas = store.services;
                    break;

                default:
                    break;
            }
        }


        results.value = datas.filter((data) => {
            //filtering array value and user characters to lowercase and return only those words which are start with user enetered chars
            return data.titre.toLocaleLowerCase().startsWith(newValue.toLocaleLowerCase());
        });

        searchInput.value = true;

    } else {
        searchInput.value = false;
    }
});


</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

::selection {
    color: #fff;
    background: #664AFF;
}

.container {
    max-width: 450px;
    box-shadow: var(--fk-border-box-shadow);
    border-radius: var(--fk-border-radius);
    max-width: var(--fk-max-width-input);

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    .searchInput {
        background: #fff;
        width: 100%;
        border-radius: 5px;
        position: relative;

        input {
            height: 42px;
            width: 100%;
            outline: none;
            border: none;
            border-radius: 5px;
            padding: 0 60px 0 20px;
            font-size: 18px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        }

        &.active {
            input {
                border-radius: 5px 5px 0 0;
            }

            .resultBox {
                padding: 10px 8px;
                opacity: 1;
                pointer-events: auto;

                li {
                    display: block;
                }
            }
        }

        .resultBox {
            padding: 0;
            opacity: 0;
            pointer-events: none;
            max-height: 280px;
            overflow-y: auto;

            li {
                list-style: none;
                padding: 8px 12px;
                display: none;
                width: 100%;
                cursor: default;
                border-radius: 3px;

                &:hover {
                    background: #efefef;
                }
            }
        }

        .icon {
            position: absolute;
            right: 0px;
            top: 0px;
            height: 55px;
            width: 55px;
            text-align: center;
            line-height: 55px;
            font-size: 20px;
            color: #644bff;
            cursor: pointer;
        }
    }
}
</style>
