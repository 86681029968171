<template>
    <aside v-if="userData.typeDeCompte == 'admin'" class="rk_sidebar" name="sidebar">
        <div class="logo">
            <router-link to="/">
                <img :src="serverUrl + '/uploads/kikekoi/logo-bleu.svg'" alt="Logo du site kikekoi">

            </router-link>
        </div>
        <div @click="$emit('changePage', 1)">
            <p :class="{ selected: (selected == 1) }"><svg class="w-5 h-5" aria-hidden="true" fill="none"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                    </path>
                </svg><span>Accueil</span></p>
        </div>
        <div @click="$emit('changePage', 2)">
            <p :class="{ selected: (selected == 2) }"><span>Ajout de catégories</span> </p>
        </div>
        <div @click="$emit('changePage', 3)">
            <p :class="{ selected: (selected == 3) }"><span>Ajout de prestataires</span></p>
        </div>
        <div @click="$emit('changePage', 4)">
            <p :class="{ selected: (selected == 4) }"><span>Services</span></p>
        </div>
        <div @click="$emit('changePage', 5)">
            <p :class="{ selected: (selected == 5) }"><span>Mon compte</span></p>
        </div>
        <form class="form form-login" @submit.prevent="logout">

            <button type="submit" class="btn-login">Déconnexion</button>
        </form>
    </aside>
    <aside v-if="userData.typeDeCompte == 'prestataire' || userData.entreprises.length > 0" class="rk_sidebar"
        name="sidebar">
        <div class="logo"><a href="/">
                <img :src="serverUrl + '/uploads/kikekoi/logo-bleu.svg'" alt="Logo du site kikekoi">
            </a>
        </div>
        <div @click="$emit('changePage', 5)">
            <p :class="{ selected: (selected == 5) }"><span>Mon compte</span></p>
        </div>

        <div @click="$emit('changePage', 6)">
            <p :class="{ selected: (selected == 6) }"><span>Mes entreprises</span></p>
        </div>
        <div @click="$emit('changePage', 7)">
            <p :class="{ selected: (selected == 7) }"><span>Devis en attente</span></p>
        </div>
        <div @click="$emit('changePage', 8)">
            <p :class="{ selected: (selected == 8) }"><span>Mes produits</span></p>
        </div>
        <div @click="$emit('changePage', 9)">
            <p :class="{ selected: (selected == 9) }"><span>Commandes</span></p>
        </div>
        <div @click="$emit('changePage', 10)">
            <p :class="{ selected: (selected == 10) }"><span>Mes événements</span></p>
        </div>
        <form class="form form-login" @submit.prevent="logout">

            <button type="submit" class="btn-login">Déconnexion</button>
        </form>
    </aside>

    <aside v-if="userData.typeDeCompte == 'utilisateur' && userData.entreprises.length == 0" class="rk_sidebar"
        name="sidebar">
        <div class="logo"><a href="/">
                <img :src="serverUrl + '/uploads/kikekoi/logo-bleu.svg'" alt="Logo du site kikekoi">
            </a>
        </div>
        <div @click="$emit('changePage', 5)">
            <p :class="{ selected: (selected == 5) }"><span>Mon compte</span></p>
        </div>

        <div @click="$emit('changePage', 6)">
            <p :class="{ selected: (selected == 6) }"><span>Mes entreprises</span></p>
        </div>

        <form class="form form-login" @submit.prevent="logout">

            <button type="submit" class="btn-login">Déconnexion</button>
        </form>
    </aside>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useLoginStore } from '../../store/piniaLogin'
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useLoginStore()
const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
import { toast } from 'vue3-toastify';

defineEmits(["changePage"])

defineProps({
    selected: Number
})
const userData = ref(JSON.parse(store.currentUser));

async function logout() {
    try {
        store.setUser(false);
        store.setToken(false);

        router.push({ path: '/' })
    } catch (error) {
        console.log(error);
        toast.error(error.response.data);

    }
}
</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;


.rk_sidebar {
    background-color: $mainColorBlue;
    min-width: 16rem;
    padding: 40px 0 40px 40px;

    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: calc(100vh - 80px);

    .logo {
        margin-bottom: 40px;
    }

    p {
        svg {
            width: 20px;
            margin-right: 10px;
            transform: translateY(25%);
        }

        &.selected {
            background-color: white;
            color: $mainColorBlue;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            position: relative;

            :before {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                top: 0%;
                right: 0%;
                border-bottom-right-radius: 40px;
                border: solid 20px white;
                border-left: none;
                border-top: none;
                transform: translate(20px, -40px);
            }

            :after {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                bottom: 0%;
                right: 0%;
                border-top-right-radius: 40px;
                border: solid 20px white;
                border-left: none;
                border-bottom: none;
                transform: translate(20px, 40px);
            }
        }


        cursor: pointer;
        margin: 0;
        padding: 20px;
        color: white;
        font-weight: 600;
        font-family: 'Poppins';

    }

    .form-login {
        margin-top: auto;

        button {
            background-color: white;
            color: $inputColor;
            border: solid 1px white;
            padding: 10px 15px;
            font-family: 'Poppins';
            font-weight: 600;
            border-radius: 40px;
            cursor: pointer;
            transition: all .4s;

            &:hover {
                background-color: $inputColor;
                color: white;

            }
        }

    }
}
</style>