import { defineStore } from 'pinia'
import { ref } from 'vue';
import { useLocalStorage } from "@vueuse/core"

export const usePanierStore = defineStore('panier', () => {

    // use local storge permet de conserver en local storage les infos
    const currentOrder = ref(useLocalStorage('currentOrder', []));

    function setCurrentOrder(product, qte) {

        const index = currentOrder.value.findIndex(object => object._id === product._id);
        if (index === -1) {
            product.qte = 0;
            product.qte = product.qte + qte.value;


            currentOrder.value.push(product);
        }
        else {
            currentOrder.value[index].qte = currentOrder.value[index].qte + qte.value;
        }

    }

    const cartOpened = ref(false);

    function setCartOpened(value) {
        cartOpened.value = value;
    }

    function clearCart() {
        cartOpened.value = false;
        currentOrder.value = [];
    }


    return { currentOrder, setCurrentOrder, setCartOpened, cartOpened, clearCart }
})