<template>
    <div class="rk_scheduler">

        <div class="rk_sous_titre">
            <h2>Réserver un créneaux</h2>
        </div>

        <div class="rk_reservation">
            <div v-if="currentPos !== 0" class="rk_ctrl_left" @click="prevDay">
                <p> &lt; </p>
            </div>
            <div class="rk_content">
                <div class="rk_day_content" v-for="(jour, index) in days" :key="index">
                    <div class="rk_jour"> {{ jour.jour }}</div>
                    <div class="rk_date">{{ jour.date }}</div>
                    <div class="booking" v-for="(crenneau, indexCre) in jour.creneaux.all" :key="indexCre">
                        <div class="booking_elem not" v-if="!jour.creneaux.avaiable.includes(crenneau)">-</div>
                        <div class="booking_elem" v-else>{{ crenneau }}</div>
                    </div>
                </div>
            </div>
            <div class="rk_ctrl_right" @click="addDay">
                <p>></p>
            </div>
        </div>

    </div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue'
import { usePrestaStore } from '../../store/piniaPresta'
const storePresta = usePrestaStore();



const nbJoursCharges = ref(5);
const jourOuvrables = ref([])
const currentPos = ref(0);
const days = ref([]);
const loadedDays = ref([]);
const nbPastDays = ref(0);

// Parcourir chaque jour de la semaine
const creneauxNonFiltre = ref({});


// à ajouter en bdd
const tpsParCreneaux = ref(45);
const crenauxBooked = ref({
    [getDay(0) + getDate(0)]: [
        "08:00",
        "08:45",
        "09:30",
        "10:15",
    ],
    [getDay(1) + getDate(1)]: [
        "08:00",
        "08:45",
        "09:30",
        "10:15",
        "11:00",
        "11:45",
        "12:30"
    ],
    [getDay(2) + getDate(2)]: [
        "08:00",
        "08:45"

    ],
    [getDay(3) + getDate(3)]: [

    ],
    [getDay(4) + getDate(4)]: [
        "08:00",
        "08:45",
        "09:30",
        "14:45",
        "15:30",
        "16:15",
        "17:00"
    ],
    [getDay(5) + getDate(5)]: [

        "08:45",

        "10:15",
        "11:00"
    ],
    [getDay(6) + getDate(6)]: [],
})






onBeforeMount(() => {
    const creneaux = [];
    const horairesOuverts = storePresta.currentPresta.horaires;
    for (const jour in horairesOuverts) {

        if (horairesOuverts[jour].m !== 'Fermé' || horairesOuverts[jour].a !== 'Fermé') {
            jourOuvrables.value.push(jour.substring(0, 3) + '.')


        }
        const horaires = horairesOuverts[jour];
        const creneauxJour = [];

        // Traiter les jours avec des horaires spéciaux
        if (horaires.m !== "Fermé" && horaires.m.includes("-")) {
            const [heureDebut, heureFin] = horaires.m.split(" - ");

            let creneau = parseHeure(heureDebut);
            const heureFinMinutes = parseHeure(heureFin);

            while (ajouterDuree(creneau, tpsParCreneaux.value) <= heureFinMinutes) {
                const prochainCreneau = ajouterDuree(creneau, tpsParCreneaux.value);
                creneauxJour.push(formatHeure(creneau));
                creneau = prochainCreneau;


            }
        }

        if (horaires.a !== "Fermé" && horaires.a.includes("-")) {
            const [heureDebut, heureFin] = horaires.a.split(" - ");
            let creneau = parseHeure(heureDebut);
            const heureFinMinutes = parseHeure(heureFin);

            while (creneau < heureFinMinutes) {
                if (ajouterDuree(creneau, tpsParCreneaux.value) <= heureFinMinutes) {
                    creneauxJour.push(formatHeure(creneau));
                    creneau = ajouterDuree(creneau, tpsParCreneaux.value);
                } else {
                    break;
                }
            }
        }

        if (horaires.m !== "Fermé" && horaires.a !== "Fermé" && !horaires.a.includes("-") && !horaires.m.includes("-")) {
            const heureDebut = parseHeure(horaires.m);
            const heureFin = parseHeure(horaires.a);

            let creneau = heureDebut;
            while (creneau < heureFin) {
                if (ajouterDuree(creneau, tpsParCreneaux.value) <= heureFin) {
                    creneauxJour.push(formatHeure(creneau));
                    creneau = ajouterDuree(creneau, tpsParCreneaux.value);
                } else {
                    break;
                }
            }
        }

        creneaux[jour] = creneauxJour;
    }
    creneauxNonFiltre.value = creneaux;
    initDays();

});


function initDays() {
    const allDays = [];
    let index = 0;
    while (allDays.length != nbJoursCharges.value) {
        const jour = getDay(index);
        const date = getDate(index);

        if (jourOuvrables.value.includes(getDay(index))) {
            const creneaux = getCreneaux(index, jour, jour + date);
            allDays.push({ jour: jour, date: date, creneaux: creneaux })
        }

        index++;
    }
    days.value = allDays;
    loadedDays.value = allDays;
    nbPastDays.value = index;
}

function addDay() {
    if (loadedDays.value.length == (currentPos.value + 5)) {
        let index = nbPastDays.value;

        while (loadedDays.value.length != nbJoursCharges.value + 1) {
            const jour = getDay(index);
            const date = getDate(index);
            if (jourOuvrables.value.includes(jour)) {
                const creneaux = getCreneaux(index, jour, jour + date);
                loadedDays.value.push({ jour: jour, date: date, creneaux: creneaux })
            }

            index++;
        }
        nbPastDays.value = index;
        nbJoursCharges.value += 1;
        days.value = loadedDays.value.slice(1).slice(-5);
    } else {
        days.value = loadedDays.value.slice(currentPos.value + 1, currentPos.value + 6);

    }
    currentPos.value += 1;
}

function prevDay() {
    days.value = loadedDays.value.slice(currentPos.value - 1, currentPos.value + 4);
    currentPos.value -= 1;
}
// functions

// retourne les créneaux du jour

function getCreneaux(index, jourNom, daydate) {

    let tableauResultat = [];
    let tableauAllCrenneaux = [];
    if (crenauxBooked.value[daydate] !== undefined) {

        switch (jourNom) {

            case 'lun.':
                tableauResultat = creneauxNonFiltre.value.lundi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.lundi;
                break;

            case 'mar.':
                tableauResultat = creneauxNonFiltre.value.mardi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.mardi;
                break;

            case 'mer.':
                tableauResultat = creneauxNonFiltre.value.mercredi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.mercredi;
                break;
            case 'jeu.':
                tableauResultat = creneauxNonFiltre.value.jeudi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.jeudi;
                break;
            case 'ven.':
                tableauResultat = creneauxNonFiltre.value.vendredi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.vendredi;
                break;

            case 'sam.':
                tableauResultat = creneauxNonFiltre.value.samedi.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.samedi;
                break;

            case 'dim.':
                tableauResultat = creneauxNonFiltre.value.dimanche.filter((valeur) => !crenauxBooked.value[daydate].includes(valeur));
                tableauAllCrenneaux = creneauxNonFiltre.value.dimanche;
                break;


        }

    } else {
        switch (jourNom) {

            case 'lun.':
                tableauResultat = creneauxNonFiltre.value.lundi;
                tableauAllCrenneaux = creneauxNonFiltre.value.lundi;
                break;

            case 'mar.':
                tableauResultat = creneauxNonFiltre.value.mardi;
                tableauAllCrenneaux = creneauxNonFiltre.value.mardi;
                break;

            case 'mer.':
                tableauResultat = creneauxNonFiltre.value.mercredi;
                tableauAllCrenneaux = creneauxNonFiltre.value.mercredi;
                break;
            case 'jeu.':
                tableauResultat = creneauxNonFiltre.value.jeudi;
                tableauAllCrenneaux = creneauxNonFiltre.value.jeudi;
                break;
            case 'ven.':
                tableauResultat = creneauxNonFiltre.value.vendredi;
                tableauAllCrenneaux = creneauxNonFiltre.value.vendredi;
                break;

            case 'sam.':
                tableauResultat = creneauxNonFiltre.value.samedi;
                tableauAllCrenneaux = creneauxNonFiltre.value.samedi;
                break;

            case 'dim.':
                tableauResultat = creneauxNonFiltre.value.dimanche;
                tableauAllCrenneaux = creneauxNonFiltre.value.dimanche;
                break;


        }
    }


    return { all: tableauAllCrenneaux, avaiable: tableauResultat }

}

function getDay(index) {
    let jour = new Date();
    jour.setDate(jour.getDate() + index);
    jour = jour.toLocaleString('fr-fr', { weekday: 'short' });
    return jour;
}

function getDate(index) {
    let jour = new Date();
    jour.setDate(jour.getDate() + index);
    jour = jour.toLocaleString('fr-fr', { day: "numeric", month: "short" });
    return jour;
}




//
// 1085407567567-end189gdv5b80h7fm4t4rk7rchslu398.apps.googleusercontent.com



// Fonction pour parser une heure au format HH:MM en minutes
function parseHeure(heure) {
    const [heureStr, minuteStr] = heure.split(":");
    return parseInt(heureStr) * 60 + parseInt(minuteStr);
}

// Fonction pour formater une heure en minutes au format HH:MM
function formatHeure(minutes) {
    const heures = Math.floor(minutes / 60);
    const minutesRestantes = minutes % 60;
    return `${heures.toString().padStart(2, "0")}:${minutesRestantes.toString().padStart(2, "0")}`;
}

// Fonction pour ajouter une durée en minutes à une heure donnée
function ajouterDuree(heure, duree) {
    return heure + duree;
}

</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_sous_titre {
    h2 {
        margin-top: 60px;
        margin-bottom: 20px;
        font-size: 18px;
    }
}

.rk_reservation {
    display: flex;
    width: 100%;

    .rk_ctrl_left,
    .rk_ctrl_right {
        position: relative;

        p {
            width: 25px;
            height: 25px;
            margin: 0;
            background: #6362A7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: white;
            font-weight: 700;
            font-size: 22px;
            position: absolute;
            z-index: 100;
            top: 0;
            left: 5px;
            cursor: pointer;

        }
    }

    .rk_ctrl_right {


        p {

            left: 5px;


        }
    }

    .rk_ctrl_left {
        p {
            right: 5px;
        }
    }

    .rk_content {
        display: flex;
        flex-grow: 1;
        overflow-y: scroll;
        height: 300px;

        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #6362A7;
            border-radius: 20px;
            cursor: pointer;

        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #6362A7;
        }

        .rk_day_content {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;

            .rk_jour {
                font-weight: 700;
                margin-bottom: 5px;



            }

            .rk_date {
                margin-bottom: 15px;
            }

            .booking {
                width: calc(100% - 10px);
                aspect-ratio: 2/1;
                padding: 5px;

                .booking_elem {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;

                    &:not(.not) {
                        cursor: pointer;
                        background-color: $mainColorBlue ;
                        color: $mainColorGreen;
                        transition: all .4s;

                        &:hover {
                            background-color: $textColorBlue;
                        }
                    }
                }

            }
        }
    }
}
</style>
