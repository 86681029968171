import { defineStore } from 'pinia'
import { ref } from 'vue';
import { useLocalStorage } from "@vueuse/core"

export const useProduitStore = defineStore('produit', () => {

    // use local storge permet de conserver en local storage les infos
    const currentProduit = ref(useLocalStorage('currentProduit', ''));
    const showProduct = ref(useLocalStorage('showProduct', false));

    function setCurrentProduit(produit) {
        currentProduit.value = produit;
    }
    function setShowProduct(isShown) {
        showProduct.value = isShown;
    }


    return { currentProduit, setCurrentProduit, showProduct, setShowProduct }
})