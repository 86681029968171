import { defineStore } from 'pinia'
import { ref } from 'vue';


export const useModalStore = defineStore('modals', () => {

    // use local storge permet de conserver en local storage les infos
    const currentModal = ref({ type: 'avis', opened: false });

    function setCurrentModal(type, status) {
        currentModal.value.type = type;
        currentModal.value.opened = status;
    }



    return { currentModal, setCurrentModal }
})