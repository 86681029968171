<template>
  <div class="rk_market_banner">
    <img :src="serverUrl + '/uploads/kikekoi/marketplace.webp'" alt="Image market place kikekoi">
    <img class="rk_market_title" :src="serverUrl + '/uploads/kikekoi/marketplace.svg'" alt="market place">
  </div>
  <h1 class="rk_titre_section">Tous les produits
    <img :src="serverUrl + '/uploads/kikekoi/icon-map.svg'" alt="icone map">
  </h1>
  <div class="rk_market_content">
    <div class="rk_filtres" v-if="produitFiltered.length > 0">
      <p>Je recherche

        <span class="search_text">
          <input type="text" onclick="this.select();" v-model="searchProduit"
            :style="'width:' + (searchProduit.length * 14) + 'px;'">
          <span class="formkit-suffix-icon formkit-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9">
              <path
                d="M7.18,7.62L3.03,2.79c-.6-.7-.1-1.79,.82-1.79H12.14c.93,0,1.42,1.09,.82,1.79l-4.14,4.83c-.43,.5-1.21,.5-1.64,0Z"
                fill="currentColor"></path>
            </svg>
          </span></span>

        avec un budget maximum
        <span class="search_price">
          <select name="prix" v-model="searchPrix">
            <option value="0" selected>Non défini</option>
            <option value="5">de 5€</option>
            <option value="15">de 15€</option>
            <option value="50">de 50€</option>
            <option value="100">de 100€</option>
          </select>
          <span class="formkit-suffix-icon formkit-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9">
              <path
                d="M7.18,7.62L3.03,2.79c-.6-.7-.1-1.79,.82-1.79H12.14c.93,0,1.42,1.09,.82,1.79l-4.14,4.83c-.43,.5-1.21,.5-1.64,0Z"
                fill="currentColor"></path>
            </svg>
          </span>

        </span>
        dans la thématique

        <span class="search_price">
          <select name="categorie" v-model="searchCat">
            <option value="0" selected>toutes</option>
            <option v-for="(categorie, indexCat) in categories" :key="indexCat" :value="categorie._id">{{ categorie.name
            }}</option>
          </select>
          <span class="formkit-suffix-icon formkit-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9">
              <path
                d="M7.18,7.62L3.03,2.79c-.6-.7-.1-1.79,.82-1.79H12.14c.93,0,1.42,1.09,.82,1.79l-4.14,4.83c-.43,.5-1.21,.5-1.64,0Z"
                fill="currentColor"></path>
            </svg>
          </span>
        </span>

      </p>
    </div>
    <LazyList v-if="produitFilteredToShow !== false && produitFiltered.length > 0" class="rk_produits"
      :data="produitFiltered" :itemsPerRender="produitFilteredToShow" containerClasses="rk_produits">
      <template v-slot="{ item }">
        <div @mouseover="storeProduit.setCurrentProduit(item)" class="rk_produit">
          <div class="rk_produit_images">
            <vueper-slides lazy lazy-load-on-drag :arrows="false" :slide-ratio="2 / 3">
              <vueper-slide :link="'/produit/' + item._id" v-for="(imageUrl, indexImg) in item.images" :key="indexImg"
                :image="serverUrl + imageUrl">
                <template #loader>
                  <i class="icon icon-loader spinning"></i>
                  <span>Chargement...</span>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <router-link :to="'/produit/' + item._id" class="rk_produit_titre">
            <h3>{{ item.titre }}</h3>
            <p class="rk_price">{{ item.prix }} €</p>
          </router-link>

        </div>

      </template>
    </LazyList>
    <div class="rk_no_products" v-else>
      <h3>Oh non ! 🥺 <br>
        Nous ne trouvons aucun produit autour de chez vous pour le moment... </h3>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import { useLoginStore } from '../../store/piniaLogin'
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import axios from "axios";
import LazyList from 'lazy-load-list/vue'
import keyword_extractor from 'keyword-extractor';

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { useProduitStore } from '../../store/piniaProducts'

const storeProduit = useProduitStore();

const store = useLoginStore()

const { currentLatlng, searchProduit } = storeToRefs(store)

const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
const produits = ref([]);
const categories = ref([]);

const searchPrix = ref(0);
const searchCat = ref(0);
const filters = ref([]);


const produitFiltered = ref(false);
const produitFilteredToShow = ref(false);

onBeforeMount(() => {
  loadAround();
  loadCat()
})
watch(searchPrix, (value) => {
  filterProducts(searchProduit.value, value, searchCat.value)

})

watch(searchCat, (value) => {
  filterProducts(searchProduit.value, searchPrix.value, value)

})


watch(searchProduit, (value) => {

  filterProducts(value, searchPrix.value, searchCat.value)

})
function loadCat() {



  axios.get(process.env.VUE_APP_SERVER_URL + `/produits/cat`)
    .then(function (response) {
      categories.value = response.data;

    })
    .catch(function (error) {
      // handle error
      console.log(error);

    })

}
function filterProducts(produit, prix, cat) {
  // si il y a aucun filtre sur 3
  if (produit == 'Un produit' && cat == 0 && prix == 0) {
    produitFiltered.value = produits.value;

  }
  // si il y à qu'un filtre sur 3
  if (produit == 'Un produit' && cat == 0 && prix != 0) {
    produitFiltered.value = produits.value.filter(produit => produit.prix <= prix);

  }

  if (prix == 0 && cat == 0 && produit != 'Un produit') {
    filterByWord(produit);
  }

  if (produit == 'Un produit' && prix == 0 && cat != 0) {
    produitFiltered.value = produits.value.filter((obj) =>
      obj.categories.some((categorie) => categorie._id === cat));

  }


  // si il y a deux filtres sur 3

  if (produit != 'Un produit' && cat == 0 && prix != 0) {
    filterByWord(produit);
    produitFiltered.value = produitFiltered.value.filter(produit => produit.prix <= prix);

  }

  if (prix == 0 && cat != 0 && produit != 'Un produit') {
    filterByWord(produit);
    produitFiltered.value = produits.value.filter((obj) =>
      obj.categories.some((categorie) => categorie._id === cat));
  }

  if (produit == 'Un produit' && prix != 0 && cat != 0) {
    produitFiltered.value = produits.value.filter((obj) =>
      obj.categories.some((categorie) => categorie._id === cat));
    produitFiltered.value = produitFiltered.value.filter(produit => produit.prix <= prix);

  }
  // si il y a 3 filtres sur 3

  if (produit != 'Un produit' && prix != 0 && cat != 0) {
    filterByWord(produit);
    produitFiltered.value = produitFiltered.value.filter((obj) =>
      obj.categories.some((categorie) => categorie._id === cat));
    produitFiltered.value = produitFiltered.value.filter(produit => produit.prix <= prix);

  }

  produitFilteredToShow.value = produitFiltered.value.length >= 13 ? 13 : produitFiltered.value.length;
}

function filterByWord(value) {
  const extraction_result =
    keyword_extractor.extract(value, {
      language: "french",
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: true
    });

  if (extraction_result.length > 0 || value.length > 2) {
    filters.value = extraction_result;

    produitFiltered.value = filterDatas(extraction_result, produits.value);
  } else {

    produitFiltered.value = produits.value

  }
  produitFilteredToShow.value = produitFiltered.value.length >= 13 ? 13 : produitFiltered.value.length;
}
function filterDatas(value, datas) {
  const result = [];
  for (let i = 0; i < datas.length; i++) {
    const obj = datas[i];

    const { description, titre } = obj;
    let { motsClefs } = obj;
    motsClefs = motsClefs.toString();
    const found = [description, titre, motsClefs];
    const foundAny = found.some(text => {
      return value.some(query => text.toLowerCase().includes(query));
    });
    if (foundAny) {
      result.push(obj);
    }
  }
  return result;
}
function loadAround() {
  let token = '';

  const params = ref({
    lat: 45.692341,
    long: 5.908998
  });



  if (store.currentToken !== undefined && store.currentToken !== 'false' && store.currentToken !== '') {

    const localUser = JSON.parse(store.currentUser);
    token = store.currentToken;

    params.value.lat = localUser.location.coordinates[1];
    params.value.long = localUser.location.coordinates[0];



  } else if (currentLatlng.value.lat !== '' && currentLatlng.value.lng !== '') {

    params.value = {
      lat: currentLatlng.value.lat,
      long: currentLatlng.value.lng
    };


  }

  axios.get(process.env.VUE_APP_SERVER_URL + `/produits/autour`, {
    withCredentials: true,

    params: params.value,
    headers: {
      'Authorization': `Basic ${token}`
    }
  })
    .then(function (response) {

      response.data.datas.forEach(produit => {

        produits.value.push(produit)


      });
      produitFiltered.value = produits.value;
      produitFilteredToShow.value = produitFiltered.value.length >= 13 ? 13 : produitFiltered.value.length;
    })
    .catch(function (error) {
      // handle error
      console.log(error);

      toast.error(error);
    })
}


</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_market_banner {
  width: 100%;
  aspect-ratio: 4/1;
  position: relative;

  img:not(.rk_market_title) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .rk_market_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.rk_market_content {
  color: $textColorBlue;
  font-size: 22px;
  min-height: 700px;

  .rk_filtres {
    text-align: center;
    margin: 20px 0;

    .search_text,
    .search_price {

      input,
      select {
        font-family: "Poppins", sans-serif;
        color: $textColorBlue;
        border: none;
        font-size: 22px;
        font-weight: 700;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
          outline: none;

        }
      }

      .formkit-suffix-icon {
        color: $textColorBlue;
        width: 15px;
        display: inline-flex;
        align-items: start;
        transform: translateY(-5px);
      }
    }
  }
}

.rk_titre_section {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: $textColorBlue;
  position: relative;
  background-color: white;
  padding: 60px 0 20px;
  transform: translateY(-30px);
  border-radius: 30px;
  margin: 0;

  svg,
  img {
    position: absolute;
    height: 70px;
    left: 50%;

    top: 70%;


    transform: translate(-50%, -50%);
  }
}

.rk_produits {
  display: flex;
  flex-wrap: wrap;
  max-width: 980px;
  margin: auto;

  .rk_produit {
    flex: 1 1 auto;
    margin: 2%;
    min-width: 200px;
    max-width: 20%;

    img {
      width: 100%;
    }

    .rk_produit_images {
      border-radius: 10px;
      overflow: hidden;
    }

    .rk_produit_titre {
      display: flex;
      text-decoration: none;
      justify-content: space-between;

      h3 {
        font-family: "Poppins";
        color: #161615;
        font-size: 15px;
      }

      p {
        font: normal normal bold 15px/20px Poppins;
        letter-spacing: 0px;
        color: #9BCFC3;
      }
    }
  }
}

.rk_no_products {
  text-align: center;
}
</style>
