<template>
    <div class="admin-container">
        <sidebar @change-page="(n) => adminPage = n" :selected="adminPage"></sidebar>

        <div class="dashboard-main">
            <div class="hello">
                <h2>Salut {{ userData.prenom }} !</h2>
                <h3>Bienvenue dans ton espace personnel</h3>
            </div>

            <div v-if="adminPage == 1" class="dashboard-admin">
                <div v-if="userData.typeDeCompte == 'admin'" class="data-cards">
                    <div class="card">
                        <div class="card-icon">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z">
                                </path>
                            </svg>
                        </div>

                        <div class="info">
                            <p>Prestatiares</p>
                            <p>0</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                            <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                            </svg>
                        </div>
                        <div class="info">
                            <p>Utilisateurs</p>
                            <p>0</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
                                </path>
                            </svg>
                        </div>
                        <div class="info">
                            <p>Demandes en cours</p>
                            <p>0</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-icon">
                            <svg class="w-5 h-5" aria-hidden="true" fill="none" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path
                                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
                                </path>
                            </svg>
                        </div>
                        <div class="info">
                            <p>Demandes traités</p>
                            <p>0</p>
                        </div>
                    </div>
                </div>
                <div v-else>

                </div>
            </div>

            <addCat v-if="adminPage == 2"></addCat>
            <addPresta v-if="adminPage == 3"> </addPresta>
            <addService v-if="adminPage == 4"></addService>
            <editAccount v-if="adminPage == 5"></editAccount>
            <mesPresta v-if="adminPage == 6"></mesPresta>
            <mesDevis v-if="adminPage == 7"></mesDevis>
            <addProduits v-if="adminPage == 8"></addProduits>
            <mesCommandes v-if="adminPage == 9"></mesCommandes>
            <addEvent v-if="adminPage == 10"></addEvent>

        </div>
    </div>
</template>
<script setup>
import sidebar from '../components/admin/SidebarView.vue'
import addCat from '../components/admin/addCatView.vue'
import addService from '../components/admin/addServiceView.vue'
import addPresta from '../components/admin/addPrestaView.vue'
import addEvent from '../components/admin/addEventView.vue'
import mesPresta from '../components/admin/mesPrestationsView.vue'
import mesCommandes from '../components/admin/mesCommandesView.vue'
import addProduits from '../components/admin/addProduitsView.vue'

import mesDevis from '../components/admin/mesDevisView.vue'
import editAccount from '../components/admin/editAccountView.vue'
import { useAdminStore } from '../store/piniaAdmin'
import { useLoginStore } from '../store/piniaLogin'

import { onBeforeMount, ref } from 'vue'
import { toast } from 'vue3-toastify';

import axios from "axios";

//store admin
const storelogin = useLoginStore()
const userData = ref(JSON.parse(storelogin.currentUser));

// variable
const adminPage = ref(1);
const allServices = ref([]);
const mainCategories = ref([]);
onBeforeMount(() => {
    const storeAdminDeOuf = useAdminStore();

    // axios.get(process.env.VUE_APP_SERVER_URL + '/categories')
    //     .then(function (response) {
    //         // handle success
    //         categories.value = response.data;
    //         console.log(categories.value);
    //     })
    //     .catch(function (error) {
    //         // handle error
    //         toast.error(error);
    //     })

    axios.get(process.env.VUE_APP_SERVER_URL + '/categories')
        .then(function (response) {
            // handle success
            mainCategories.value = response.data;
            console.log(storeAdminDeOuf);
            storeAdminDeOuf.setCategories(response.data)

        })
        .catch(function (error) {
            // handle error
            console.log(error);
            toast.error(error);
        })

    axios.get(process.env.VUE_APP_SERVER_URL + '/services')
        .then(function (response) {
            // handle success
            storeAdminDeOuf.setServices(response.data)
            allServices.value = response.data;

        })
        .catch(function (error) {
            // handle error
            console.log(error);

            toast.error(error);
        })

})

</script>

<style lang="scss" scoped>
$kikekoiGrey: #f5f5f5;
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.admin-container {
    display: flex;
    background-color: white;
    margin-top: -80px;

    .hello {
        padding: 40px;
        background-color: $kikekoiGrey;
        border-radius: 40px;

        h2 {
            font-family: 'Poppins';
            font-size: 40px;
            color: $mainColorText;
            margin: 0;
        }

        h3 {
            font-family: 'Poppins';
            color: $mainColorText;

        }
    }

    .dashboard-main {
        width: 100%;
        padding: 40px;
        padding-top: 80px;

        .dashboard-admin {
            flex: 1 0 0;



            .data-cards {
                display: flex;
                flex-wrap: wrap;


                .card {
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
                    display: inline-block;
                    width: clamp(150px, calc(25% - 4rem), 400px);
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    border-radius: 0.5rem;
                    background-color: #fff;
                    margin: 1rem;

                    .card-icon {
                        min-width: 24px;
                        background-color: #e1effe;
                        border-radius: 9999px;
                        padding: 10px;
                        text-align: center;

                        svg {
                            width: 1.25rem;
                            color: #3f83f8;
                        }
                    }

                    .info {
                        padding-left: 20px;

                        p:nth-child(1) {
                            font-size: .875rem;
                            font-weight: 500;
                            color: #4c4f52;
                            margin: 0;
                            font-family: 'Poppins';

                        }

                        p:nth-child(2) {
                            font-family: 'Poppins';

                            color: #24262d;
                            font-weight: 600;
                            font-size: 1.125rem;
                            margin: 0;

                        }
                    }
                }
            }
        }
    }

}
</style>

<style lang="scss">
$kikekoiGrey: #f5f5f5;
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.dashboard-main {
    h2 {
        font-family: 'Poppins';
        font-size: 30px;
        color: $mainColorText;
        margin-bottom: 20px;
    }



    .rk_add_cat {
        flex: 1 0 0;
        padding: 20px;


    }

    .mainCats {
        display: flex;
        flex-wrap: wrap;

        .editCats {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 5px;
        }
    }

    .services {
        display: flex;
        flex-wrap: wrap;

        .card {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            border-radius: 0.5rem;
            background-color: #fff;
            margin: 1rem;
            max-width: 25%;
            position: relative;

            &.noEdit {
                margin: 2px;
                padding: 4px;
            }

            .infos,
            .actions {
                display: flex;
            }

            div {
                align-self: end;
            }
        }

        .subCategories {
            margin-left: 20px;
        }
    }




    .close {
        position: absolute;
        top: -10px;
        right: -10px;
        $size: 20px;
        $borderSize: 2px;
        $borderColor: rgba(white, 1);
        $speed: .5s;
        width: $size;
        height: $size;
        background: #455A64;
        border-radius: 50%;
        box-shadow: 0 0 20px -5px rgba(white, .5);
        transition: .25s ease-in-out;
        cursor: pointer;
        animation: fade-in $speed ease-out .25s both;

        @keyframes fade-in {
            from {
                opacity: 0;
                transform: scale(1.1)
            }
        }

        .circle {
            path {
                stroke: $borderColor;
                fill: none;
                stroke-width: $borderSize/2;
                stroke-linecap: round;
                animation: progress $speed ease-out .25s both;

                @keyframes progress {
                    from {
                        stroke-dasharray: 0 100;
                    }
                }
            }
        }

        span {
            display: block;
            width: ($size/2);
            height: $borderSize - 1px;
            background: $borderColor;
            box-shadow: 0 0 20px -5px rgba(white, .5);
            border-radius: 20px;
            position: absolute;
            $padding: $size/3;
            transition: .25s ease-in-out;
            animation: slide-in $speed ease-in-out .25s both;

            @keyframes slide-in {
                from {
                    width: 0;
                }
            }

            &:nth-child(1) {
                top: ($padding - ($borderSize/2) +1px);
                left: $padding;
                transform: rotate(45deg);
                transform-origin: top left;
            }

            &:nth-child(2) {
                top: ($padding - ($borderSize/2) +1px);
                right: $padding;
                transform: rotate(-45deg);
                transform-origin: top right;
            }

        }

        &:hover {
            background: #37474F;


        }
    }

    .quadra {
        display: flex;
        flex-wrap: wrap;

        >* {
            flex: 1 1 130px;
            margin: 0 5px;
        }
    }

    .penta {
        margin-top: 1.25em;

        .horaires {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;

            >* {
                flex: 1 0 100px;
                margin-right: 1%;
            }

        }


    }

    /* The switch - the box around the slider */
    .switch {
        width: 90px !important;
        position: relative;
        display: inline-block;
        height: 100%;
        min-height: 100px;

        p {
            position: absolute;
            top: 0;
            width: 100%;
            margin: 0;
            font-family: var(--fk-font-family-label);
            font-size: var(--fk-font-size-label);
            font-weight: var(--fk-font-weight-label);
            line-height: var(--fk-line-height-label);
        }
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 17px;
        transform: translate(-50%, -50%);
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;


    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;

    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 17px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .form_container {
        border-radius: var(--fk-border-radius);
        border: var(--fk-border);
        padding: 1.25em;
        margin: var(--fk-margin-outer);
        position: relative;

        >label {
            font-family: var(--fk-font-family-label);
            font-size: var(--fk-font-size-label);
            font-weight: var(--fk-font-weight-label);
            line-height: var(--fk-line-height-label);

        }

        h2 {
            position: absolute;
            font-family: var(--fk-font-family-label);

            top: -17px;
            left: .7em;
            background-color: white;
            padding: 0 5px;
            margin-top: 0;
            font-size: 22px;
        }
    }

    .single_service {
        border-radius: var(--fk-border-radius);
        border: var(--fk-border);
        padding: 1.25em;
        margin: var(--fk-margin-outer);
        position: relative;
        display: flex;

        >* {
            margin-right: 10px !important;
        }
    }

    .double {
        z-index: 10;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >* {
            width: 47%;
        }
    }
}
</style>