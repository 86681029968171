<template>
    <div class="rk_header"
        :class="[{ sticky: (store.hasScrolled && router.currentRoute.value.name == 'Accueil') }, { unsticky: !store.hasScrolled && width > 750 }, { mobile_menu: width < 750 }]">
        <div class="shadow"></div>
        <div class="logo"><router-link to="/" @click="goHome()">
                <img :src="serverUrl + '/uploads/kikekoi/logo.svg'" alt="Logo du site kikekoi">
            </router-link></div>

        <ul class="menu" v-if="width !== undefined && width > 750" :class="{ searchBar: store.selectedPart === 1 }"
            ref="menu">
            <li v-for="(data, index) in homeDatas" :key="data.titre">
                <a @click="changePage(index)" href="#">{{ data.titre }}
                    <div v-if="(store.selectedPart == index && activeMaps == true && router.currentRoute.value.name == 'Accueil')"
                        class="active"></div>
                </a>
            </li>
        </ul>
        <ul class="menu_mobile" v-else :class="{ searchBar: store.selectedPart === 1 }" ref="menu">
            <li v-for="(data, index) in homeDatas" :key="data.titre">
                <a @click="changePage(index)" href="#">
                    <span class="mobile_image">
                        <img :src="serverUrl + '/uploads/kikekoi/icones/' + data.titreMobile.toLowerCase() + '.svg'"
                            alt="Icône du menu mobile">
                        <div v-if="(store.selectedPart == index && activeMaps == true && router.currentRoute.value.name == 'Accueil')"
                            class="active"></div>
                    </span>

                    <span>
                        {{ data.titreMobile }}
                    </span>

                </a>
            </li>
            <li class="rk_btn_demande">
                <div v-if="width < 750" class="rk_devis_btn_mobile" ref="devisBtn">
                    <a @click="showdevisModal = true">
                        <SendOutlineIcon></SendOutlineIcon>
                    </a>
                </div>
            </li>
        </ul>

        <div class="profile">
            <div v-if="width > 750" class="rk_devis_btn" ref="devisBtn" @mouseover.once="preloadImages">
                <a @click="showdevisModal = true">
                    <SendOutlineIcon></SendOutlineIcon> Faire une demande
                </a>
            </div>
            <a class="rk_connectBtn" v-if="!store.isLogged && width > 750"
                @click="store.setShowModal(!store.showModal)">Connexion</a>
            <a v-else @click="store.setShowModal(!store.showModal)" class="active_profile"
                :class="{ open: store.showModal }">
                <div v-if="store.isLogged" class="rk_menu">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <i class="icon-user"></i>
            </a>
        </div>
        <Teleport to="body">
            <!-- use the modal component, pass in the prop -->
            <modal :show="store.showModal" @close="store.setShowModal(false)">
                <template #header>
                    <h3>custom header</h3>
                </template>
            </modal>

            <!-- use the modal component, pass in the prop -->
            <devisModal :show="showdevisModal" @close="showdevisModal = false">

            </devisModal>
        </Teleport>
        <div class="rk_panier" :class="{ active: cartOpened, order: ordering, }">
            <h3 v-if="!ordering">Panier</h3>
            <h3 v-else>Validation de la commande</h3>
            <h4 v-if="ordering">Vos informations :</h4>

            <TransitionGroup v-if="ordering" name="order" tag="div" class="rk_order_validation rk_choiceData">
                <FormKit type="form" id="mesInfos" @submit="sendOrder" :actions="false" v-model="orderData"
                    submit-label="Passer là commande">

                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="nom" validation="required" label="Nom"
                            :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="prenom" validation="required" label="Prénom"
                            :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="adresse" validation="required"
                            label="Numéro et nom de rue" :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="codePostale" validation="required"
                            label="Code postal" :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="ville" validation="required" label="Ville"
                            :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="text" class="form-control" name="telephone" validation="required" label="Téléphone"
                            :floating-label="true" />
                    </div>
                    <div class="form-group">
                        <FormKit type="email" class="form-control" name="email" validation="required" label="E-mail"
                            :floating-label="true" />
                    </div>


                </FormKit>
            </TransitionGroup>
            <div class="rk_closeCart" @click="closeCart()"></div>
            <h4 v-if="ordering">Votre commande :</h4>

            <TransitionGroup name="list" tag="ul" class="rk_order">
                <li class="item" v-for="(order, index) in currentOrder" :key="index">
                    <img :src="serverUrl + order.images[0]" alt="">
                    <div>
                        <p> {{ order.titre }} </p>

                        <input type="number" v-model="order.qte">

                        <p>{{ (order.prix) }} €</p>
                    </div>

                </li>
            </TransitionGroup>

            <div v-if="!ordering" class="rk_subtotal">
                <p>Total : {{ getTotal() }} €</p>
                <button class="order_now" @click="ordering = true">Commander</button>
            </div>
            <div v-else class="rk_subtotal order">
                <p>Total : {{ getTotal() }} €</p>
                <FormKit type="checkbox" label="Je m'engage à aller chercher cette commande quand elle sera prête"
                    name="terms" v-model="checkTerms" validation="accepted" validation-visibility="dirty" />
                <button type="submit" form="mesInfos" class="order_now" :class="{ disabled: !checkTerms }"
                    :disabled="!checkTerms">Passer
                    la commande</button>
            </div>


            <div v-if="!ordering && currentOrder.length > 0" class="rk_iconCart"
                @click=" storePanier.setCartOpened(!cartOpened)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart3"
                    viewBox="0 0 16 16">
                    <path
                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useLoginStore } from '../../store/piniaLogin'
import { usePanierStore } from '../../store/piniaPanier'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import SendOutlineIcon from 'vue-material-design-icons/SendOutline.vue';
import Modal from '../modals/registerModal.vue'
import devisModal from '../modals/devisModal.vue'
import { storeToRefs } from 'pinia';
import { useShepherd } from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import { useWindowSize } from '@vueuse/core'

const router = useRouter()
const store = useLoginStore()
const storePanier = usePanierStore()
const { activeMaps, tutoSkiped } = storeToRefs(store)

const { currentOrder, cartOpened } = storeToRefs(storePanier)
const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
const showdevisModal = ref(false);
const ordering = ref(false);
const checkTerms = ref(false);
const orderData = ref();
const { width } = useWindowSize()





const menu = ref(null);
const devisBtn = ref(null);

const homeDatas = ref([
    {
        "titre": "Entraide",
        "titreMobile": "Entraide",
    },
    {
        "titre": "Prestataires",
        "titreMobile": "Prestataires",
    },
    {
        "titre": "Activités",
        "titreMobile": "Activités",

    },
    {
        "titre": "Produits locaux",
        "titreMobile": "Produits",

    }
]);
onMounted(() => {
    // window.addEventListener('scroll', () => {
    //     if (document.querySelector('.rk_header') !== null) {

    //         if (document.documentElement.scrollTop > 50) {
    //             store.setHasScrolled(true);


    //         } else {
    //             store.setHasScrolled(false);


    //         }
    //     }

    // });
    if (!tutoSkiped.value) {
        const tour = useShepherd({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'shadow-md bg-purple-dark',
                scrollTo: true
            }
        });
        tour.addStep({
            attachTo: { element: menu.value.querySelector('li:first-child'), on: 'bottom' },
            text: "Proposez ou demandez de l’aide à côté de chez vous.",
            classes: 'example-step-extra-class',
            buttons: [
                {
                    text: 'Passer',
                    action() {
                        store.setTutoSkiped(true);
                        tour.cancel();
                    },
                },
                {
                    text: "Suivant",
                    action: tour.next
                }
            ]
        });

        tour.addStep({
            attachTo: { element: menu.value.querySelector('li:nth-child(2)'), on: 'bottom' },
            text: 'Trouvez et contactez des professionnels et artisans locaux.',
            classes: 'example-step-extra-class',
            buttons: [
                {
                    text: 'Passer',
                    action() {
                        store.setTutoSkiped(true);
                        tour.cancel();
                    },
                },
                {
                    text: 'Suivant',
                    action: tour.next
                }
            ]
        });

        tour.addStep({
            attachTo: { element: menu.value.querySelector('li:nth-child(3)'), on: 'bottom' },
            text: 'Participez aux événements à proximité de chez vous.',
            classes: 'example-step-extra-class',
            buttons: [
                {
                    text: 'Passer',
                    action() {
                        store.setTutoSkiped(true);
                        tour.cancel();
                    },
                },
                {
                    text: 'Suivant',
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            attachTo: { element: menu.value.querySelector('li:nth-child(4)'), on: 'bottom' },
            text: 'Achetez et consommez local.',
            classes: 'example-step-extra-class',
            buttons: [
                {
                    text: 'Passer',
                    action() {
                        store.setTutoSkiped(true);
                        tour.cancel();
                    },
                },
                {
                    text: 'Suivant',
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            attachTo: { element: devisBtn.value, on: 'bottom' },
            text: "Ici vous pourrez créer des demandes d'aide, de bénévolat ou une demande de devis.",
            classes: 'example-step-extra-class',
            buttons: [
                {
                    text: 'Fin',
                    action() {
                        store.setTutoSkiped(true);
                        tour.next();
                    },
                }
            ]
        });

        tour.start();
    }

});

// on précharge les images pour éviter d'attendre
function preloadImages() {
    const img = new Image();
    const img2 = new Image();
    const img3 = new Image();


    img.src = serverUrl.value + '/uploads/kikekoi/aide_demande.webp';
    img2.src = serverUrl.value + '/uploads/kikekoi/partage_demande.webp';
    img3.src = serverUrl.value + '/uploads/kikekoi/devis_demande.webp';
}

function closeCart() {
    storePanier.setCartOpened(false);
    ordering.value = false;
}

function changePage(index) {

    if (router.currentRoute.value.name == 'Accueil') {
        store.selectedPart = index;
        store.setActiveMaps(true);
    } else {
        store.selectedPart = index;
        store.setActiveMaps(true);
        router.push('/')
    }

}
function goHome() {
    store.setSelectedPart(1);
    setTimeout(() => {
        store.setActiveMaps(false);

    }, 500);
}

function getTotal() {
    let total = 0;
    if (currentOrder.value.length > 0) {
        currentOrder.value.forEach(produit => {
            total += produit.qte * produit.prix;
        });
        return total.toFixed(2);
    } else {
        return total;

    }
}

async function sendOrder(datas) {
    const commande = [];
    currentOrder.value.forEach(order => {

        commande.push({ produit: order._id, titre: order.titre, quantite: order.qte, image: order.images[0], prix: order.prix, prestaId: order.prestaId[0]._id });
    });

    const groupedByPrestaId = {};

    commande.forEach(item => {
        const { prestaId, ...rest } = item;
        if (!groupedByPrestaId[prestaId]) {
            groupedByPrestaId[prestaId] = [];
        }
        groupedByPrestaId[prestaId].push(rest);
    });

    // Convertir l'objet en tableau multidimensionnel
    const commandesParPresta = Object.entries(groupedByPrestaId).map(([prestaId, items]) => {
        return { prestaId, items };
    });

    commandesParPresta.forEach((commande) => {
        let prix = 0;
        commande.items.forEach((produit) => {

            prix += produit.prix * produit.quantite;
        })
        commande.montantTotal = prix.toFixed(2);

    })
    const dataToSend = { infos: datas, commande: commandesParPresta };
    await axios.post(process.env.VUE_APP_SERVER_URL + '/commandes', dataToSend, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
    }).then(function () {
        ordering.value = false;
        storePanier.clearCart();
        toast.success('Commande envoyée !');

    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });

}

</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

@import "../../assets/css/mixin.scss";

.rk_header {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    color: white;
    display: none;
    align-items: center;
    background-color: white;
    overflow: hidden;
    justify-content: space-between;
    height: 80px;


    &.mobile_menu {
        bottom: 0;
        top: inherit;
        overflow: visible;
        display: flex;
        border-top: solid 1px $inputColorhover;

        .logo {
            display: none;
        }


        .profile {
            position: fixed;
            top: 0;
            right: 0;
        }

        .menu_mobile {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            max-width: 100vw;
            padding: 0;

            a {
                text-decoration: none;

            }

            .mobile_image {
                position: relative;

                img {
                    position: relative;
                    z-index: 2;
                }

                .active {
                    width: 30px;
                    height: 30px;
                    background-color: $inputColorhover;
                    position: absolute;
                    display: block;
                    transform: translate(25%, -50%);
                    z-index: 1;
                    border-radius: 50%;
                }
            }

            .rk_btn_demande {
                order: 2;

                .rk_devis_btn_mobile span {
                    height: 50px;
                    width: 50px;
                    transform: translateY(-5px);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $textColorBlue;
                    color: white;
                }
            }

            li {
                color: #161615;
                font-family: "Poppins";
                font-weight: 500 !important;
                list-style: none;
                flex: 1;

                &:nth-child(1),
                &:nth-child(2) {
                    order: 1;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    order: 3;
                }

                a {
                    font-size: 12px;
                }

                .mobile_image {

                    img {
                        height: 20px;
                    }
                }

            }
        }
    }

    &.unsticky {
        &:after {
            animation: headUnGrow .4s ease-in forwards;
        }

        .profile {
            :not(.rk_devis_btn) {
                a {
                    transition-duration: .4S;
                    transition-delay: 0s;
                }
            }

        }
    }

    &.sticky:not(.mobile_menu) {
        .menu {
            &.searchBar {
                li:nth-child(2) {
                    margin-right: 45px;
                }

                li:nth-child(3) {
                    margin-left: 45px;

                }
            }

        }
    }



    .shadow {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
        opacity: 0.4;
        position: absolute;
        top: 0;
    }

    @include mq("tablet") {
        display: flex;
    }

    .logo {
        margin-left: 20px;
        z-index: 1;
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    ul {
        display: none;
        align-items: center;
        justify-content: center;

        @include mq("desktop") {
            display: flex;
        }

        li {
            line-height: 1.4;

            a {
                color: $mainColorText;
                font-size: 17px;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-top: 20px;

                @include mq("desktop") {
                    font-size: 14px;
                    font-weight: 400;
                    width: max-content;
                }

                @include mq("large") {
                    font-size: 17px;
                    font-weight: 300;
                }

                &:hover {
                    opacity: 0.8;
                }

                .active {
                    width: 20px;
                    height: 2px;
                    background-color: $mainColorText;
                    position: absolute;
                    bottom: -12px;
                    opacity: 1;
                }
            }

            &:first {
                margin-left: 0px;
            }
        }
    }

    .profile {
        margin-right: 20px;
        z-index: 1;
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .rk_connectBtn {
            font-family: 'Poppins';
            cursor: pointer;
        }

        :not(.rk_devis_btn) {
            a {
                transition-duration: .4S;
                transition-delay: 1s;
            }

        }

        a {


            padding: 12px;
            border-radius: 30px;
            color: $mainColorText;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin-left: 12px;

            .icon-country {
                font-size: 18px;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.15);
            }
        }

        .active_profile {
            background-color: #fff;
            padding: 3px 6px 3px 9px;
            max-height: 42px;
            height: 70px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            .rk_menu {
                width: 30px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                overflow: hidden;

                div {
                    background-color: $textColorBlue;
                    height: 2px;
                    transition: all .4S;

                    &:nth-child(1) {
                        width: 50%;
                    }

                    &:nth-child(3) {
                        width: 50%;
                        align-self: end;
                    }
                }


            }

            &:hover {
                .rk_menu {
                    div {
                        &:nth-child(1) {
                            width: 100%;
                        }

                        &:nth-child(3) {
                            width: 100%;
                            align-self: end;
                        }
                    }

                }
            }


            &.open {
                .rk_menu {

                    div {

                        &:nth-child(1) {
                            width: 80%;
                            transform: translate(11%, 8px) rotate(45deg);

                        }

                        &:nth-child(2) {
                            transform: translateX(100%);
                        }

                        &:nth-child(3) {
                            width: 80%;

                            transform: translate(-11%, -8px) rotate(-45deg);

                        }
                    }


                }
            }

            .icon-menu {
                color: #222;
                font-size: 14px;
                transform: scale(1.2, 1);
                margin-left: 5px;
            }

            .icon-user {
                margin-left: 5px;
                font-size: 32px;
                color: $textColorBlue;
            }

            &:hover {
                background-color: #fff;
            }
        }
    }

    .rk_devis_btn {
        a {
            background-color: $mainColorBlue;
            border: solid 1px $mainColorBlue;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            color: white !important;
            font-family: 'Poppins';

            span {
                position: absolute;
                right: calc(100% + 27px);
                top: 50%;
                transform: translateY(-50%);
                transition: right .4s;
            }

            &:hover {
                background-color: white !important;
                animation: devisBtn .6s linear forwards;
                color: $mainColorBlue !important;

                span {
                    position: absolute;
                    right: 0;
                    color: $mainColorBlue !important;
                    top: calc(50% + 3px);
                    transform: translateY(-50%);
                }
            }
        }

    }

    @keyframes devisBtn {
        0% {
            color: white;
            padding: 12px;
        }

        25% {
            color: transparent;
            padding: 12px;
        }

        65% {
            color: transparent;
            padding: 12px;
        }

        100% {
            padding: 12px 30px 12px 12px;
            color: $mainColorBlue;
        }
    }
}

.menu {
    display: none;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: 0;
    justify-content: center;
    padding: 0;
    flex: 3;

    @include mq("tablet") {
        display: flex;
    }

    @include mq("desktop") {
        display: none;
    }

    li {
        line-height: 1.4;
        margin: 0px 15px;
        height: 100%;
        text-align: center;
        flex: 1;
        transition: margin .4s;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            color: $mainColorText;
            font-size: 17px;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            margin-top: 0px !important;
            font-family: 'Poppins';
            font-weight: 500 !important;
            text-decoration: none;
            text-align: center;

            &:hover {
                opacity: 0.8;
            }

            .active {
                width: 20px;
                height: 2px;
                background-color: $mainColorText;
                position: absolute;
                bottom: -12px;
                opacity: 1;
            }
        }

        &:first {
            margin-left: 0px;
        }
    }
}


.rk_panier {
    position: fixed;
    top: 80px;
    display: flex;
    flex-direction: column;
    right: 0;
    width: min(300px, 80%);
    height: calc(100vh - 80px);
    background-color: white;
    transition: all .4s;
    transform: translateX(100%);
    box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.1);


    h3 {
        font-family: 'Poppins';
        text-align: center;
        width: 100%;
        font-weight: 400;
        color: $textColorBlue;
    }

    h4 {
        font-family: 'Poppins';
        font-weight: 400;
        color: $textColorBlue;
    }

    .rk_closeCart {
        width: 30px;
        height: 30px;

        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        display: block;
        border-radius: 50%;
        cursor: pointer;

        &:after {
            content: '';
            width: 50%;
            height: 2px;
            background-color: $textColorBlue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:before {
            content: '';
            width: 50%;
            height: 2px;
            background-color: $textColorBlue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &.active {
        transform: translateX(0%);

    }

    .rk_order {
        height: calc(100% - 150px);
        overflow: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        justify-content: start;
        padding: 20px;

        .item {
            list-style: none;
            display: flex;
            border: solid 1px $textColorBlue;
            padding: 10px;
            width: calc(100% - 20px);
            margin-bottom: 10px;
            color: $textColorBlue;

            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center;
                margin-right: 10px;
            }

            div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                p {
                    margin-top: 0;
                    font-family: 'Poppins';

                    &:nth-child(1) {
                        width: 100%;
                    }

                    &:nth-child(3) {
                        flex: 1;
                        font-size: 18px;
                        margin: 0;
                        text-align: right;
                    }
                }

                input {
                    width: 20%;

                }
            }


        }
    }

    .rk_iconCart {
        position: absolute;
        bottom: 25px;
        right: 325px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $inputColorhover;

    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .order-enter-active,
    .order-leave-active {
        transition: all 0.5s ease;
    }

    .order-enter-from,
    .order-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .rk_subtotal {
        text-align: center;
        padding-bottom: 20px;

        p {
            font-family: Poppins;
            text-align: center;
            font-size: 22px;
        }

        .order_now {
            color: white;
            text-decoration: none;
            padding: 15px 20px;
            border: solid 1px $inputColorhover !important;
            background: $inputColorhover 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            font-family: Poppins;
            border: none;
            border-radius: 30px;
            opacity: 1;
            transition: all .4s;
            cursor: pointer;
            height: 53px;

            &:hover {
                background: white;
                color: $inputColorhover;
            }

            &.disabled {
                cursor: auto;
                background-color: rgba(239, 239, 239, 0.3) !important;
                color: rgba(16, 16, 16, 0.3) !important;
                border-color: rgba(195, 195, 195, 0.3) !important;
            }
        }
    }

    &.order {
        width: 100%;
        overflow: scroll;
        overflow-x: auto;


        &::-webkit-scrollbar {
            width: 10px;
            border-right: solid 5px white;

        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #EEEEF4;

        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(202, 202, 202);
            border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgb(151, 151, 151);
        }

        .rk_order {

            padding-top: 0;
            height: auto;
            overflow: initial;
            width: 980px;
            margin: auto;

            .item {
                >div {
                    width: 100%;
                }
            }
        }

        h4 {
            margin: 20px auto;
            max-width: 980px;
            font-weight: 500;
            width: 100%;
        }
    }

    .rk_order_validation {
        form {
            display: flex;
            max-width: 980px;
            flex-wrap: wrap;
            margin: auto;

            .form-group {
                flex: 1 0 50%;

                &:nth-child(odd) {
                    .formkit-outer {
                        * {
                            margin-left: auto;
                        }
                    }
                }

            }
        }
    }
}
</style>


<style lang="scss">
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;


.rk_order_validation {
    form {
        .form-group {
            &:nth-child(even) {
                .formkit-outer {
                    * {
                        margin-left: auto;
                    }
                }
            }

        }
    }
}

.rk_subtotal {
    &.order {
        max-width: 980px;
        margin: auto;
        width: 100%;
        color: $textColorBlue;
        text-align: right !important;

        p {
            display: inline-block;
            border: solid 1px #012577;
            padding: 10px;
            margin-left: auto;
            margin-top: 0;
        }

        .formkit-outer {
            max-width: 980px;
            margin: auto;

            .formkit-wrapper,
            span {
                max-width: none;
            }
        }
    }
}

.shepherd-text {
    font-family: 'Poppins';
    text-align: center;
}

.shepherd-enabled.shepherd-target {
    background-color: $inputColorhover;
}

.shepherd-button {
    background-color: #6362A7;
    font-family: 'Poppins';

}
</style>