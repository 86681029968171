<template>
    <div v-if="entraideListFiltered.length > 0" class="entraide_container">
        <div class="rk_entraide_prese">
            <p>
                KikeKoi vous propose de vous investir, localement, aux côtés
                de ceux qui en ont besoin. Mais comme donner c’est aussi recevoir, vos actions seront
                récompensées et vous pourrez bénéficier de points transformant votre aide en
                cagnotte à utiliser sur la market place !
            </p>

        </div>
        <div class="rk_entraide_filters">
            <img :src="serverUrl + '/uploads/kikekoi/icones/filtre.svg'" alt="icone map">
            <ul>
                <li v-for="(type, index) in typesDemandesUniques" :key="index"
                    :class="{ hidden: type === 'recommandation_presta' }">
                    <span @click="filterHelp(type)"
                        :class="[{ reco: type == 'recommandation' }, { partage: type == 'partage' }, { benev: type == 'bénévoles' }, { aide: type == 'aide' }]">{{
                            type }}</span>
                </li>

            </ul>
        </div>

        <div class="rk_entraide_history">

            <LazyList v-if='entraideListFiltered.length > 0' :data="entraideListFiltered" :itemsPerRender="13"
                containerClasses="list entraide" defaultLoadingColor="#222">
                <template v-slot="{ item }">
                    <div class="entraide_content" @click="currentElem = elemIndex(entraideListFiltered, item)"
                        :class="{ active: currentElem == elemIndex(entraideListFiltered, item) }">
                        <p class="rk_prenom">{{ item.prenom }} </p>

                        <p class="rk_ville"> {{
                            item.ville }}</p>

                        <div
                            :class="[{ reco: (item.typeDemandes == 'recommandation' || item.typeDemandes == 'recommandation_presta') }, { partage: item.typeDemandes == 'partage' }, { benev: item.typeDemandes == 'bénévoles' }, { aide: item.typeDemandes == 'aide' }]">
                            {{
                                item.typeDemandes == 'recommandation_presta' ? 'recommandation' : item.typeDemandes }}
                        </div>
                    </div>

                </template>

            </LazyList>
        </div>

        <div class="rk_entraide_card">

            <div class="card_content" v-if="width > 750" @pointerdown="onPointerDown"
                :class="[{ reco: (entraideListFiltered[currentElem].typeDemandes == 'recommandation' || entraideListFiltered[currentElem].typeDemandes == 'recommandation_presta') }, { partage: entraideListFiltered[currentElem].typeDemandes == 'partage' }, { benev: entraideListFiltered[currentElem].typeDemandes == 'bénévoles' }, { aide: entraideListFiltered[currentElem].typeDemandes == 'aide' }]">
                <p class="rk_prenom">{{ entraideListFiltered[currentElem].prenom }}</p>

                <p class="rk_ville"> <img :src="serverUrl + '/uploads/kikekoi/icon-map.svg'" alt="icone map"> {{
                    entraideListFiltered[currentElem].ville }}</p>
                <p class="rk_presentation_card_title"> Description
                </p>
                <p class="rk_presentation_card">{{ entraideListFiltered[currentElem].description }}
                </p>
                <div class="cant_help" @click="currentElem++"><span>Je ne peux pas aider</span></div>
            </div>
            <div class="card_content" v-else @pointerdown="onPointerDown" v-for="(card, index) in entraideListFiltered"
                :key="index"
                :class="[{ reco: (card.typeDemandes == 'recommandation' || card.typeDemandes == 'recommandation_presta') }, { partage: card.typeDemandes == 'partage' }, { benev: card.typeDemandes == 'bénévoles' }, { aide: card.typeDemandes == 'aide' }]">
                <p class="rk_prenom">{{ card.prenom }}</p>

                <p class="rk_ville"> <img :src="serverUrl + '/uploads/kikekoi/icon-map.svg'" alt="icone map"> {{
                    card.ville }}</p>
                <p class="rk_presentation_card_title"> Description
                </p>
                <p class="rk_presentation_card">{{ card.description }}
                </p>
                <div class="answer_card">
                    <div class="cant_help" @click="nextcard(true)"><span>Ne pas aider</span></div>
                    <div class="can_help" @click="activeAnswer = true"><span>Aider</span></div>

                </div>
            </div>
            <img :src="serverUrl + '/uploads/kikekoi/fleche.svg'" alt="icone map">

        </div>
        <div class="rk_entraide_answer" :class="{ active: activeAnswer }">
            <div class="backCard" v-if="width < 750" @click="backToCard">
                <img :src="serverUrl + '/uploads/kikekoi/icones/back.svg'" alt="icone back">
            </div>
            <FormKit
                v-if="entraideListFiltered[currentElem].typeDemandes == 'bénévoles' || entraideListFiltered[currentElem].typeDemandes == 'aide'"
                type="form" @submit="sendNewReco" submit-label="Je souhaite aider cette personne">
                <h2>Pour aider cette personne :</h2>
                <FormKit name="reco_id" type="hidden" :value="entraideListFiltered[currentElem]._id" />
                <FormKit :floating-label="true" type="email" label="Votre email" name="email" validation="required|email"
                    validation-visibility="live" />
                <FormKit :floating-label="true" type="text" name="prenom" validation="required" label="Prénom" />
                <FormKit :floating-label="true" type="tel" label="Téléphone" name="telephone" placeholder="0606060606"
                    validation="matches:/^[0-9]{10}$/" :validation-messages="{
                        matches: 'Le numéro de téléphone est invalide',
                    }" validation-visibility="dirty" />
            </FormKit>
            <FormKit v-if="entraideListFiltered[currentElem].typeDemandes == 'partage'" type="form" @submit="sendNewReco"
                submit-label="Partager mes connaissances">
                <h2>Connaissances à partager :</h2>

                <FormKit :floating-label="true" name="reco_id" type="hidden"
                    :value="entraideListFiltered[currentElem]._id" />
                <FormKit :floating-label="true" type="email" name="email" label="Votre email" validation="required|email"
                    validation-visibility="live" />
                <FormKit :floating-label="true" type="text" name="prenom" validation="required" label="Prénom" />
                <FormKit :floating-label="true" type="tel" label="Téléphone" name="telephone" placeholder="0606060606"
                    validation="matches:/^[0-9]{10}$/" :validation-messages="{
                        matches: 'Le numéro de téléphone est invalide',
                    }" validation-visibility="dirty" />
                <FormKit :floating-label="true" type="taglist" name="ses_comp" label="Vous souhaitez apprendre :"
                    :options="entraideListFiltered[currentElem].competences" />

                <FormKit :floating-label="true" type="taglist" name="vos_comp" label="Vous souhaitez partager :"
                    :options="entraideListFiltered[currentElem].competencesRecherchees" />

            </FormKit>
            <FormKit v-if="entraideListFiltered[currentElem].typeDemandes == 'recommandation'" type="form"
                @submit="sendNewReco" submit-label="Envoyer ma recommandation">
                <h2>Votre recommandation :</h2>
                <FormKit :floating-label="true" name="reco_id" type="hidden"
                    :value="entraideListFiltered[currentElem]._id" />
                <FormKit :floating-label="true" type="email" name="email" label="Votre email" validation="required|email"
                    validation-visibility="live" />
                <FormKit :floating-label="true" type="text" name="prenom" validation="required" label="Prénom" />

                <FormKit :floating-label="true" type="textarea" name="recomandation" auto-height label="Votre recomandation"
                    :value="value" />
            </FormKit>

            <FormKit v-if="entraideListFiltered[currentElem].typeDemandes == 'recommandation_presta'" type="form"
                @submit="sendNewReco" submit-label="Recommander ce prestataire">
                <h2>Votre recommandation :</h2>

                <FormKit :floating-label="true" name="reco_id" type="hidden"
                    :value="entraideListFiltered[currentElem]._id" />
                <FormKit :floating-label="true" type="email" name="email" label="Votre email" validation="required|email"
                    validation-visibility="live" />
                <FormKit :floating-label="true" type="text" name="prenom" validation="required" label="Prénom" />

                <FormKit :floating-label="true" type="autocomplete" name="prestaReco" label="Prestataires aux alentours"
                    :options="entraideStoreView.entraidePresta" selection-appearance="text-input" />
            </FormKit>
        </div>


    </div>
    <section v-else class="loader_container">
        <div class="loader loader-4"></div>
    </section>
</template>
<script setup>
// variable
import { onBeforeMount, ref } from 'vue'
import { useEntraideStore } from '../../store/piniaEntraide'
import { toast } from 'vue3-toastify';
import LazyList from 'lazy-load-list/vue'
import axios from "axios";
import { useWindowSize } from '@vueuse/core'

const serverUrl = ref(process.env.VUE_APP_SERVER_URL);

const currentElem = ref(0);
const typesDemandes = ref([]);
const entraideListFiltered = ref([]);
const typesDemandesUniques = ref([]);
const startX = ref();
const startY = ref();
const moveX = ref();
const moveY = ref();
const activeAnswer = ref(false);
const current = ref();
const { width } = useWindowSize()
const entraideStoreView = useEntraideStore()




onBeforeMount(() => {
    const loadingInter = setInterval(async () => {
        if (typeof entraideStoreView.entraideList == 'object' && entraideStoreView.entraideList.length > 0) {
            typesDemandes.value = await entraideStoreView.entraideList.map((demande) => demande.typeDemandes);
            typesDemandesUniques.value = [...new Set(typesDemandes.value)];
            entraideListFiltered.value = entraideStoreView.entraideList;
            clearInterval(loadingInter)
        }
    }, 100);

});
// functions
function elemIndex(arr, item) {
    return arr.findIndex(object => {
        return object._id === item._id;
    });
}

function filterHelp(type) {
    if (type == "recommandation") {
        entraideListFiltered.value = entraideStoreView.entraideList.filter(object => (object.typeDemandes === type || object.typeDemandes === 'recommandation_presta'));

    } else {
        entraideListFiltered.value = entraideStoreView.entraideList.filter(object => object.typeDemandes === type);
    }
    currentElem.value = 0;

}

async function sendNewReco(datas) {

    const dataToSend = datas;


    await axios.post(process.env.VUE_APP_SERVER_URL + '/entraide/reco', dataToSend, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
    }).then(function () {
        if (currentElem.value + 1 < entraideListFiltered.value.length) {
            currentElem.value++;
        }
        toast.success('Recommandation envoyée ! Pensez à valider votre email pour qu\'elle soit mise en ligne!');


    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });
}


//swipe 

// function initCard(card) {
//   card.addEventListener('pointerdown', onPointerDown)
// }

function setTransform(x, y, deg, duration) {
    current.value.style.transform = `translate3d(${x}px, ${y}px, 0) rotate(${deg}deg)`
    // likeText.style.opacity = Math.abs((x / innerWidth * 2.1))
    // likeText.className = `is-like ${x > 0 ? 'like' : 'nope'}`
    if (duration) current.value.style.transition = `transform ${duration}ms`
}

function onPointerDown({ clientX, clientY, target }) {
    if (target.classList.contains('card_content')) {
        current.value = target;

    } else {
        current.value = target.closest('.card_content');

    }
    startX.value = clientX
    startY.value = clientY
    current.value.addEventListener('pointermove', onPointerMove)
    current.value.addEventListener('pointerup', onPointerUp)
    current.value.addEventListener('pointerleave', onPointerUp)
}

function onPointerMove({ clientX, clientY }) {
    moveX.value = clientX;
    moveY.value = clientY;
    setTransform((clientX - startX.value), clientY - startY.value, (clientX - startX.value) / innerWidth * 50)
}

function onPointerUp() {
    current.value.removeEventListener('pointermove', onPointerMove)
    current.value.removeEventListener('pointerup', onPointerUp)
    current.value.removeEventListener('pointerleave', onPointerUp)
    if (Math.abs(moveX.value) < startX.value - 150) {
        complete('l')
    } else if (Math.abs(moveX.value) > startX.value + 150) {
        complete('r')
    } else {
        cancel()
    }
    // if (Math.abs(moveX.value) > document.body.querySelector('.rk_entraide_card').clientWidth / 2) {

    //     complete()
    // } else cancel()
}

function complete(pos) {
    let flyX = 0;
    let flyY = 0;
    if (pos == 'l') {
        flyX = (Math.abs(moveX.value) / moveX.value) * innerWidth * -1.3
        flyY = (moveY.value / moveX.value) * -flyX
        nextcard()
    } else {
        flyX = (Math.abs(moveX.value) / moveX.value) * innerWidth * 1.3
        flyY = (moveY.value / moveX.value) * flyX
        activeAnswer.value = true;

    }
    setTransform(flyX, flyY, flyX / innerWidth * 50, innerWidth)

}
function nextcard(isMobile = false) {
    if (entraideListFiltered.value.length > currentElem.value + 1) {
        currentElem.value++

    }
    if (isMobile) {
        let flyX = 0;
        let flyY = 0;
        flyX = (Math.abs(moveX.value) / moveX.value) * 300 * -2.3
        flyY = (moveY.value / moveX.value) * -flyX
        setTransform(flyX, flyY, flyX / 300 * 50, 500)
        setTimeout(() => current.value.remove(), 500)

    } else {
        setTimeout(() => current.value.remove(), 300)

    }

}
function backToCard() {

    cancel();
    activeAnswer.value = false;
}
function cancel() {
    setTransform(0, 0, 0, 100)
    setTimeout(() => current.value.style.transition = '', 100)
}
</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

@import "../../assets/css/mixin.scss";

.entraide_container {
    display: flex;
    min-height: 90vh;
    flex-wrap: wrap;

    @include mq("mobile") {
        flex-direction: column;
        max-width: 100vw;
        overflow: hidden;
    }

    @include mq("tablet") {
        flex-direction: row;

    }

    .rk_entraide_history {
        background-color: #EBEBF2;
        flex: 1.5;
        max-height: calc(100vh - 80px);
        direction: rtl;
        border-left: solid 20px #EBEBF2;
        border-bottom: solid 20px #EBEBF2;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: relative;

        @include mq("mobile") {
            display: none;
        }

        @include mq("tablet") {
            display: block;
        }

        ::-webkit-scrollbar {
            width: 10px;
            padding: 2px;

        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #F5F5F5;
            border-radius: 10px;
            margin-top: 30px;
            margin-bottom: 30px;

        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #B0AFE3;
            border-radius: 10px;
            border: solid 2px #F5F5F5;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        &:after {
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0px;
            left: 0px;
            border-bottom-right-radius: 40px;
            border: solid 20px #EBEBF2;
            border-left: none;
            border-top: none;
            transform: translate(-40px, calc(-100% + 20px)) rotate(90deg);

            z-index: 0;
        }

        &:before {
            z-index: 0;

            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0px;
            left: -20px;
            border-bottom-right-radius: 40px;
            border: solid 20px #EBEBF2;
            border-left: none;
            border-top: none;
            transform: translate(-20px, 100%) rotate(180deg);
        }

        #container {
            position: relative;
            padding-top: 20px;
            z-index: 1;


            .entraide_content {
                cursor: pointer;
                direction: ltr;
                background-color: rgba($color: #fff, $alpha: .62);
                padding: 15px;
                margin: 10px 30px 10px 20px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                border-color: white;
                transition: all .4s;
                text-align: center;

                &.active {
                    background: white;
                    border-left: solid 10px white;
                    margin-right: 0;
                    margin-left: 5px;
                    border-right: solid 40px white;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                    position: relative;

                    &:after {
                        content: "";
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        border-bottom-right-radius: 40px;
                        border: solid 20px white;
                        border-left: none;
                        border-top: none;
                        transform: translate(60px, calc(-100% + 20px)) rotate(0deg);
                    }

                    &:before {
                        content: "";
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        border-bottom-right-radius: 40px;
                        border: solid 20px white;
                        border-left: none;
                        border-top: none;
                        transform: translate(60px, calc(100% - 20px)) rotate(270deg);
                    }
                }

                .rk_prenom {
                    font-size: 20px;
                    font-weight: 700;
                    font-family: "Poppins", sans-serif;
                    color: #161615;
                    margin-top: 0;
                    margin-bottom: 5px;
                    text-align: left;
                }

                .rk_ville {
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    font-family: "Poppins", sans-serif;
                    color: #161615;
                    font-weight: 300;

                }

                div {
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 30px;
                    padding: 10px 15px;
                    display: inline-block;
                    font-weight: 700;
                    color: $textColorBlue;
                    cursor: pointer;
                    transition: all .4s;
                    text-transform: capitalize;
                    margin: auto;
                    font-size: 10px;

                    &.reco {
                        background-color: #FCDAD1;

                    }

                    &.partage {
                        background-color: #EEE1E8;

                    }

                    &.benev {
                        background-color: #CEE9D7;

                    }

                    &.aide {
                        background-color: #DDE8F4;

                    }

                }
            }
        }
    }

    .rk_entraide_prese {
        max-width: 80%;
        width: 600px;
        margin: auto;

        p {
            text-align: center;
        }

    }

    .rk_entraide_filters {
        direction: ltr;
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        padding-left: 20px;
        height: 70px;

        img {
            height: 20px;

        }

        ul {
            display: inline-flex;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 10px;
                font-size: 12px;
                margin-right: 10px;

                span {
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 30px;
                    padding: 15px 20px;
                    display: inline-block;
                    font-weight: 700;
                    color: $textColorBlue;
                    cursor: pointer;
                    transition: all .4s;
                    text-transform: capitalize;

                    &:hover {
                        transform: scale(1.1);
                    }

                    &.reco {
                        background-color: #FCDAD1;

                    }

                    &.partage {
                        background-color: #EEE1E8;

                    }

                    &.benev {
                        background-color: #CEE9D7;

                    }

                    &.aide {
                        background-color: #DDE8F4;

                    }

                }

            }
        }
    }

    .rk_entraide_card {
        flex: 3;
        justify-content: center;
        display: flex;
        align-items: center;
        position: relative;



        @include mq("mobile") {
            overflow: hidden;

        }

        @include mq("tablet") {
            overflow: inherit;
        }

        >img {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(100%);

            @include mq("mobile") {
                display: none;

            }

            @include mq("tablet") {
                display: block;
            }
        }

        .card_content {
            position: absolute;
            height: auto;
            padding: 30px;
            border-radius: 30px;
            text-align: center;
            max-width: 280px;

            .answer_card {
                display: flex;

                .can_help {
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 30px;
                    border-radius: 30px;
                    padding: 15px 20px;
                    font-weight: 700;
                    color: white;
                    position: relative;
                    cursor: pointer;
                    overflow: hidden;
                    display: inline-block;
                    margin: auto;
                    background-color: $inputColorhover;
                }
            }

            @include mq("mobile") {
                top: 20px;

            }

            @include mq("tablet") {
                top: inherit;
            }

            &:nth-child(1) {
                z-index: 3;
            }

            &:nth-child(2) {
                z-index: 2;
                transform: translateY(4px);
            }

            &:nth-child(3) {
                z-index: 1;
                transform: translateY(8px);

            }

            .rk_prenom {
                text-align: center;
                font: normal normal bold 23px/26px Poppins;
                letter-spacing: 0px;
                color: #012577;
                margin-bottom: 5px;
            }

            .rk_ville {
                margin-top: 5px;
                text-align: center;
                font: italic normal medium 12px/26px Poppins;
                letter-spacing: 0px;
                color: #012577;

                img {
                    width: 15px;
                }
            }

            .rk_presentation_card {
                text-align: center;
                font: normal normal normal 14px/20px Poppins;
                letter-spacing: 0px;
                color: #012577;
                margin-top: 10px;
                margin-bottom: 60px;
            }

            .rk_presentation_card_title {
                text-align: center;
                font: normal normal bold 18px Poppins;
                color: #012577;
                margin-bottom: 0;
            }

            .cant_help {
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 30px;
                border-radius: 30px;
                padding: 15px 20px;
                font-weight: 700;
                color: white;
                position: relative;
                cursor: pointer;
                overflow: hidden;
                display: inline-block;
                margin: auto;

                span {
                    position: relative;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    mix-blend-mode: multiply;
                    z-index: 0;
                }
            }

            &.reco,
            &.reco .cant_help:before {
                background: #FCDAD1 0% 0% no-repeat padding-box;
            }

            &.partage,
            &.partage .cant_help:before {
                background: #EEE1E8 0% 0% no-repeat padding-box;
            }

            &.benev,
            &.benev .cant_help:before {
                background: #CEE9D7 0% 0% no-repeat padding-box;
            }

            &.aide,
            &.aide .cant_help:before {
                background: #DDE8F4 0% 0% no-repeat padding-box;
            }
        }

    }


}

.loader_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50vh;
    vertical-align: top;

    /*LOADER-4*/
    .loader {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 75px;
        display: inline-block;
        vertical-align: middle;
    }

    .loader-4 {
        border: 7px double $mainColorBlue;
        -webkit-animation: ball-turn 1s linear infinite;
        animation: ball-turn 1s linear infinite;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: $mainColorBlue;
            border-radius: 50%;
            bottom: 0;
            right: 37px;
            left: 37px;
            top: 0;
        }
    }


}



@-webkit-keyframes ball-turn {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ball-turn {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>

<style lang="scss">
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

@import "../../assets/css/mixin.scss";

.rk_entraide_answer {
    background-color: white;
    flex: 2;
    display: flex;
    align-items: center;
    transition: transform .4s;
    position: relative;

    .backCard {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;

        img {
            height: 30px;
        }
    }

    &.active {
        transform: translateX(0%);
        z-index: 10;
        height: calc(100% - 80px);
    }

    @include mq("mobile") {
        position: fixed;
        left: 0;
        bottom: 80px;
        width: 100%;
        justify-content: center;
        transform: translateX(-100%);

    }

    @include mq("tablet") {
        position: relative;
        left: inherit;
        bottom: inherit;
        width: auto;
        justify-content: start;
        transform: inherit;

    }

    form {
        width: min(3500px, 70%);


    }

    .formkit-inner {
        box-shadow: none;

        input,
        textarea {
            font-family: "Poppins", sans-serif;
            background: #EEEEF4 0% 0% no-repeat padding-box;
            box-shadow: 0px 12px 16px #455B6314;
            border-radius: 12px;
            border: none;
        }

        label {
            font-family: "Poppins", sans-serif;
            color: #78849E;
            font-weight: 700;
        }


    }

    button {
        background: #6362A7 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px !important;
        margin: auto;
        font-weight: 700;
    }

    .formkit-listbox-button {
        margin: 0;
        border-radius: 5px !important;
        color: white;
        font-size: 20px;
    }

}
</style>
