<template>
    <div class="rk_contactForm" :class="{ active: currentModal.opened }" @click.self="openModal">
        <div class="rk_closeContact" @click="modalStore.setCurrentModal('avis', false)"></div>
        <div class="rk_text" @click.self="modalStore.setCurrentModal('avis', true)">
            Votre avis
        </div>
        <div v-if="currentModal.opened && currentModal.type == 'avis'" class="rk_feedback_form">
            <h2>Votre avis est important pour nous!</h2>
            <p>kikeKoi est encore en développement, le moindre retour de votre part serait extrêmement précieux pour nous.
                Si vous avez des suggestions, des idées d'amélioration, ou si quelque chose ne fonctionne pas comme prévu,
                n'hésitez pas à nous en faire part. Votre expérience et votre avis sont essentiels pour nous aider à
                perfectionner notre plateforme. Merci d'avance pour votre collaboration !</p>
            <QuillEditor v-model:content="contentHtml" content-type="html" theme="snow"
                :options="{ placeholder: 'Votre avis ...' }" />
            <button class="rk_send_feedback" v-if="buttonEnbaled" @click="sendFeedBack">
                Donner mon avis
            </button>
        </div>
        <div v-if="currentModal.opened && currentModal.type == 'presta'" class="rk_feedback_form">
            <h2>Devenir prestataire !</h2>
            <p>En remplissant ce formulaire, nous créerons un compte et une fiche sur kikeKoi en liant à l'adresse e-mail
                que vous nous fournirez.</p>

            <div class="rk_prestaform">

                <FormKit type="group" v-model="contentpresta">
                    <FormKit type="text" label="Nom de votre entreprise" name="nom" />
                    <FormKit type="url" name="url" label="Fiche google" placeholder="https://www.example.com..."
                        validation="url"
                        help="Votre fiche google est quasi suffisante pour nous aider à créer votre page sur kikeKoi" />

                    <FormKit type="textarea" rows="5" label="Liste des services que vous proposez (séparés par un point) :"
                        auto-height name="services"
                        placeholder="Création de sites internet. Entretient espace vert. Etc..." />

                    <FormKit type="textarea" rows="5" label="Description de l'entreprise" auto-height name="description"
                        help="Sauf si renseignée dans la fiche google" />
                    <FormKit type="email" label="Adresse mail de l'entreprise"
                        help="Sauf si renseignée dans la fiche google" validation="email" validation-visibility="live"
                        name="mail" />
                    <FormKit type="tel" label="Téléphone de l'entreprise " placeholder="06XXXXXXXX"
                        validation="matches:/^[0-9]{10}$/" :validation-messages="{
                            matches: 'Phone number must be in the format xxx-xxx-xxxx',
                        }" validation-visibility="dirty" name="numero"
                        help="Sauf si renseignée dans la fiche google" />

                    <FormKit type="textarea" rows="5" name="horaires" label="Horaires d'ouverture de l'entreprise"
                        auto-height help="Sauf si renseignée dans la fiche google" />

                    <FormKit type="checkbox"
                        label="J'autorise kikeKoi à utiliser des images provenant de nos réseaux sociaux d'entreprise"
                        name="autorisation" />


                </FormKit>
            </div>

            <button class="rk_send_feedback" v-if="buttonEnbaled" @click="sendFichePresta">
                Envoyer ma fiche
            </button>
        </div>
    </div>
</template>
<script setup>
// variable
import { ref } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from "axios";
import { toast } from 'vue3-toastify';
import { useModalStore } from '../../store/piniaModals'
import { storeToRefs } from 'pinia'

const modalStore = useModalStore();
const { currentModal } = storeToRefs(modalStore)

// import axios from "axios";
const buttonEnbaled = ref(true);

const contentHtml = ref();
const contentpresta = ref();

function sendFeedBack() {
    buttonEnbaled.value = false;

    const nouvelAvis = {
        type: 'avis',
        contenu: contentHtml.value,
    };

    axios.post(process.env.VUE_APP_SERVER_URL + '/contact', nouvelAvis)
        .then(() => {

            toast.success('Nouvel avons bien reçu votre retour. MERCI !');
            modalStore.setCurrentModal('avis', false)
        })
        .catch(error => {
            buttonEnbaled.value = true;

            console.error('Erreur lors de la création de l\'avis :', error.message);
        });
}

function sendFichePresta() {
    buttonEnbaled.value = false;

    const nouvelAvis = {
        type: 'entreprise',
        contenu: contentpresta,
    };

    axios.post(process.env.VUE_APP_SERVER_URL + '/contact', nouvelAvis)
        .then(() => {

            toast.success('Nouvel avons bien reçu votre fiche. MERCI !');
            modalStore.setCurrentModal('entreprise', false)
        })
        .catch(error => {
            buttonEnbaled.value = true;

            console.error('Erreur lors de la création de l\'avis :', error.message);
        });
}
function openModal() {
    if (!currentModal.value.opened) {
        modalStore.setCurrentModal('avis', true)

    }
}
</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_contactForm {
    position: fixed;
    padding: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: $inputColor;
    z-index: 1000;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s;


    .rk_text {

        text-align: center;

        transition: all .4s;
    }

    &.active {
        align-items: inherit;
        padding: 30px;
        cursor: inherit;
        height: 100vh;
        max-height: 100vh;
        overflow: scroll;
        width: 100vw;
        border-radius: 0%;
        top: 0px;
        right: 0px;
        transform: translateY(-0%);

        .rk_text {
            font-size: 0;
            width: 0;
            height: 0;
        }

        .rk_closeContact {
            width: 50px;
            height: 50px;

            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;
            display: block;
            border-radius: 50%;
            cursor: pointer;

            &:after {
                content: '';
                width: 50%;
                height: 2px;
                background-color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:before {
                content: '';
                width: 50%;
                height: 2px;
                background-color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }


    }

    .rk_feedback_form {
        >p {
            max-width: 980px;
            text-align: center;
            opacity: 0;
        }


        h2 {
            text-align: center;
            opacity: 0;

        }

        .rk_send_feedback {
            opacity: 0;
        }
    }

    .rk_send_feedback {
        background-color: $inputColorhover;
        border: solid 1px $inputColorhover;
        color: $inputColor;
        font-family: "Poppins";
        padding: 12px;
        border-radius: 30px;

        &:hover {
            background-color: $inputColor ;
            color: $inputColorhover;
        }
    }
}

@media screen and (max-width: 980px) {
    .rk_contactForm {
        bottom: 90px;
    }
}
</style>

<style lang="scss">
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_feedback_form {
    .ql-snow {
        opacity: 0;

        background-color: white;
    }

    .ql-container {
        color: $mainColorText ;
        height: auto;
    }

    .ql-editor {
        min-height: 150px;
    }

    .ql-toolbar {
        position: relative;
        z-index: 1;
    }
}


.rk_contactForm {
    &.active {
        .rk_feedback_form {
            h2 {
                animation: appear .4s .4s forwards;

            }

            >p {
                animation: appear .4s .6s forwards;
            }

            .ql-snow {
                animation: appear .4s .8s forwards;

            }

            .rk_send_feedback {
                animation: appear .4s 1s forwards;
                margin-top: 15px;
                cursor: pointer;
            }


        }
    }
}

@keyframes appear {
    from {
        opacity: 0;

    }

    to {
        opacity: 1;

    }
}

.rk_prestaform {
    display: flex;
    flex-wrap: wrap;
    max-width: 980px;
    justify-content: space-between;

    >div:not([data-type="textarea"]) {
        width: 50%;

    }

    >div[data-type="textarea"] {
        width: 100%;

        * {
            min-width: 100% !important;
        }
    }

    >div[data-type="checkbox"] {
        width: 100%;
    }

    input,
    textarea {
        background: white;
        height: auto !important;
    }

    .formkit-decorator {
        background: white !important;
    }

    .formkit-help {
        color: $inputColorhover;
    }
}
</style>