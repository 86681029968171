<template>
  <div class="rk_event_banner">
    <img :src="serverUrl + '/uploads/kikekoi/evenements&acctivites.webp'" alt="Image market place kikekoi">
    <h1 class="rk_titre_section">Événements <div>&</div> Activités
    </h1>
  </div>

  <div class="rk_event_content">
    <div class="rk_event_cities" v-for="(events, indexEvent) in eventsFiltered" :key="indexEvent">
      <div class="rk_events">
        <h2>{{ indexEvent }}</h2>
        <vueper-slides lazy class="no-shadow" :bullets="false" :touchable="false" :visible-slides="4" slide-multiple
          :arrows="false" :slide-ratio="0.3" :breakpoints="{
            800: {
              visibleSlides: 2, slideMultiple: 1, slideRatio: 0.6
            }
          }
            ">
          <vueper-slide class="rk_event" v-for="(   event, i   ) in    events" @click='addEvent(event)' :key="i">

            <template #content>
              <div class="rk_event_images">
                <img :src="serverUrl + event.images[0]" :alt="event.nomEvent">
              </div>

              <div class="rk_event_titre">
                <h3> {{ event.nomEvent }}</h3>
              </div>
              <div class="rk_event_date">
                <p>{{ formatDate(event.dateDebut, event.dateFin) }}</p>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>



    <Teleport to="body">
      <div class="rk_event_modal" :class="{ active: modalActive }">
        <div class="close_map" @click="modalActive = false"></div>
        <div class="rk_modal_content" v-if="selectedEvent !== false">
          <h2>{{ selectedEvent.nomEvent }}</h2>
          <div class="rk_event_images">
            <img :src="serverUrl + selectedEvent.images[0]" :alt="selectedEvent.nomEvent">
            <p>{{ formatDate(selectedEvent.dateDebut, selectedEvent.dateFin) }}</p>
          </div>
          <p class="rk_event_desc" v-html="selectedEvent.description">

          </p>
          <l-map ref="map" :zoom="11" :options="{ scrollWheelZoom: true }"
            :center="[selectedEvent.location.coordinates[1], selectedEvent.location.coordinates[0]]">
            <l-tile-layer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" layer-type="base"
              name="OpenStreetMap"></l-tile-layer>
            <l-marker :lat-lng="[selectedEvent.location.coordinates[1], selectedEvent.location.coordinates[0]]">


              <l-icon :icon-anchor="[0, 32]" :icon-size="[32, 32]"
                :icon-url="serverUrl + '/uploads/kikekoi/map-icon-green.svg'">
              </l-icon>

            </l-marker>
          </l-map>
        </div>
      </div>
    </Teleport>
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import { useLoginStore } from '../../store/piniaLogin'
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


const store = useLoginStore()

const { currentLatlng, searchProduit } = storeToRefs(store)

const serverUrl = ref(process.env.VUE_APP_SERVER_URL);
const events = ref([]);

const searchPrix = ref(0);
const searchCat = ref(0);
const dataLoaded = ref(0);
const modalActive = ref(false);
const donsDuSang = ref([]);
const eventsFiltered = ref([]);
const selectedEvent = ref(false);

onBeforeMount(() => {
  loadAround();
})

watch(searchPrix, (value) => {
  filterProducts(searchProduit.value, value, searchCat.value)

})

watch(searchCat, (value) => {
  filterProducts(searchProduit.value, searchPrix.value, value)

})

// quand les données sont chargés on les tri
watch(dataLoaded, (newValue) => {
  if (newValue == 2) {

    donsDuSang.value.forEach((event) => {
      events.value.push(
        {
          "location": {
            "type": "Point",
            "coordinates": [
              event.longitude,
              event.latitude
            ]
          },
          "nomEvent": event.name,
          "dateDebut": event.collections[0].date,
          "dateFin": event.collections[0].date,
          "description": event.collections[0].convocationLabelLong,
          "ville": event.city,
          "website": event.collections[0].urlBlood,
          "images": [
            '/uploads/kikekoi/Logo_Don_du_Sang.svg'
          ],
        }
      )
    })

    // Regrouper les événements par ville tout en prenant en compte les accents
    const groupedEvents = events.value.reduce((acc, event) => {
      const cityKey = removeAccents(event.ville.toLowerCase());

      if (!acc[cityKey]) {
        acc[cityKey] = [];
      }

      acc[cityKey].push(event);

      return acc;
    }, {});

    // Trier les événements par dateDebut (du plus récent au plus ancien)
    for (const cityKey in groupedEvents) {
      groupedEvents[cityKey].sort((a, b) => new Date(a.dateDebut) - new Date(b.dateDebut));
    }
    eventsFiltered.value = groupedEvents;
  }
})


watch(searchProduit, (value) => {

  filterProducts(value, searchPrix.value, searchCat.value)

})


// Fonction pour calculer les coordonnées du point nord-est et sud-ouest
function calculerCarré(latitude, longitude, distanceKm) {
  // Rayon de la Terre en kilomètres
  const rayonTerre = 6371; // Approximatif

  // Convertir la latitude et la longitude en radians
  // const latRadian = (latitude * Math.PI) / 180;
  // const lonRadian = (longitude * Math.PI) / 180;

  // Convertir la distance en radians
  const distanceRadian = distanceKm / rayonTerre;

  // Calculer les nouvelles latitudes et longitudes
  const latNordEst = latitude + (distanceRadian * 180) / Math.PI;
  const lonNordEst = longitude + (distanceRadian * 180) / Math.PI;
  const latSudOuest = latitude - (distanceRadian * 180) / Math.PI;
  const lonSudOuest = longitude - (distanceRadian * 180) / Math.PI;

  const carreCoordonnees = {
    nordEst: { latitude: latNordEst, longitude: lonNordEst },
    sudOuest: { latitude: latSudOuest, longitude: lonSudOuest },
  };

  return `https://oudonner.api.efs.sante.fr/carto-api/v3/samplingcollection/searchinsquare?NorthEastLatitude=${carreCoordonnees.nordEst.latitude}&NorthEastLongitude=${carreCoordonnees.nordEst.longitude}&SouthWestLatitude=${carreCoordonnees.sudOuest.latitude}&SouthWestLongitude=${carreCoordonnees.sudOuest.longitude}&HidePrivateCollects=false&HideNonPubliableCollects=true&UserLatitude=${latitude}&UserLongitude=${longitude}`
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function addEvent(event) {
  modalActive.value = true;
  selectedEvent.value = event;
}

function formatDate(inputDateDebut, inputDateFin) {
  const months = [
    "jan.",
    "fév.",
    "mars",
    "avr.",
    "mai",
    "juin",
    "jui.",
    "août",
    "sep.",
    "oct.",
    "nov.",
    "déc.",
  ];

  const dateObject = new Date(inputDateDebut);
  const day = dateObject.getDate();
  const month = months[dateObject.getMonth()];


  const dateObjectFin = new Date(inputDateFin);
  const dayFin = dateObjectFin.getDate();
  const monthFin = months[dateObjectFin.getMonth()];

  if (day == dayFin && month == monthFin) {
    return `${day} ${month}`;
  } else {
    return `${day} - ${dayFin} ${month}`;
  }
}


function loadAround() {
  let token = '';

  const params = ref({
    lat: 45.692341,
    long: 5.908998
  });



  if (store.currentToken !== undefined && store.currentToken !== 'false' && store.currentToken !== '') {

    const localUser = JSON.parse(store.currentUser);
    token = store.currentToken;
    params.value.lat = localUser.location.coordinates[1];
    params.value.long = localUser.location.coordinates[0];

  } else if (currentLatlng.value.lat !== '' && currentLatlng.value.lng !== '') {

    params.value = {
      lat: currentLatlng.value.lat,
      long: currentLatlng.value.lng
    };


  }


  // On récup tout les dons du sang 
  axios.get(calculerCarré(
    params.value.lat,
    params.value.long,
    10
  ), {
    headers: {
      'Authorization': `application/json`
    }
  })
    .then(function (response) {
      // handle success

      donsDuSang.value = response.data.samplingLocationCollections;
      dataLoaded.value++;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })


  axios.get(process.env.VUE_APP_SERVER_URL + `/events`, {
    withCredentials: true,

    params: params.value,
    headers: {
      'Authorization': `Basic ${token}`
    }
  })
    .then(function (response) {

      response.data.datas.forEach(event => {

        events.value.push(event)


      });
      dataLoaded.value++;

      // produitFiltered.value = events.value;
      // produitFilteredToShow.value = produitFiltered.value.length >= 13 ? 13 : produitFiltered.value.length;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      toast.error(error);
    })
}


</script>

<style lang="scss" scoped>
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;

.rk_event_banner {
  width: 100%;
  aspect-ratio: 4/1;
  position: relative;


  img:not(.rk_event_title) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .rk_event_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.rk_event_content {
  color: $textColorBlue;
  font-size: 22px;
  min-height: 700px;
}

.rk_titre_section {
  text-align: center;
  font-family: 'Cakecofe';
  font-style: normal;
  font-weight: 700;
  font-size: 66px;
  color: white;
  position: relative;
  padding: 60px 0 20px;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  >div {
    color: #29C4A9;
  }

  svg,
  img {
    position: absolute;
    height: 70px;
    left: 50%;

    top: 70%;


    transform: translate(-50%, -50%);
  }
}

.rk_events {
  max-width: 980px;
  margin: auto;

  h2:first-letter {
    text-transform: capitalize;
  }

  .rk_event {
    flex: 1 1 auto;
    margin: 2%;
    width: 200px;
    max-width: calc(18% - 22px);
    border: solid 1px $inputColor;
    padding: 10px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    .rk_event_date {
      position: absolute;
      bottom: 0;
      background-color: $mainColorBlue;
      color: white;
      left: 50%;
      transform: translatex(-50%);
      padding: 10px 15px;
      width: calc(100% - 30px);

      p {
        margin: 0;
        font-size: 16px;
        text-align: center;
      }
    }

    .rk_event_images {
      aspect-ratio: 1/1;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;

      }
    }

    .rk_event_titre {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      text-align: center;


      h3 {
        font-family: "Poppins";
        color: #161615;
        font-size: 15px;
        margin-bottom: 0;
      }

      p {
        font: normal normal bold 15px/20px Poppins;
        letter-spacing: 0px;
        color: #9BCFC3;
        margin: 0;

      }
    }
  }


}
</style>


<style lang="scss">
$inputColor: #6362A7;
$inputColorhover: #29C4A9;
$mainColorText : #161615;
$textColorBlue: #012577;
$mainColorBlue : #6362A7;
$mainColorGreen : #D2EFE8;


.rk_event_modal {
  position: fixed;
  top: 80px;
  left: 0;
  width: 90%;
  transform: translateX(-100%);
  height: calc(100% - 80px);
  transition: all .4s;
  background-color: $mainColorGreen;
  z-index: 10;
  max-width: 350px;
  padding: 20px;

  &.active {
    transform: translateX(0%);

    .close_map {
      transform: translate(50%, -50%);
    }
  }

  .close_map {
    width: 40px;
    height: 40px;
    background-color: #6362A7;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 1;
    display: block;
    border-radius: 50%;
    cursor: pointer;
    transition: all .4s;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

    &:after {
      content: '';
      width: 50%;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:before {
      content: '';
      width: 50%;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .rk_event_images {
    width: 100%;
    position: relative;

    p {
      position: absolute;
      top: 0;
      background-color: $mainColorBlue;
      color: white;
      left: 0;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Poppins";
      margin: 0;
    }

    img {
      width: 100%;
    }
  }

  .rk_modal_content {
    display: flex;
    flex-direction: column;

    .leaflet-container {
      aspect-ratio: 2/1;
      position: absolute;
      bottom: 0;
      right: 0;
      height: inherit !important;
    }

    .rk_event_desc {
      color: $inputColor;
      font-family: "Poppins";
      font-size: 16px;
    }
  }

  h2 {
    font-family: "Poppins";
    font-weight: 500 !important;
    color: $mainColorBlue;
    font-size: 22px;
    text-align: center;
    margin-top: 0;
  }
}
</style>