import { defineStore } from 'pinia'
import { ref } from 'vue';
import { useLocalStorage } from "@vueuse/core"

export const useEntraideStore = defineStore('entraide', () => {

    // use local storge permet de conserver en local storage les infos
    const entraideList = ref(useLocalStorage('entraideList', {}));
    const entraidePassed = ref(useLocalStorage('entraidePassed', []));
    const entraideHelped = ref(useLocalStorage('entraideHelped', []));
    const entraidePresta = ref(useLocalStorage('entraidePresta', []));

    function setEntraideList(listeEntraide) {
        entraideList.value = listeEntraide;
    }

    function addEntraidePassed(entraide) {
        entraidePassed.value.push(entraide);
    }

    function addEntraidePresta(prestas) {
        entraidePresta.value = prestas;
    }

    function addEntraideHelped(entraide) {
        entraideHelped.value.push(entraide);
    }

    return { entraideList, entraidePassed, entraideHelped, setEntraideList, addEntraidePassed, addEntraideHelped, addEntraidePresta, entraidePresta }
})