<template>
    <Transition name="modalDevis">
        <div class="rk_modal" v-if="show">
            <div class="rk_modal_titre" :class="{ selected: (selectedSearch != 0) }">
                <h2>Vous <br>recherchez :</h2>
                <svg class="rk_search_icon" width="90" height="91" viewBox="0 0 90 91" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M65.231 60.31L88.197 83.277C88.9427 84.0387 89.3577 85.0637 89.3521 86.1296C89.3464 87.1955 88.9205 88.2161 88.1668 88.9698C87.4131 89.7235 86.3925 90.1494 85.3266 90.1551C84.2607 90.1607 83.2357 89.7457 82.474 89L59.508 66.033C52.2358 71.8425 43.0151 74.6471 33.7397 73.8707C24.4644 73.0942 15.8383 68.7958 9.63309 61.8581C3.4279 54.9204 0.114608 45.8701 0.373682 36.5659C0.632756 27.2617 4.44453 18.4098 11.0262 11.8282C17.6078 5.24653 26.4597 1.43476 35.7639 1.17568C45.0681 0.91661 54.1184 4.2299 61.0561 10.4351C67.9938 16.6403 72.2922 25.2664 73.0687 34.5417C73.8451 43.8171 71.0405 53.0378 65.231 60.31ZM36.775 65.904C42.3776 65.904 47.8543 64.2427 52.5126 61.1301C57.171 58.0174 60.8017 53.5934 62.9457 48.4173C65.0897 43.2412 65.6507 37.5456 64.5577 32.0507C63.4647 26.5558 60.7668 21.5084 56.8052 17.5468C52.8436 13.5852 47.7962 10.8873 42.3013 9.79431C36.8064 8.7013 31.1108 9.26227 25.9347 11.4063C20.7587 13.5503 16.3346 17.181 13.222 21.8394C10.1094 26.4977 8.44801 31.9745 8.44801 37.577C8.44801 45.0898 11.4325 52.2949 16.7448 57.6072C22.0571 62.9196 29.2622 65.904 36.775 65.904Z"
                        fill="#EEEEF4" stroke="#EEEEF4" stroke-width="0.5" />
                </svg>

                <svg class="rk_search_icon_k" width="369" height="454" viewBox="0 0 369 454" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.362"
                        d="M17.787 453.002C3.561 355.213 0.567999 254.914 0.348999 216.686V208.386C0.620999 168.873 5.066 84.877 19.134 0.994995H158.541C154.649 26.363 151.403 51.914 148.418 77.332C145.129 104.743 141.827 131.06 171.467 101.456C209.839 61.988 265.777 4.95599 315.138 4.95599C345.838 4.95599 368.898 27.984 368.898 57.588C368.898 108.029 249.333 208.895 210.935 251.664C247.127 264.816 357.908 292.228 357.908 337.188C357.908 361.312 341.463 384.327 312.946 384.327C260.296 384.327 206.563 361.311 154.997 343.776C144.047 340.476 135.25 337.188 135.25 353.64C135.25 386.276 137.044 419.811 140.011 452.992L17.787 453.002Z"
                        fill="#EEEEF4" />
                </svg>


            </div>
            <div @click="$emit('close')" class="close_modal">
                <CloseIcon></CloseIcon>
            </div>
            <div class="step_1" :class="{ selected: (selectedSearch != 0) }">
                <div v-if="selectedSearch == 0 || selectedSearch == 1" @click="selectedSearch = 1" class="rk_choice"
                    :class="classChoices.ch1">
                    <div class="content-image"
                        :style="'background-image:url(' + serverUrl + '/uploads/kikekoi/aide_demande.webp)'">

                    </div>
                    <p @mouseleave="mouseenteredLeftElem(false, 1, $event)"
                        @mouseover="mouseenteredLeftElem(true, 1, $event)">De l'aide,
                        des
                        bénévoles ou une recomandation</p>
                </div>
                <div v-if="selectedSearch == 0 || selectedSearch == 2" @click="selectedSearch = 2" class="rk_choice"
                    :class="classChoices.ch2">
                    <div class="content-image"
                        :style="'background-image:url(' + serverUrl + '/uploads/kikekoi/devis_demande.webp)'">

                    </div>
                    <p @mouseleave="mouseenteredLeftElem(false, 2, $event)"
                        @mouseover="mouseenteredLeftElem(true, 2, $event)">Un devis des
                        prestataires aux alentours</p>

                </div>
                <div v-if="selectedSearch == 0 || selectedSearch == 3" @click="selectedSearch = 3" class="rk_choice"
                    :class="classChoices.ch3">
                    <div class="content-image"
                        :style="'background-image:url(' + serverUrl + '/uploads/kikekoi/partage_demande.webp)'">

                    </div>

                    <p @mouseleave="mouseenteredLeftElem(false, 3, $event)"
                        @mouseover="mouseenteredLeftElem(true, 3, $event)">Quelqu'un
                        avec
                        qui partager mes connaissances</p>

                </div>
                <!-- <div v-if="selectedSearch == 0 || selectedSearch == 4" @click="selectedSearch = 4" class="rk_choice">
                    <div class="content-image">

                    </div>
                    <p>Une recomandation</p>

                </div> -->
                <div class="prev_step" v-if="selectedSearch != 0" @click="selectedSearch = 0">
                    <CloseIcon></CloseIcon>
                </div>
            </div>
            <div class="step_2" :class="{ selected: (selectedSearch != 0) }">
                <FormKit v-if="contact.lat == 0 && contact.lat == 0 && selectedSearch != 0" type="group">
                    <h2>Tout d'abord, votre annonce concerne quelle adresse ?</h2>
                    <p>
                        En choisissant une adresse ici, les personne autour de cette dernière pouront répondre à votre
                        demande
                    </p>

                    <div class="double">
                        <FormKit :floating-label="true" v-model="contact.adresse" name="adresse" label="Rue" />
                        <FormKit :floating-label="true" v-model="contact.codePostale" name="codePostale"
                            label="Code postale" />
                        <FormKit :floating-label="true" v-model="contact.ville" name="ville" label="Ville" />

                    </div>
                    <FormKit type="button" @click="checkAdress">
                        Choisir cette adresse !
                    </FormKit>
                </FormKit>


                <div class="rk_choice_form" v-if="selectedSearch == 1 && contact.lat !== 0 && contact.lat !== 0">
                    <FormKit type="form" id="help_search" submit-label="Envoyer ma demande" @submit="sendDevisHelp">
                        <h2>Besoin d'un coup de main ?</h2>
                        <p>
                            Pas de soucis ! Remplissez le formulaire suivant et on s'occupe de tout !
                        </p>

                        <div class="rk_formf_1">

                            <FormKit type="text" name="prenom" label="Prénom" :floating-label="true"
                                validation="required" />
                            <FormKit type="text" name="email" label="Votre email" placeholder="jane@example.com"
                                help="Si quelqu'un vous répond, nous enverrons un mail sur cette adresse."
                                :floating-label="true" validation="required|email" />
                            <FormKit type="select" label="Vous avez besoin :" v-model="besoins"
                                placeholder="Choisissez une option" validation="required" name="typeDemandes" :options="[
                                    { label: 'D\'aide', value: 'aide' },
                                    { label: 'De bénévoles', value: 'bénévoles' },
                                    { label: 'Que l\'on me recommande un prestataire', value: 'recommandation_presta' },
                                    { label: 'D\'une recomandation autre', value: 'recommandation' }
                                ]" />
                            <FormKit v-if="besoins == 'bénévoles'" validation="required" type="number"
                                help="Vous avez besoin de combien de personnes pour vous aider ?"
                                label="Nombre de personnes" name="nbBenevoles" step="1" />
                        </div>
                        <div class="rk_formf_1">
                            <FormKit type="textarea" auto-height validation="required" :floating-label="true"
                                name="description" label="Description" rows="10" placeholder="J'ai besoin de ..."
                                help="Faites une description précise de votre demande." />

                        </div>

                    </FormKit>
                </div>
                <div class="rk_choice_form" v-if="selectedSearch == 2 && contact.lat !== 0 && contact.lat !== 0">
                    <FormKit type="form" id="devis_search" submit-label=" Envoyer ma demande" @submit="sendDevisPresta">
                        <h2>Besoin d'un devis ?</h2>
                        <p>
                            Pas de soucis ! Remplissez le formulaire suivant et on s'occupe de tout !
                        </p>
                        <div class="rk_formf_1">
                            <FormKit type="text" name="prenom" label="Prénom" :floating-label="true"
                                validation="required" />
                            <FormKit type="text" name="email" label="Votre email" placeholder="jane@example.com"
                                help="Si une entreprise souhaite répondre, nous enverrons un mail sur cette adresse."
                                :floating-label="true" validation="required|email" />
                            <FormKit v-if="servicesLoaded.length > 0" validation="required" type="autocomplete"
                                name="service" label="Service" :options="servicesLoaded" selection-appearance="option"
                                help="Choisissez le service qui correspond à votre demande" />

                        </div>
                        <div class="rk_formf_1">
                            <FormKit type="textarea" :floating-label="true" validation="required" name="description"
                                label="Description" rows="10" placeholder="J'ai besoin de ..."
                                help="Faites une description précise de votre demande." />

                        </div>
                    </FormKit>
                </div>
                <div class="rk_choice_form" v-if="selectedSearch == 3 && contact.lat !== 0 && contact.lat !== 0">
                    <FormKit type="form" id="share_search" submit-label=" Envoyer ma demande" @submit="sendDevisSkills">
                        <h2>Vous souhaitez partager vos connaissances ?</h2>
                        <p>
                            Pas de soucis ! Remplissez le formulaire suivant et on s'occupe de tout !
                        </p>
                        <div style="width:100%">
                            <FormKit type="text" name="prenom" label="Prénom" :floating-label="true"
                                validation="required" />
                            <FormKit type="text" name="email" label="Votre email" placeholder="jane@example.com"
                                help="Si quelqu'un vous répond, nous enverrons un mail sur cette adresse."
                                :floating-label="true" validation="required|email" />
                        </div>

                        <div class="rk_formf_1">
                            <FormKit name="competences" validation="required" v-model="competences.my" :options="['']"
                                empty-message="Appuyez sur entrer pour valider" type="taglist" label="Vos compétences"
                                help="Ajoutez les compétences que vous souhaitez partager (valider avec la touche entrer)"
                                :allow-new-values="true" />

                            <div class="rk_my_skills">
                                <p v-for="(competence, indexcomp) in competences.my" v-bind:key="indexcomp">
                                    {{ competence }}
                                </p>
                            </div>

                        </div>
                        <div class="rk_formf_1">
                            <FormKit name="competencesRecherchees" validation="required" v-model="competences.yours"
                                :options="['']" empty-message="Appuyez sur entrer pour valider" type="taglist"
                                label="Compétences que vous recherchez" help="Ajoutez les compétences que vous recherchez"
                                :allow-new-values="true" />

                            <div class="rk_yours_skills">
                                <p v-for="(competence, indexcomp) in competences.yours" v-bind:key="indexcomp">
                                    {{ competence }}
                                </p>
                            </div>
                        </div>
                    </FormKit>
                </div>
                <!-- <div class="rk_choice_form" v-if="selectedSearch == 4">
                    form 4

                </div> -->
            </div>

        </div>
    </Transition>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import axios from 'axios';
import { toast } from 'vue3-toastify';
// import { storeToRefs } from 'pinia'
// import { useLoginStore } from '../../store/piniaLogin'
import CloseIcon from 'vue-material-design-icons/Close.vue';
const serverUrl = ref(process.env.VUE_APP_SERVER_URL);



// const store = useLoginStore()
// const { currentServices } = storeToRefs(store)

const selectedSearch = ref(0);
const besoins = ref('');
const classChoices = ref({ ch1: '', ch2: '', ch3: '' });
const servicesLoaded = ref([]);
defineProps({
    show: Boolean
})
defineEmits(['close'])
const competences = ref({ my: [], yours: [] });
const contact = ref({ lat: 0, long: 0 });

function mouseenteredLeftElem(isEntered, nb, e) {
    const element = e.target;
    const el_pos = element.getBoundingClientRect();
    let edge = closestEdge(e.pageX - el_pos.left, e.pageY - el_pos.top, element.offsetWidth, element.offsetHeight);
    if (isEntered) {
        edge += 'In';
    } else {
        edge += 'Out';
    }

    switch (nb) {
        case 1:
            classChoices.value.ch1 = edge;
            break;
        case 2:
            classChoices.value.ch2 = edge;
            break;
        case 3:
            classChoices.value.ch3 = edge;
            break;

    }
}



function closestEdge(x, y, w, h) {
    const topEdgeDist = distMetric(x, y, w / 2, 0);
    const bottomEdgeDist = distMetric(x, y, w / 2, h);
    const leftEdgeDist = distMetric(x, y, 0, h / 2);
    const rightEdgeDist = distMetric(x, y, w, h / 2);
    const min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);
    switch (min) {
        case leftEdgeDist:
            return 'left';
        case rightEdgeDist:
            return 'right';
        case topEdgeDist:
            return 'top';
        case bottomEdgeDist:
            return 'bottom';
    }
}


function distMetric(x, y, x2, y2) {
    const xDiff = x - x2;
    const yDiff = y - y2;
    return xDiff * xDiff + yDiff * yDiff;
}

watch(contact.value, async () => {
    if (contact.value.lat !== 0 && contact.value.long !== 0) {
        servicesLoaded.value = await loadServices();

    }
});
const defaultDevis = ref({
    "statut": "en attente",
    "description": "Entraide",
    "typeDemandes": "",
    "email": "",
    "competences": [],
    "competencesRecherchees": [],
    "nbBenevoles": 0
})

async function loadServices() {

    const params = {
        lat: contact.value.lat,
        long: contact.value.long
    };
    const services = await axios.get(process.env.VUE_APP_SERVER_URL + `/prestataires/autour/services`, {
        withCredentials: true,
        params: params,
    })
        .then(function (response) {
            const results = [];
            response.data.datas.forEach((services) => {
                services.servicesPresta.forEach((service) => {
                    if (!results.some(s => s.value === service.services._id)) {

                        results.push({ 'label': service.services.titre, 'value': service.services._id });
                    }
                })
            })

            return results;
        })
        .catch(function (error) {
            // handle error
            console.log(error);

            toast.error(error);
        })
    // servicesLoaded.value = services;

    return services;
}

function checkAdress() {


    axios.get(`https://dev.virtualearth.net/REST/v1/Locations/FR/${contact.value.codePostale}/${contact.value.ville}/${contact.value.adresse}?key=AoDJrgfVItWTs_icYLtdaizY88Uv3yj-98Tr03Cr6TR1VONQwP3tPZw0EY-JWTaL`)
        .then(function (response) {
            // handle success

            contact.value.lat = response.data.resourceSets[0].resources[0].point.coordinates[0];
            contact.value.long = response.data.resourceSets[0].resources[0].point.coordinates[1];

        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

}

async function sendDevisHelp(datas) {
    const dataToSend = { ...defaultDevis.value, ...datas };
    dataToSend.ville = contact.value.ville;
    dataToSend.location = {
        type: "Point",
        coordinates: [
            contact.value.long,
            contact.value.lat
        ]
    };
    await axios.post(process.env.VUE_APP_SERVER_URL + '/entraide', dataToSend, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
    }).then(function () {
        toast.success('Demande envoyée ! Pensez à valider votre email pour qu\'elle soit mise ne ligne!');

        emit('close')

    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });
}
async function sendDevisPresta(datas) {
    datas.typeDemandes = 'devis';

    const dataToSend = { ...defaultDevis.value, ...datas };
    dataToSend.prestationLabel = servicesLoaded.value.find(item => item.value === dataToSend.service);

    dataToSend.location = {
        type: "Point",
        coordinates: [
            contact.value.long,
            contact.value.lat
        ]
    };
    await axios.post(process.env.VUE_APP_SERVER_URL + '/entraide', dataToSend, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
    }).then(function () {
        toast.success('Demande envoyée ! Pensez à valider votre email pour qu\'elle soit mise ne ligne!');

        emit('close')

    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });

}
async function sendDevisSkills(datas) {
    datas.typeDemandes = 'partage';

    const dataToSend = { ...defaultDevis.value, ...datas };

    dataToSend.location = {
        type: "Point",
        coordinates: [
            contact.value.long,
            contact.value.lat
        ]
    };
    await axios.post(process.env.VUE_APP_SERVER_URL + '/entraide', dataToSend, {
        headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
        },
    }).then(function () {
        toast.success('Demande envoyée ! Pensez à valider votre email pour qu\'elle soit mise ne ligne!');

        emit('close')

    }).catch(function (err) {
        console.log(err);
        toast.error(err.response.data);
        return err;
    });
}
</script>


<style scoped lang="scss">
$mainColorText : #2d2d25;
$mainColorBlue : #6362A7;
@import "../../assets/css/mixin.scss";

.rk_modal {
    display: flex;
    z-index: 1000;
    position: fixed;
    aspect-ratio: 5/2;
    left: 50%;
    max-width: 980px;
    width: 100%;
    background-color: white;
    transform: translatex(-50%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

    @include mq("mobile") {
        flex-direction: column;
        aspect-ratio: inherit;
        justify-content: start;
        top: 0px;
        overflow: auto;
        max-height: 100vh;

    }

    @include mq("tablet") {
        flex-direction: row;
        aspect-ratio: 5/2;
        top: 80px;
        overflow: hidden;
        height: auto;

        justify-content: space-between;

    }




    .rk_modal_titre {
        flex: 2;
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px;
        padding-top: 10%;

        @include mq("mobile") {
            flex-direction: row;
            justify-content: space-evenly;

        }

        @include mq("tablet") {
            flex-direction: column-reverse;
            justify-content: start;


        }

        * {
            transition: transform .2s;
        }

        &.selected {
            padding: 0;
            overflow: hidden;

            @include mq("mobile") {
                display: none;

            }

            @include mq("tablet") {
                width: 0;
                display: flex;


            }

            * {
                transform: translateX(-100%);
            }
        }

        .rk_search_icon {
            height: 20%;
        }

        .rk_search_icon_k {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }

        h2 {
            text-align: left;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            color: #012577;
            position: relative;
            z-index: 1;
        }

    }

    .step_1 {
        flex: 4;
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        position: relative;
        padding-left: 1%;

        @include mq("mobile") {
            flex-direction: column;
            flex: auto;

        }

        @include mq("tablet") {
            flex-direction: row;
            flex: 4;

        }

        h2 {
            color: white;
        }

        &.selected {
            padding-left: 0;

            .rk_choice {

                margin: 0;
                max-width: 100%;

                @include mq("mobile") {
                    height: 20%;
                    aspect-ratio: inherit;


                }

                @include mq("tablet") {
                    aspect-ratio: 1/1;
                    height: 100%;

                }

                .content-image {
                    transform: scale(1.2);
                    filter: grayscale(0%);

                }

                &:after {

                    transform: translate(-50%, -50%) !important;
                }

                p {
                    text-align: center;
                    font-size: 2rem;
                    width: 100%;

                    @include mq("mobile") {
                        padding: 0px 0;
                    }

                    @include mq("tablet") {
                        padding: 20px;
                    }
                }
            }
        }

        .prev_step {
            position: absolute;
            top: 12px;
            right: 12px;
            height: 24px;
            color: white;
            background-color: hsl(348, 100%, 61%);
            border-radius: 50%;
            cursor: pointer;
            z-index: 3;
        }

        .rk_choice {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            cursor: pointer;
            transition: all .4s;


            @include mq("mobile") {
                max-width: 100%;

                height: 20%;
            }

            @include mq("tablet") {
                max-width: 33%;

                height: 100%;
            }


            &:hover {
                .content-image {
                    transform: scale(1.2);
                    filter: grayscale(0%);

                }
            }

            &:after {
                background-color: #6362A7;
                opacity: .5;
                content: '';
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-200%, -200%);
            }

            &.leftIn:after {
                animation: ltr .2s linear .02s forwards;
            }

            &.rightOut:after {
                animation: ltrO .2s linear forwards;
            }

            &.rightIn:after {
                animation: rtl .2s linear .02s forwards;
            }

            &.leftOut:after {
                animation: rtlO .2s linear forwards;
            }

            &.topIn:after {
                animation: ttb .2s linear forwards;
            }

            &.bottomOut:after {
                animation: ttbO .2s linear forwards;
            }

            &.bottomIn:after {
                animation: btt .2s linear forwards;
            }

            &.topOut:after {
                animation: bttO .2s linear forwards;
            }

            @keyframes btt {
                from {
                    transform: translate(-50%, 200%);

                }

                to {
                    transform: translate(-50%, -50%);

                }


            }

            @keyframes bttO {

                from {
                    transform: translate(-50%, -50%);

                }

                to {
                    transform: translate(-50%, -200%);

                }

            }

            @keyframes ttb {
                from {
                    transform: translate(-50%, -200%);

                }

                to {
                    transform: translate(-50%, -50%);

                }


            }

            @keyframes ttbO {

                from {
                    transform: translate(-50%, -50%);

                }

                to {
                    transform: translate(-50%, 200%);

                }

            }

            @keyframes rtl {
                from {
                    transform: translate(200%, -50%);

                }

                to {
                    transform: translate(-50%, -50%);

                }


            }

            @keyframes rtlO {

                from {
                    transform: translate(-50%, -50%);

                }

                to {
                    transform: translate(-200%, -50%);

                }

            }

            @keyframes ltr {
                from {
                    transform: translate(-150%, -50%);

                }

                to {
                    transform: translate(-50%, -50%);

                }
            }

            @keyframes ltrO {
                from {
                    transform: translate(-50%, -50%);

                }

                to {
                    transform: translate(200%, -50%);

                }
            }

            .content-image {
                filter: grayscale(100%);
                transition: transform 4s, filter .4s;
                width: 100%;
                height: 100%;

                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 0%;
                left: 0;
            }



            overflow:hidden;

            p {
                font-size: 1.5rem;
                text-align: center;
                font-size: 1.2rem;
                width: 100%;
                padding: 25px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                color: white;
                font-family: 'Cakecofe';
            }


        }

    }

    .step_2 {
        h2 {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            color: #012577;
            position: relative;
            z-index: 1;
        }

        &.selected {
            padding: 0px 30px 0px 30px;
            background-color: white;
            flex-grow: 1;

        }

        &.selected {
            .rk_choice_form {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .rk_choice_form {
            transition: all .8s 1s;
            transform: translateY(100%);
            opacity: 0;
        }

        p {
            font-family: 'Poppins';
            font-size: 15px;
        }
    }
}
</style>

<style lang="scss">
$mainColorText : #2d2d25;
$mainColorBlue : #6362A7;

#help_search,
#devis_search,
#share_search {
    display: flex;
    flex-wrap: wrap;

    h2,
    >p,
    .formkit-actions,
    hr {
        width: 100%;
    }

    .formkit-actions {
        justify-self: end;
    }

    .rk_formf_1 {
        width: clamp(410px, 100%, 50%);

    }
}

.close_modal {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    cursor: pointer;
    z-index: 3;

    svg {
        width: 48px;
        height: 48px;
        fill: $mainColorBlue;
    }


}

.step_2 {

    &::-webkit-scrollbar {
        width: 15px;
        padding: 2px;

    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #EBEBF2;
        padding: 2px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #6362A7;
        border-radius: 20px;
        border: solid 5px #EBEBF2;
        cursor: pointer;

    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #6362A7;
    }

    &.selected {
        overflow: auto;

    }

    .formkit-inner {
        box-shadow: none;
    }

    .formkit-input {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        border: none;
    }

    .formkit-label {
        font-family: 'Poppins';
        font-weight: 700 !important;
        color: #78849e !important;
    }

    [type="button"].formkit-input,
    [type="submit"].formkit-input {
        background-color: #012577;
        font-family: 'Poppins';
        font-weight: 700 !important;
    }

    [type="button"].formkit-input:hover,
    [type="submit"].formkit-input:hover {
        background-color: #6362A7;
    }

}
</style>